import React from 'react'
import { Link } from 'react-router-dom'
import { userLog } from '../_api/index'
import { useMutation } from 'react-query';

export default function Header(props) {
    const { mutate: log } = useMutation(userLog);
    function handleClick(e) {
        e.preventDefault()
        window.history.go(-1)

        log({
            pageName: props?.page,
            buttonName: "Back"
        })
    }
    return (
        <>
            <div className="back back2 get-started-code-back py-3 px-md-5 bg-light" style={{ position: 'fixed', width: '100%', zIndex: 1000, top: '0px', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)', left: 0 }}>
                <div className="back-wrapper ml-5">
                    <a
                        // to={props?.backLink}
                        className={props.icon ? "bg-primary1 back-btn" : ""}
                        onClick={(e) => handleClick(e)}>
                        {props.icon ? props.icon : <img src="/assets/svg/back-arrow.svg" alt="" />}

                    </a>
                    <h3>{props?.title}</h3>
                </div>

                <button
                    type="button"
                    className="skip-code"
                >
                    <Link style={{ color: '#616ded' }} to={props.textLink !== undefined && props.textLink}

                        onClick={() => log(
                            {
                                pageName: props?.page,
                                buttonName: 'Skip'
                            })}

                    >
                        {props?.text}
                    </Link>

                </button>


            </div>
        </>
    )
}
