import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import Header from '../common/Header'
import { useTranslation } from 'react-i18next'

import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
export default function PrivacyPolicy() {
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    // if (isMobile) {
    //     return (<TurnPhoneH content={t("privacyPolicy.turn")} />)
    // } else
        return (
            <>
                <div className="container pp-container">
                    <Header title={t("privacyPolicy.privacyHeading")} icon={<KeyboardBackspaceRoundedIcon />} page="Privacy Policy" />
                    <div className="content">
                        <div className="policy-container">
                            <div className="policy">
                                <h1>{t("privacyPolicy.privacyHeading")}</h1>
                                <p>
                                    {t("privacyPolicy.privacyText")}
                                </p>
                            </div>
                            <div className="policy">
                                <h1>{t("privacyPolicy.cookiesHeading")}</h1>
                                <p>
                                    {t("privacyPolicy.cookiesText")}
                                </p>
                            </div>
                            <div className="policy">
                                <h1> {t("privacyPolicy.securityHeading")}</h1>
                                <p>
                                    {t("privacyPolicy.securityText")}
                                </p>
                            </div>
                            <div className="policy">
                                <h1> {t("privacyPolicy.childerenHeading")}</h1>
                                <p>
                                    {t("privacyPolicy.childerenText")}
                                </p>
                            </div>
                            <div className="policy">
                                <h1> {t("privacyPolicy.contactUsHeading")}</h1>
                                <p>
                                    {t("privacyPolicy.contactUsText")}
                                </p>
                            </div>
                            <div className="policy">
                                <h1> {t("privacyPolicy.illusHeading")}</h1>
                                <p><a href="url(https://stories.freepik.com)">

                                </a> https://stories.freepik.com</p>
                            </div>
                        </div>
                        {/* <input className="submit-btn" type="submit" value="Read More" /> */}
                    </div>
                </div>
            </>
        )
}
