import React from 'react'
import { useSelector } from 'react-redux'

export default function Keyboard(props) {
    const { value, setValue, word } = props
    const correctLetters = useSelector(state => state.user?.correctLetters)
    const existLetters = useSelector(state => state.user?.existLetters)
    const absentLetters = useSelector(state => state.user?.absentLetters)
    let keyLatters = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P", "A", "S", "D", "F", "G", "H", "J", "K", "L", "Z", "X", "C", "V", "B", "N", "M"]
    return (
        <>
            <div className="row">
                {
                    keyLatters.slice(0, 10).map((key, index) => {
                        let index9 = correctLetters?.includes(key)
                        let index1 = existLetters?.includes(key)
                        let index2 = absentLetters?.includes(key)
                        return (
                            <div key={index} className={index9 ? "char bg-primary1 primary-show" : index1 ? "char bg-secondary1 secondary-shadow" : index2 ? "char bg-dark1 dark-shadow" : "char bg-grey1 grey-shadow"} onClick={() => {
                                value?.length < word?.length ? setValue(value + key) : setValue(value + "")
                            }}>
                                {key}
                            </div>
                        )
                    })
                }
            </div>
            <div className="row">
                {
                    keyLatters.slice(10, 19).map((key, index) => {
                        let index3 = correctLetters?.includes(key)
                        let index4 = existLetters?.includes(key)
                        let index5 = absentLetters?.includes(key)
                        return (
                            <div key={index} className={index3 ? "char bg-primary1 primary-show" : index4 ? "char bg-secondary1 secondary-shadow" : index5 ? "char bg-dark1 dark-shadow" : "char bg-grey1 grey-shadow"} onClick={() => {
                                value?.length < word?.length ? setValue(value + key) : setValue(value + "")
                            }}>
                                {key}
                            </div>
                        )
                    })
                }
            </div>
            <div className="row">
                {
                    keyLatters.slice(19, 26).map((key, index) => {
                        let index6 = correctLetters?.includes(key)
                        let index7 = existLetters?.includes(key)
                        let index8 = absentLetters?.includes(key)
                        return (
                            <div key={index} className={index6 ? "char bg-primary1 primary-show" : index7 ? "char bg-secondary1 secondary-shadow" : index8 ? "char bg-dark1 dark-shadow" : "char bg-grey1 grey-shadow"} onClick={() => {
                                value?.length < word?.length ? setValue(value + key) : setValue(value + "")
                            }}>
                                {key}
                            </div>
                        )
                    })
                }
                <div className="char del bg-light white-shadow" onClick={() => { setValue(value.slice(0, -1)) }}>
                    Del
                </div>
            </div>

        </>
    )
}
