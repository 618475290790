export const componentsDefaults = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: "8px",
        textTransform: "capitalize",
        height: "fit-content",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: "15px",
      },
    },
  },

  MuiIconButton: {
    variants: [
      {
        props: { variant: 'rounded' },
        style: ({ theme }) => ({
          borderRadius: '13px',
          color: theme.palette.common.white, // Text color light
          background: `linear-gradient(80deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`, // Background primary
          '&:hover': {
            background: `linear-gradient(90deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`, // Darker shade on hover (optional)
          },
          '& svg': {
            fontSize: '30px',
          },
          [theme.breakpoints.down("sm")]: {
            '& svg': {
              fontSize: '20px',
            },
          },

        }),
      },
      {
        props: { variant: 'circle' },
        style: ({ theme }) => ({
          borderRadius: '50%',
          color: theme.palette.common.white, // Text color light
          backgroundColor: theme.palette.primary.main, // Background primary
          '&:hover': {
            backgroundColor: theme.palette.primary.dark, // Darker shade on hover (optional)
          },

        }),
      },
      {
        props: { variant: 'dragable' },
        style: ({ theme }) => ({
          width: "fit-content",
          height: "fit-content",
          borderRadius: '50%',
          color: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          padding: '5px',
          [theme.breakpoints.down("sm")]: {
            padding: '2px',
          },
          '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.dark,
          },
          '& svg': {
            fontSize: '15px',
          }

        }),
      },

      {
        props: { variant: "successCircle" },
        style: ({ theme }) => ({
          borderRadius: "50%",
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.main,
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
          },
        }),
      },
    ],
  },
}
