import React, { useState } from 'react'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import CloseIcon from "@mui/icons-material/Close";
import {
    IconButton,
    Box,
    Typography,
    CircularProgress,
    useMediaQuery
} from '@mui/material'
import { useStyles } from '../../utils/styles';
import ChatBot from './chatbot';
import GoalsModal from './GoalsModal';
import TodoListModal from '../lessons/notes/TodoListModal';
import Notes from '../lessons/notes/Notes';
import { useSelector } from 'react-redux';
import StaticNotes from '../lessons/notes/StaticNotes';
import StaticTodoListModal from '../lessons/notes/StaticTodoListModal';
import { isStatic } from './isStatic';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

export default function SkillSectionButtons(props) {
    let showTimer = sessionStorage.getItem("timer")
    const isMobile = useMediaQuery('(max-width: 600px)');
    const { handleNextClick, handlePrevClick, isCompleted, nextTimer } = props
    const isNextEnabled = useSelector(state => state.user?.isNextEnabled)
    // const hasWaitTime = useSelector(state => state.user.hasWaitTime)
    const hasWaitTime = true
    const waitTime = useSelector(state => state.user.userData?.timer) || 0
    const classes = useStyles()
    const [showModal, setShowModal] = useState(false);
    const [divident] = useState(100 / waitTime)

    const showTimerProgress = !!(nextTimer > 0 && hasWaitTime && !isCompleted)
    const openModal = () => {
        setShowModal(!showModal);
    };

    return (
        <>
            {showModal && (
                <div className="journal-modal">
                    {
                        isStatic ? <StaticNotes /> : <Notes />
                    }
                </div>
            )}
            <Box className={classes.fixedBottomButtons} >
                <Box className={classes.flexColCenter}>
                    <IconButton variant="rounded" onClick={handlePrevClick}>
                        <ArrowBackIosNewRoundedIcon />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>Previous</Typography>
                </Box>

                <Box className={classes.flexColCenter}>

                    <ChatBot
                        data={{ name: "Ameer" }}
                        pageName={"Skill Section"}
                    />
                    <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>Chat</Typography>
                </Box>

                <Box className={classes.flexColCenter}>
                    <IconButton variant="rounded"
                        onClick={openModal}
                        sx={{ background: showModal && "red" }}
                    >
                        {showModal ? (
                            <CloseIcon />
                        ) : (
                            <NoteAltRoundedIcon />
                        )}
                    </IconButton >
                    <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>Journal</Typography>
                </Box>

                {!isStatic &&
                    <Box className={classes.flexColCenter}>
                        <GoalsModal page={"Skill Section"} />
                        <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>Goals</Typography>
                    </Box>}


                <Box className={classes.flexColCenter}>
                    {isStatic ? <StaticTodoListModal page="Skill Section" /> : <TodoListModal page="Skill Section" />}
                    <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>To Do</Typography>
                </Box>
                {
                    !isMobile &&

                    <Box className={classes.flexColCenter}>
                        <IconButton
                            variant="rounded"
                            onClick={() => {
                                window.print({
                                    pageSetup: {
                                        size: "legal",
                                        orientation: "landscape",
                                        pages: "1",
                                    },
                                    backgrounds: true,
                                    backgroundGraphics: true,
                                });
                            }}
                        >
                            <LocalPrintshopOutlinedIcon />
                        </IconButton>
                        <Typography
                            variant="caption"
                            sx={{ color: (theme) => theme.palette.common.text }}
                        >
                            Print
                        </Typography>
                    </Box>
                }

                <Box className={classes.flexColCenter}>
                    {
                        showTimerProgress && showTimer !== "No" ?
                            <CircularProgressWithLabel divident={divident} value={nextTimer * divident} />
                            :
                            <>
                                <IconButton disabled={isNextEnabled && !isCompleted} variant="rounded" onClick={handleNextClick}>
                                    <ArrowForwardIosRoundedIcon />
                                </IconButton>
                                <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>Next</Typography>
                            </>

                    }

                </Box>
            </Box>
        </>

    )
}


function CircularProgressWithLabel(props) {
    return (
        <Box className="next" sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="primary"
                >{(props.value / props.divident).toFixed(0)}</Typography>
            </Box>
        </Box>
    );
}