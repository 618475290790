import { useState } from "react";
import { TikTokEmbed } from "react-social-media-embed";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "@mui/material";
import {
  Adminwrapper,
  ResizableBoxes,
  TypographyWithMore,
} from "../../../common";
import {
  ADMIN_LINK,
  checkQuilValidation,
  DATA_TYPE,
  isAdminAdd,
  isAdminEdit,
  renderPlaceholder,
  isValidUrl,
  LazzyLoading,
} from "../../../common/contentType/contentTypeData";
import { useStyles } from "../../../../utils/styles";

const RenderIframIfram = ({ content }) => {
  const classes = useStyles();
  const { QUILL,  LINK } = DATA_TYPE;
  const myObj = JSON.parse(content?.allContent?.content);
  const { wireframe_link, wireframe_link1 } = myObj;
  const { name, description, type } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link,
    wireframe_link1,
  });
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      wireframe_link: data.wireframe_link,
      wireframe_link1: data.wireframe_link1,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      wireframe_link: Yup.string().required("Link is Required"),
      wireframe_link1: Yup.string().required("Link is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <>
      {isAdminEdit() && (
        <Button
          className={classes.btnUpdateStyle}
          onClick={contentFormik.handleSubmit}
        >
          Update
        </Button>
      )}
      {isAdminAdd() && (
        <Button
          className={classes.btnAddStyle}
          onClick={contentFormik.handleSubmit}
        >
          ADD
        </Button>
      )}
      <Adminwrapper
        errorText={contentFormik.errors.name}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="name"
      >
        {renderPlaceholder(data?.name, "Enter Name")}
        {/* <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} /> */}
        <TypographyWithMore
          textAlign="center"
          textLength={10}
          lines={1}
          variant="h2"
          color="primary"
          data={data?.name}
        />
      </Adminwrapper>

      <Adminwrapper
        errorText={contentFormik.errors.description}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="description"
      >
        {renderPlaceholder(data?.description, "Enter Description")}
        {/* <QuillEditor
                            sx={quilStyle}
                            value={data?.description}
                            readOnly={true}
                        /> */}
        <TypographyWithMore
          textAlign="center"
          textLength={50}
          lines={3}
          variant="body1"
          color="text"
          data={data?.description}
        />
      </Adminwrapper>
      {data?.type === "Iframe-Iframe" ? (
        <ResizableBoxes height={"55vh"}>
          <ResizableBoxes.Left>
            <Adminwrapper
              errorText={contentFormik.errors.wireframe_link}
              dataType={LINK}
              data={data}
              setData={setData}
              type="link1"
              label={"Iframe-Iframe Link"}
            >
              {!isIframeReady && <LazzyLoading />}
              <iframe
                onLoad={() => setIsIframeReady(true)}
                style={{
                  width: "100%",
                  height: isIframeReady ? "100%" : "5%",
                  opacity: isIframeReady ? 1 : 0,
                }}
                src={
                  isValidUrl(data?.wireframe_link)
                    ? data?.wireframe_link +
                      "?token=" +
                      localStorage.getItem("token") +
                      "&isIframe=true"
                    : "www.google.com"
                }
                frameBorder="0"
              ></iframe>
            </Adminwrapper>
          </ResizableBoxes.Left>
          <ResizableBoxes.Right>
            <Adminwrapper
              errorText={contentFormik.errors.wireframe_link1}
              dataType={LINK}
              data={data}
              setData={setData}
              type="link2"
              label={"Iframe-Iframe Link1"}
            >
              {!isIframeReady && <LazzyLoading />}
              <iframe
                onLoad={() => setIsIframeReady(true)}
                style={{
                  width: "100%",
                  height: isIframeReady ? "100%" : "5%",
                  opacity: isIframeReady ? 1 : 0,
                }}
                src={
                  isValidUrl(data?.wireframe_link1)
                    ? data?.wireframe_link1 +
                      "?token=" +
                      localStorage.getItem("token") +
                      "&isIframe=true"
                    : "www.google.com"
                }
                frameBorder="0"
              ></iframe>
            </Adminwrapper>
          </ResizableBoxes.Right>
        </ResizableBoxes>
      ) : (
        <ResizableBoxes height={"55vh"}>
          <ResizableBoxes.Left>
            <Adminwrapper
              errorText={contentFormik.errors.wireframe_link}
              dataType={LINK}
              data={data}
              setData={setData}
              type="link1"
              label={"Iframe-Iframe Link"}
            >
              {!isIframeReady && <LazzyLoading />}
              <iframe
                onLoad={() => setIsIframeReady(true)}
                style={{
                  width: "100%",
                  height: isIframeReady ? "100%" : "5%",
                  opacity: isIframeReady ? 1 : 0,
                }}
                src={
                  isValidUrl(data?.wireframe_link)
                    ? data?.wireframe_link +
                      "?token=" +
                      localStorage.getItem("token") +
                      "&isIframe=true"
                    : "www.google.com"
                }
                frameBorder="0"
              ></iframe>
            </Adminwrapper>
          </ResizableBoxes.Left>
          <ResizableBoxes.Right>
            <Adminwrapper
              errorText={contentFormik.errors.wireframe_link1}
              dataType={LINK}
              data={data}
              setData={setData}
              type="link2"
              label={"Tiktok Link"}
            >
              <TikTokEmbed
                style={{ height: "100%" }}
                url={data?.wireframe_link1}
              />
            </Adminwrapper>
          </ResizableBoxes.Right>
        </ResizableBoxes>
      )}
    </>
  );
};
export default RenderIframIfram;
