
import React, { useState } from "react";
import {
    Button,
    Grid,
    Box,
    Typography,
    Stack
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTheme } from "@mui/material/styles";
import { Adminwrapper, TypographyWithMore } from "../../../common";
import UploadIcon from "@mui/icons-material/Upload";
import {
    ADMIN_LINK,
    checkQuilValidation,
    DATA_TYPE,
    isAdminAdd,
    isAdminEdit,
    renderPlaceholder,
    getName,
    isValidUrl,
    LazzyLoading,
} from "../../../common/contentType/contentTypeData";
import { useStyles } from "../../../../utils/styles";
import CustomCardWrapper from "../../../common/contentType/CustomCardWrapper";
import SimpleImageSlider from "react-simple-image-slider";
import CustomAudioPlayer from "../../../common/contentType/CustomAudioPlayer";
import StickbotTop from "../../../common/contentType/StickbotTop";

const RenderImageStickbotAudio = ({ content, user }) => {
    const classes = useStyles()
    const myObj = JSON.parse(content?.allContent?.content);
    const { video_link, audio_link, heading1, heading2, stickbot_link, text } =
        myObj;
    const [isAdd, setIsAdd] = useState(false);
    const { name, description, type } = content?.allContent;
    const [isIframeReady, setIsIframeReady] = useState(false);
    const [dataType, setDataType] = useState("audio")
    const [data, setStateData] = useState({
        name,
        description,
        type,
        wireframe_link: video_link,
        wireframe_link1: audio_link,
        heading1,
        heading2,
        link_1: stickbot_link,
        text,
    });
    const { QUILL, MEDIA, LINK, MULTI } = DATA_TYPE;
    const imagesVisibility = () => {
        return data?.wireframe_link?.map((image) => {
            if (!image.name)
                return { url: process.env.REACT_APP_S3_BUCKET_LINK + image };
            return { url: URL.createObjectURL(image) };
        });
    };

    const setData = (obj) => {
        setStateData(obj);
        contentFormik.setValues(obj);
    };
    const contentFormik = useFormik({
        initialValues: {
            name: data.name,
            type: data.type,
            description: data.description,
            heading1: data?.heading1,
            heading2: data?.heading2,
            wireframe_link: data?.wireframe_link,
            wireframe_link1: data?.wireframe_link1,
            link_1: data?.link_1,
            text: data?.text,
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required("Name is Required")
                .test("matches", "Name is required", checkQuilValidation),
            description: Yup.string()
                .required("Description is Required")
                .test("matches", "Description is required", checkQuilValidation),
            heading1: Yup.string().required("Heading is Required"),
            heading2: Yup.string().required("Heading is Required"),
            wireframe_link: Yup.array()
                .min(1, "At least one image is required")
                .required("At least one image is Required"),
            wireframe_link1: Yup.string().required("Audio is Required"),
            link_1: Yup.string().required("Stickbot link is Required"),
            text: Yup.string().required("Audio Subtitle is Required"),
        }),
        onSubmit: () => {
            handleAdminUpdate();
        },
    });
    const handleAdminUpdate = () => {
        window.parent.postMessage(
            isAdminAdd() ? { ...data, points: content?.points } : data,
            ADMIN_LINK
        );
    };
    return (
        <CustomCardWrapper height={"62vh"} mt="50px" px="34px" py="34px">
            {isAdminEdit() && (
                <Button className={classes.btnUpdateStyle} onClick={contentFormik.handleSubmit}>
                    Update
                </Button>
            )}
            {isAdminAdd() && (
                <Button className={classes.btnAddStyle} onClick={contentFormik.handleSubmit}>
                    ADD
                </Button>
            )}

            <Grid container sx={{ height: "100%" }}>
                <Grid className={classes.flexColCenterVertical} md={6} xs={12} sx={{ px: { sm: '16px' }, pr: { sm: 6 } }}>
                    <Adminwrapper
                        errorText={contentFormik.errors.name}
                        dataType={QUILL}
                        data={data}
                        setData={setData}
                        type="name"
                    >
                        {renderPlaceholder(data?.name, "Enter Name")}
                        {/* <QuillEditor sx={quilStyle} value={data.name} readOnly={true} /> */}
                        <TypographyWithMore textAlign="left" textLength={8} lines={2} variant="h2" color="primary" data={data?.name} />
                    </Adminwrapper>
                    <Adminwrapper
                        errorText={contentFormik.errors.description}
                        dataType={QUILL}
                        data={data}
                        setData={setData}
                        type="description"
                    >
                        {renderPlaceholder(data?.description, "Enter Description")}
                        {/* <QuillEditor
                                sx={quilStyle}
                                value={data.description}
                                readOnly={true}
                            /> */}
                        <TypographyWithMore textAlign="left" textLength={25} lines={4} variant="body1" color="text" data={data?.description} />
                    </Adminwrapper>
                    <Stack direction="row" spacing={2} className={classes.outerStack}>
                        <Button
                            variant="contained"                           
                            onClick={() => setDataType("audio")}
                            className={`${classes.button} ${dataType == "audio" ? classes.activeButton : classes.inactiveButton}`}
                            >
                            Audio
                        </Button>
                        {/* <Button
                            variant="contained"
                            onClick={() => setDataType("image")}
                            className={`${classes.button} ${dataType == "image" ? classes.activeButton : classes.inactiveButton}`}>
                            Image
                        </Button> */}
                        <Button
                            variant="contained"
                            onClick={() => setDataType("stickbot")}
                            className={`${classes.button} ${dataType == "stickbot" ? classes.activeButton : classes.inactiveButton}`}>
                            Stickbot
                        </Button>
                    </Stack>



                </Grid>
                <Grid md={6} xs={12} className={classes.flexColCenterVertical}>

                    {
                        dataType == "audio" ?
                            <Box>
                                {!!Object.keys(data).length && (
                                    <Adminwrapper
                                        errorText={contentFormik.errors.wireframe_link1}
                                        dataType={MEDIA}
                                        data={data}
                                        setData={setData}
                                        type="link2"
                                        mediaType={"AUDIO"}
                                    >
                                        <CustomAudioPlayer
                                            autoPlay={false}
                                            isMultiple={true}
                                            imagesVisibility={imagesVisibility}
                                            myObj={myObj}
                                            src={
                                                data?.wireframe_link1.includes("https")
                                                    ? data?.wireframe_link1
                                                    : process.env.REACT_APP_S3_BUCKET_LINK +
                                                    data?.wireframe_link1
                                            }
                                            text={data?.text || "No subtitle to show"}
                                        />
                                    </Adminwrapper>
                                )}
                                {(isAdminAdd() || isAdminEdit()) && (
                                    <Adminwrapper
                                        errorText={contentFormik.errors.text}
                                        dataType={LINK}
                                        data={data}
                                        setData={setData}
                                        type="text"
                                        label="Audio Subtitle"
                                    >
                                        {renderPlaceholder(data?.text, "Enter Audio Subtitle")}
                                        {/* <QuillEditor
                                        sx={quilStyle}
                                        value={data?.text}
                                        readOnly={true}
                                    /> */}
                                    </Adminwrapper>
                                )}
                            </Box>
                            : dataType == "image" ?
                                <Box
                                    style={{
                                        borderRadius: "20px",
                                        position: "relative",
                                        height: "400px",
                                        overflow: 'hidden'
                                    }}
                                >
                                    <Adminwrapper
                                        errorText={contentFormik.errors.wireframe_link}
                                        dataType={MULTI}
                                        data={data}
                                        type="link1"
                                        setData={setData}
                                    >
                                        <SimpleImageSlider
                                            width="100%"
                                            height="400px"
                                            style={{ borderRadius: "20px" }}
                                            images={imagesVisibility()}
                                            showBullets={false}
                                            showNavs={false}
                                            autoPlay={true}
                                            autoPlayDelay={parseFloat(myObj?.timer) || 1}
                                        />
                                    </Adminwrapper>

                                    {isAdminAdd() && (
                                        <>
                                            <div className={classes.addDropdown}>
                                                <Button startIcon={<UploadIcon />}>
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        className={classes.inputStyle}
                                                        onChange={(e) => {
                                                            setData({
                                                                ...data,
                                                                wireframe_link: [
                                                                    ...data?.wireframe_link,
                                                                    e.target.files[0],
                                                                ],
                                                            });
                                                        }}
                                                        accept="image/*"
                                                    />
                                                    Choose Image {data?.wireframe_link?.length + 1}
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </Box>
                                :
                                <>
                                    {/* <Adminwrapper
                                        errorText={contentFormik.errors.heading1}
                                        dataType={LINK}
                                        data={data}
                                        setData={setData}
                                        type="heading1"
                                        label={"Heading 1"}
                                    >
                                        {renderPlaceholder(data?.heading1, "Enter Heading 1")}
                                        <Typography variant="h5" sx={{ textAlign: "center", m: 1 }}>
                                            {data?.heading1}
                                        </Typography>
                                    </Adminwrapper>
                                    <Adminwrapper
                                        errorText={contentFormik.errors.heading2}
                                        dataType={LINK}
                                        data={data}
                                        setData={setData}
                                        type="heading2"
                                        label={"Heading 2"}
                                    >
                                        {renderPlaceholder(data?.heading2, "Enter Heading 2")}
                                        <Typography variant="h5" sx={{ textAlign: "center", m: 1 }}>
                                            {data?.heading2}
                                        </Typography>
                                    </Adminwrapper> */}

                                    <Adminwrapper
                                        errorText={contentFormik.errors.link_1}
                                        dataType={LINK}
                                        data={data}
                                        setData={setData}
                                        type="link_1"
                                        label={"Stickbot Link"}
                                    >
                                        
                                         <StickbotTop url={ isValidUrl(data?.link_1)
                                                    ? data?.link_1 + `?userId=${getName(user)}`
                                                    : "https://www.google.com"} title={data?.heading2}/>
                                        {/* {!isIframeReady && <LazzyLoading />}
                                        <iframe
                                            onLoad={() => setIsIframeReady(true)}
                                            style={{
                                                width: "100%",
                                                height: isIframeReady ? "90%" : "10%",
                                                opacity: isIframeReady ? 1 : 0,
                                            }}
                                            src={
                                                isValidUrl(data?.link_1)
                                                    ? data?.link_1 + `?userId=${getName(user)}`
                                                    : "https://www.google.com"
                                            }
                                            frameBorder="0"
                                        ></iframe> */}
                                    </Adminwrapper>
                                </>
                    }
                </Grid>
            </Grid>
        </CustomCardWrapper>
    );
};

export default RenderImageStickbotAudio