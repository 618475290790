import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { userLog } from '../_api/index'
import { useMutation } from 'react-query';
import TranslateModal from '../lessons/details/TranslateModal';

export default function Header(props) {
    const { mutate: log } = useMutation(userLog);
      const [openTranslate, setOpenTranslate] = useState(false);
    function handleClick(e) {
        e.preventDefault()
        window.history.go(-1)

        log({
            pageName: props?.page,
            buttonName: "Back"
        })
    }

    return (
        <>
            <TranslateModal openTranslate={openTranslate} setOpenTranslate={setOpenTranslate} />
            <div className="custom-header">
                <div className="container header-container">
                    <div className="left">
                        {
                            !props?.back ?
                            <>
                                <button
                                    className="btn-back"
                                    onClick={(e) => handleClick(e)}>
                                    <img src="/assets/new-svgs/arrow_back.svg" alt="" />
                                    Back
                                </button>
                            </>
                            :
                            <>
                            </>
                        }    
                        
                    </div>
                    <div className="center">{props?.title ? props?.title : props?.logo ? (<img width="130px" src="/assets/svg/logo.gif" alt=""></img>):undefined}</div>
                    <div className="right">


                        {
                            props?.translation  &&  
                            <>
                                     <div className="translate-buttons">

                                            <Link to="/" className="translate-it"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setOpenTranslate(true)
                                                log({
                                                pageName: 'Alt Space-vr',
                                                buttonName: 'Translate It'
                                                })
                                            }}>
                                            Translate It
                                            </Link>


                                            </div>

                            </>
                        }



                       {
                        props?.textLink &&
                        <>
                            <button className="btn-back" style={{ background: "#616ded",  minWidth: "10rem",  display: "flex",  justifyContent: "center" }}>
                            <Link style={{ color: 'white' }} to={props.textLink !== undefined && props.textLink}
                                onClick={() => log(
                                    { pageName: props?.page,
                                        buttonName: 'Skip'})} >
                               
                                {props?.text}
                            
                            </Link>
                             </button>
                        </>
                       }

                    </div>
                </div>
            </div>
        </>
    )
}
