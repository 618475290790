import React, {
  useState,
} from "react";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import "react-h5-audio-player/lib/styles.css";
import {
  IconButton,
  Grid,
  Button,
  Typography,
  Dialog,
  TextField,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import {
  DATA_TYPE, METAVERSE_DATA, isAdminEdit, isAdminAdd, isAdminView,
  Transition
} from "../contentType/contentTypeData";
import { AdminQuillEditor } from "../../lessons/details/quil-editor";



const Adminwrapper = (props) => {
  const {
    children,
    dataType,
    label,
    type,
    data,
    setData,
    formData,
    mediaType,
    gridIndex,
    errorText,
    style,
  } = props;
  const [isShowModal, setShowModal] = useState(false);
  // const [data, setData] = useState(props?.data)
  const [message, setMessage] = useState("");
  // const isAdminEdit() = (localStorage.getItem("view") === "admin-edit") //admin-edit || admin
  const { QUILL, MEDIA, LINK, MULTI, FORM, METAVERSE } = DATA_TYPE;
  const [imgData, setImgData] = useState(data?.wireframe_link);
  const [showName, setShowName] = useState("");
  const [file, setFile] = useState();
  const handleDataType = () => {
    switch (dataType) {
      case MEDIA:
        return renderMedia();
      case QUILL:
        return renderQuillEditor();
      case LINK:
        return renderLink();
      case MULTI:
        return renderMulti();
      case FORM:
        return renderForm(formData);
      case METAVERSE:
        return renderMetaverse();
    }
  };
  const handleChange = (e) => {
    if (type === "name") {
      setData({
        type: data.type,
        name: e,
        description: data.description,
        content: data?.content,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "content") {
      setData({
        type: data.type,
        name: data.name,
        description: data.description,
        content: e,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "description") {
      setData({
        type: data.type,
        name: data.name,
        description: e,
        content: data?.content,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "link1") {
      setData({
        type: data.type,
        name: data.name,
        content: data?.content,
        description: data.description,
        wireframe_link: e,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "link2") {
      setData({
        type: data.type,
        name: data.name,
        content: data?.content,
        description: data.description,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: e,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "heading1") {
      setData({
        type: data.type,
        name: data.name,
        content: data?.content,
        description: data.description,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: e,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "heading2") {
      setData({
        type: data.type,
        name: data.name,
        content: data?.content,
        description: data.description,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: e,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "link_1") {
      setData({
        type: data.type,
        name: data.name,
        content: data?.content,
        description: data.description,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: e,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "grid") {
      setData({
        type: data?.type,
        name: data?.name,
        content: data?.content,
        description: data?.description,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: e,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "dropdownTitle" || type === "dropdownDetail") {
      setData({
        type: data.type,
        name: data.name,
        content: data?.content,
        description: data.description,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: e,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "multilinks") {
      setData({
        type: data.type,
        name: data.name,
        description: data.description,
        content: e,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "text") {
      setData({
        type: data.type,
        name: data.name,
        description: data.description,
        content: data?.content,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: e,
        vidText: data?.vidText,
      });
    } else if (type === "vidText") {
      setData({
        type: data.type,
        name: data.name,
        description: data.description,
        content: data?.content,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: e,
      });
    }
  };

  const renderPdfMedia = (
    <>
      <Button startIcon={<UploadIcon />}>
        <input
          type="file"
          name="file"
          style={inputStyle}
          onChange={(e) => {
            handleChange(e.target.files[0]);
            setShowName(e.target.files[0]?.name);
          }}
          accept=".pdf"
        />
        Upload PDF
      </Button>
      <Typography color="#FF0C06" variant="subtitle2">
        {" "}
        {message}{" "}
      </Typography>
    </>
  );

  const renderGoogleSheetMedia = (
    <>
      <Button startIcon={<UploadIcon />}>
        <input
          type="file"
          name="file"
          style={inputStyle}
          onChange={(e) => {
            handleChange(e.target.files[0]);
            setShowName(e.target.files[0]?.name);
          }}
          accept=".xls ,.xlsx"
        />
        Upload Google Sheet
      </Button>
    </>
  );

  const renderImageVideoAndAudio = (
    <>
      <Button startIcon={<UploadIcon />}>
        <input
          type="file"
          name="file"
          style={inputStyle}
          onChange={(e) => {
            if (e.target.files[0]?.size > 5242900) {
              setMessage(
                "File size is too big. Try to upload less than or equal to 4MBs"
              );
            } else if (type === "grid") {
              let tempGrid = data?.grid;
              tempGrid[gridIndex] = e.target.files[0];
              handleChange(tempGrid);
            } else {
              setMessage("");
              handleChange(e.target.files[0]);
              setShowName(e.target.files[0]?.name);
            }
          }}
          accept={
            mediaType === "VIDEO"
              ? "video/*"
              : mediaType === "IMAGE"
                ? "image/*"
                : "audio/*"
          }
        />
        Upload file
      </Button>
      <Typography color="#FF0C06" variant="subtitle2">
        {" "}
        {message}{" "}
      </Typography>
    </>
  );

  const renderPowerPointMedia = (
    <>
      <Button startIcon={<UploadIcon />}>
        <input
          type="file"
          name="file"
          style={inputStyle}
          onChange={(e) => {
            handleChange(e.target.files[0]);
            setShowName(e.target.files[0]?.name);
          }}
          accept=".pptx"
        />
        Upload Power Point File
      </Button>
    </>
  );

  const renderMedia = () => {
    return (
      <Grid container spacing={3}>
        <Grid item md={4} sx={{ mt: "5%" }}>
          {mediaType == "PDF"
            ? { renderPdfMedia }
            : mediaType == "GoogleSheet"
              ? { renderGoogleSheetMedia }
              : mediaType == "PowerPoint"
                ? { renderPowerPointMedia }
                : { renderImageVideoAndAudio }}
        </Grid>
      </Grid>
    );
  };
  const renderQuillEditor = () => {
    return (
      <AdminQuillEditor
        placeholder="Write something"
        sx={{ height: 400, width: 800 }}
        value={
          type === "name"
            ? data.name
            : type === "content"
              ? data?.content
              : type === "description"
                ? data?.description
                : type === "link2"
                  ? data?.wireframe_link1
                  : type === "dropdownDetail"
                    ? data.dropdowns[gridIndex]?.detail
                    : ""
        }
        onChange={(e) => {
          if (type === "dropdownDetail") {
            let tempData = data?.dropdowns?.map((item, i) =>
              i === gridIndex ? { detail: e, title: item?.title } : item
            );
            handleChange(tempData);
          } else handleChange(e);
        }}
      />
    );
  };
  const renderLink = () => {
    const getLink = () => {
      if (type === "multilinks") {
        if (gridIndex === 1) return data?.content?.link_1;
        if (gridIndex === 2) return data?.content?.link_2;
        if (gridIndex === 3) return data?.content?.link_3;
        if (gridIndex === 4) return data?.content?.link_4;
        if (gridIndex === 5) return data?.content?.link_5;
        return "";
      }
    };
    return (
      <TextField
        sx={{ width: 500 }}
        type={type === "points" ? "number" : "text"}
        label={label || "New Link"}
        name="contentContent"
        onChange={(e) => {
          if (type === "dropdownTitle") {
            let tempData = data?.dropdowns?.map((item, i) =>
              i === gridIndex
                ? { title: e.target.value, detail: item.detail }
                : item
            );
            handleChange(tempData);
          } else if (type === "multilinks") {
            let tempData = data?.content;
            if (gridIndex === 1)
              tempData = { ...tempData, link_1: e.target.value };
            if (gridIndex === 2)
              tempData = { ...tempData, link_2: e.target.value };
            if (gridIndex === 3)
              tempData = { ...tempData, link_3: e.target.value };
            if (gridIndex === 4)
              tempData = { ...tempData, link_4: e.target.value };
            if (gridIndex === 5)
              tempData = { ...tempData, link_5: e.target.value };
            handleChange(tempData);
          } else if (type === "points") {
            if (e.target.value > 0) {
              setData(e.target.value);
            } else {
              setData(null);
            }
          } else handleChange(e.target.value);
        }}
        required
        value={
          type === "text"
            ? data?.text
            : type === "points"
              ? data
              : type === "content"
                ? data?.content
                : type === "link1"
                  ? data?.wireframe_link
                  : type === "link2"
                    ? data?.wireframe_link1
                    : type === "heading1"
                      ? data?.heading1
                      : type === "heading2"
                        ? data?.heading2
                        : type === "dropdownTitle"
                          ? data?.dropdowns[gridIndex].title
                          : type === "link_1"
                            ? data?.link_1
                            : type === "multilinks"
                              ? getLink()
                              : ""
        }
      />
    );
  };
  const renderMulti = () => {
    return (
      <Grid
        container
        spacing={4}
        style={{
          width: 400,
        }}
      >
        {imgData &&
          imgData.map((img, i) => (
            <>
              <Grid item md={6} sm={6} key={i}>
                <img
                  width="100%"
                  height={100}
                  src={process.env.REACT_APP_S3_BUCKET_LINK + img}
                  alt=""
                  type="image/png"
                />
              </Grid>
              <Grid item md={6} sm={6} key={i}>
                <Button
                  startIcon={<UploadIcon />}
                  sx={{
                    mt: 3,
                  }}
                >
                  <input
                    type="file"
                    name="file"
                    style={{
                      position: "absolute",
                      left: "1px",
                      backgroundColor: "white",
                      padding: "10px",
                      opacity: "0%",
                      width: "50%",
                    }}
                    onChange={(e) => {
                      if (e.target.files[0]?.size > 5242900) {
                        setMessage(
                          "File size is too big. Try to upload less than or equal to 4MBs"
                        );
                      } else {
                        setMessage("");
                        setImgData([
                          ...imgData.map((item, index) =>
                            index == i ? e.target.files[0] : item
                          ),
                        ]);
                        handleChange([
                          ...imgData.map((item, index) =>
                            index == i ? e.target.files[0] : item
                          ),
                        ]);
                      }
                    }}
                    accept={"image/*"}
                  />
                  Upload Image
                </Button>
              </Grid>
              <Grid item md={12} sm={12}>
                <Typography color="#FF0C06" variant="subtitle2">
                  {message}
                </Typography>
              </Grid>
            </>
          ))}
      </Grid>
    );
  };
  const renderForm = (formData) => {
    return (
      <FormControl sx={{ minWidth: 220 }}>
        <InputLabel id="demo-simple-select-label">
          {data?.type === "Chatbot" ? "Select Chatbot" : "Select Form"}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="contentContent"
          id="demo-simple-select"
          onChange={(e) => handleChange(e.target.value)}
          value={data.content}
        >
          {formData?.map((form) => {
            return data?.type === "GoogleForm" ? (
              <MenuItem key={form.id} value={form.id}>
                {form.name}
              </MenuItem>
            ) : data?.type === "Chatbot" ? (
              <MenuItem key={form.id} value={form?.description?.slice(8)}>
                {form.name}
              </MenuItem>
            ) : (
              <MenuItem key={form.id} value={form.id}>
                {form.title}
              </MenuItem>
            );
          })}
          {/* {formLoading ? (
                              <MenuItem>
                                  <CircularProgress />
                              </MenuItem>
                          ) : (
                              forms?.map((form) => {
                                  return (
                                      <MenuItem key={form.name} value={form.id}>
                                          {form.name}
                                      </MenuItem>
                                  );
                              })
                          )} */}
        </Select>
      </FormControl>
    );
  };
  const renderMetaverse = () => {
    return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select Mataverse</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="contentContent"
          id="demo-simple-select"
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          style={{ minWidth: 200 }}
          value={data.content}
          label="Content"
        >
          {METAVERSE_DATA?.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {" "}
              {item.label}{" "}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  if (!isAdminEdit() && !isAdminAdd()) return children;
  if (isAdminEdit() || isAdminAdd())
    return (
      <>
        <div style={adminWraperEdit}>
          {children}
          <IconButton
            onClick={() => setShowModal(true)}
            style={editButtonStyle}
          >
            <DriveFileRenameOutlineRoundedIcon style={{ color: "#fff" }} />
          </IconButton>
          <Dialog
            open={isShowModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setShowModal(false)}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="xxs"
            style={{
              backgroundColor: "rgba(1000, 1000, 1000, 0.2)",
              overflow: "visible",
            }}
          >
            <IconButton
              onClick={() => setShowModal(false)}
              style={crossButtonStyle}
            >
              <SaveAsRoundedIcon style={{ color: "#fff" }} />
            </IconButton>
            {handleDataType()}
          </Dialog>
        </div>
        {!!errorText && <p style={errorStyle}>{errorText}</p>}
      </>
    );
};



const inputStyle = {
  position: "absolute",
  left: "1px",
  backgroundColor: "white",
  width: "95%",
  opacity: "0%",
  textAlign: "center",
};

const editButtonStyle = {
  float: "right",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  margin: 0,
  padding: 5,
  position: "absolute",
  top: "5px",
  right: "5px",
  zIndex: 1000,
  backgroundColor: "rgba(0,0,0,0.7)",
};
const adminWraperEdit = {
  border: "1px solid #B9C0C0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: 10,
  margin: 10,
  borderRadius: "15px",
  boxShadow: "0px 0px 30px 0px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  position: "relative",
  backgroundColor: "#fff",
};
const crossButtonStyle = {
  padding: 5,
  position: "absolute",
  right: 10,
  top: -40,
  boxShadow: "0 0 20px 0 rgba(255,255,255,0.3)",
  backgroundColor: "rgba(0,0,0,0.5)",
};

const errorStyle = {
  margin: 0,
  color: "red",
  textAlign: "left",
  paddingLeft: "20px",
  fontSize: 12,
};

export default Adminwrapper

