import { useState, useEffect } from "react";
import "react-h5-audio-player/lib/styles.css";
import {
  IconButton,
  Grid,
  Box,
  Button,
  Dialog,
  DialogTitle,
  useTheme,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";
import { Adminwrapper, TypographyWithMore } from "../../../common";
import {
  ADMIN_LINK,
  CCTransition,
  checkQuilValidation,
  DATA_TYPE,
  isAdminAdd,
  isAdminEdit,
  isJson,
  renderPlaceholder,
} from "../../../common/contentType/contentTypeData";
import { useFormik } from "formik";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import ReplayIcon from "@mui/icons-material/Replay";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
import CustomCardWrapper from "../../../common/contentType/CustomCardWrapper";
import { useStyles } from "../../../../utils/styles";

const VideoSixGrid = ({ content }) => {
  const [muted, setMuted] = useState(false);
  const [replay, setReplay] = useState(false);
  const [ccModal, setCCModal] = useState(false);
  const [text, setText] = useState("");
  const [showButton, setShowButton] = useState(false);

  let contentArr = [];
  let gridText = [];
  const theme = useTheme();
  const classes = useStyles();

  if (isJson(content.allContent?.content)) {
    let tempContent = JSON.parse(content?.allContent?.content);
    contentArr = tempContent.videoNames?.split("$");
    gridText = tempContent.gridTranscripts;
  } else {
    contentArr = content?.allContent?.content?.split("$") || [];
  }

  const { name, description, type } = content?.allContent;
  const [data, setStateData] = useState({
    name,
    description,
    type,
    grid: contentArr?.filter((item) => item !== ""),
  });
  const [nextPlay, setNextPlay] = useState([]);
  const { QUILL, MEDIA } = DATA_TYPE;

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.grid,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.array().min(2, "Upload 2 videos at minimum!"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  const videoVisibility = (url) => {
    if (!url?.name) return process.env.REACT_APP_S3_BUCKET_LINK + url;
    return URL.createObjectURL(url);
  };
  useEffect(() => {
    data?.grid?.map((item, i) => {
      if (i === 0) {
        setNextPlay((old) => [...old, true]);
      } else {
        setNextPlay((old) => [...old, false]);
      }
    });
  }, [data]);
  function playVid(index) {
    var vid = document.getElementById(index + 1);
    if (vid) {
      vid.play();
    }
    if (data?.grid?.length == index + 1) {
      setReplay(true);
    }
  }
  return (
    <>
      {isAdminEdit() && (
        <Button
          className={classes.btnUpdateStyle}
          onClick={contentFormik.handleSubmit}
        >
          Update
        </Button>
      )}
      {isAdminAdd() && (
        <Button
          className={classes.btnAddStyle}
          onClick={contentFormik.handleSubmit}
        >
          ADD
        </Button>
      )}
      <CustomCardWrapper height={"fit-content"}>
        <Grid container mb="1.5rem">
          <Grid item xs={12} md={9}>
            <Adminwrapper
              errorText={contentFormik.errors.name}
              dataType={QUILL}
              data={data}
              setData={setData}
              type="name"
            >
              {renderPlaceholder(data?.name, "Enter Name")}

              <TypographyWithMore
                textLength={10}
                lines={2}
                variant="h2"
                color="primary"
                data={data?.name}
              />
            </Adminwrapper>

            <Adminwrapper
              errorText={contentFormik.errors.description}
              dataType={QUILL}
              data={data}
              setData={setData}
              type="description"
            >
              {renderPlaceholder(data?.description, "Enter Description")}

              <TypographyWithMore
                textLength={50}
                lines={6}
                variant="body1"
                color="text"
                data={data?.description}
              />
            </Adminwrapper>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            {!!data?.grid?.length && (
              <Box display="flex" gap={{ xs: 1, md: 1.5 }}>
                <IconButton
                  variant="successCircle"
                  onClick={() => setMuted(!muted)}
                  aria-label="Volume"
                >
                  {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </IconButton>
                <IconButton
                  variant="successCircle"
                  disabled={!replay}
                  aria-label="Replay"
                  onClick={() => {
                    playVid(-1);
                    setReplay(false);
                  }}
                >
                  <ReplayIcon />
                </IconButton>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
          justifyContent="center"
          gap={{ xs: "8px", md: "13px" }}
          sx={{
            maxHeight: "100%",
            overflowY: "auto",
          }}
        >
          {data?.grid?.map((arr, index) => {
            return (
              arr !== "" && (
                <Grid
                  item
                  xs={4}
                  md={data?.grid?.length > 2 ? 2 : 2.8}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "fit-content",
                    position: "relative",
                    // marginTop: index > 1 && "30px",
                  }}
                  key={arr}
                >
                  <Adminwrapper
                    dataType={MEDIA}
                    data={data}
                    setData={setData}
                    type="grid"
                    gridIndex={index}
                    mediaType={"VIDEO"}
                  >
                    <Box
                      onMouseEnter={() => setShowButton(true)}
                      onMouseLeave={() => setShowButton(false)}
                    >
                      <video
                        style={{ width: "100%", height: "100%", objectFit: "contain" }}
                        id={index}
                        controls={true}
                        autoPlay={nextPlay[index]}
                        onEnded={(e) => {
                          // setPlayVideo((index + 1).toString())
                          playVid(index);
                        }}
                        muted={muted}
                      >
                        <source type="video/mp4" src={videoVisibility(arr)} />
                      </video>

                      {showButton && (
                        <IconButton
                          className={classes.ccVideoButtonSix}
                          onClick={() => {
                            setCCModal(true);
                            setText(gridText[index]);
                          }}
                        >
                          <ClosedCaptionIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Adminwrapper>
                </Grid>
              )
            );
          })}

          {isAdminAdd() && (
            <>
              <Box className={classes.addDropdown}>
                <Button startIcon={<UploadIcon />}>
                  <input
                    type="file"
                    name="file"
                    className={classes.inputStyle}
                    onChange={(e) => {
                      setData({
                        ...data,
                        grid: [...data?.grid, e.target.files[0]],
                      });
                    }}
                    accept="video/*"
                  />
                  Choose Video {parseInt(data?.grid?.length || 0) + 1}
                </Button>
              </Box>
            </>
          )}
          {(isAdminAdd() || isAdminEdit()) &&
            !!contentFormik.errors.content && (
              <Typography className={classes.errorStyle}>
                {contentFormik.errors.content}
              </Typography>
            )}
        </Grid>
        <Dialog
          open={ccModal}
          TransitionComponent={CCTransition}
          keepMounted
          onClose={() => setCCModal(false)}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: "1rem",
            },
          }}
        >
          <DialogTitle
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            Transcript
            <IconButton onClick={() => setCCModal(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Typography className={classes.audioText}>{text}</Typography>
        </Dialog>
      </CustomCardWrapper>
    </>
  );
};

export default VideoSixGrid;
