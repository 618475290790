import React, { forwardRef, useState } from 'react'
import { userLog } from '../../_api/index'
import { useMutation } from 'react-query';

import {
    TextField,
    Grid,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    Slide,
    Typography,
    Button
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import translate from "translate";
import UploadIcon from '@mui/icons-material/Upload';
import axios from 'axios'
import toast from 'react-hot-toast'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TranslateModal(props) {
    const [textoriginal, setTextoriginal] = useState('');
    const [texttranslation, setTexttranslation] = useState('');
    const [language, setLanguage] = useState("es")
    const [loading, setLoading] = useState(false)
    const [translationType, setTranslationType] = useState("text")

    const { mutate: log } = useMutation(userLog);

    const translatetospan = (text) => {
        translate.engine = "google"; // Or "yandex", "libre", "deepl"
        translate.key = process.env.GOOGLE_KEY;
        setLoading(true)
        translate(text, { from: "en", to: language }).then((res) => {
            setTexttranslation(res);
            setLoading(false)
        });


    }


    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        toast.loading("Loading please wait...")
        // Create a FormData object to send the file
        const formData = new FormData();
        formData.append('file', file);
        formData.append('model', 'whisper-1');
        formData.append('response_format', 'json');
        try {
            // Make API request to OpenAI for audio transcription
            const response = await axios.post(
                'https://api.openai.com/v1/audio/transcriptions',
                formData,
                {
                    headers: {
                        'Authorization': 'Bearer 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',
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            setTextoriginal(response.data.text);
            translatetospan(response.data.text)
            toast.dismiss()
        } catch (error) {
            console.error(error);
            toast.dismiss()
        }
    };

    return (
        <>
            <Dialog
                open={props.openTranslate}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => props.setOpenTranslate(false)}
                fullWidth
                maxWidth='md'
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton style={{ height: 'fit-content' }} onClick={() => {
                        log({
                            pageName: 'Translate Modal',
                            buttonName: 'Close'
                        })
                        props.setOpenTranslate(false)
                    }}><CloseOutlinedIcon /></IconButton>
                </DialogTitle>
                <DialogContent>

                    {/* <iframe width="100%" height="500px" src="https://stickball-es2eng-translate.vercel.app/" frameborder="0"></iframe> */}
                    <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item xs={12} sx={{ p: 1 }}>
                            <TextField sx={{ mt: 1 }} fullWidth label={"Select Translator Type"} select
                                SelectProps={{ native: true }}
                                value={translationType}
                                onChange={(e) => setTranslationType(e.target.value)}>
                                <option value="audio">Audio</option>
                                <option value="text">Text</option>
                            </TextField>
                        </Grid>
                        {translationType === "audio" &&
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    startIcon={<UploadIcon fontSize="small" />}
                                    sx={{ m: 1, width: 'fit-content', margin: '1rem 0', padding: '1rem' }}
                                >
                                    <input
                                        type="file"
                                        multiple
                                        style={{
                                            position: "absolute",
                                            left: "1px",
                                            backgroundColor: "white",
                                            padding: "10px",
                                            opacity: "0%",
                                            width: '100%'
                                        }}
                                        onChange={handleFileUpload}
                                        accept={"audio/*"}
                                    />
                                    Upload Audio
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={12} sx={{ p: 1 }}>
                            <TextField sx={{ mt: 1 }} fullWidth label={"Choose Language"} select
                                SelectProps={{ native: true }}
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}>
                                <option value="es">Spanish</option>
                                <option value="pt">Portuguese</option>
                                <option value="ht">Haitian Creole</option>
                            </TextField>
                        </Grid>
                        <Grid style={{ border: '1px solid #ccc', margin: 10, padding: 0, backgroundColor: 'rgba(0, 0, 0, 0.09)' }} item xs={5.7}>
                            <Typography sx={{ px: 2, pt: 2 }} variant="h6">English</Typography>
                            <TextField
                                value={textoriginal} onChange={(e) => setTextoriginal(e.target.value)}
                                InputProps={{
                                    sx: { backgroundColor: 'transparent', transition: 'none', padding: 2, }
                                }} placeholder='Write here...' fullWidth variant='standard' multiline rows={10} />
                        </Grid>
                        <Grid item xs={5.7} style={{ border: '1px solid #ccc', margin: 10, padding: 0, backgroundColor: 'rgba(0, 0, 0, 0.09)' }}>
                            <Typography sx={{ px: 2, pt: 2 }} variant="h6">{language === "es" ? "Spanish" : language === "ht" ? "Haitian Creole" : "Portuguese"}</Typography>
                            <TextField
                                value={loading ? "..." : texttranslation}
                                InputProps={{
                                    sx: { backgroundColor: 'transparent', transition: 'none', padding: 2, }
                                }} variant='standard' fullWidth multiline rows={10} />
                        </Grid>
                        <Grid xs={12} sx={{ p: 1 }}>
                            <Button style={{ width: "100%" }} variant="contained" className="loginscreen__button loginscreen__buttonone"
                                onClick={() => { translatetospan(textoriginal) }}>
                                Translate
                            </Button>
                        </Grid>

                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}