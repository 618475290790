import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/material/styles';

import ReactQuill from 'react-quill'

const QuillEditorRoot = styled(ReactQuill)(({ theme }) => ({
    border: 1,
    borderColor: '#ccc',
    borderRadius: '15px',
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    '& .ql-snow.ql-toolbar': {
        borderColor: '#ccc',
        borderRadius: '15px 15px 0px 0px',
        // borderLeft: 'none',
        // borderRight: 'none',
        // borderTop: 'none',
        // '& .ql-picker-label:hover': {
        //     color: theme.palette.primary.main
        // },
        // '& .ql-picker-label.ql-active': {
        //     color: theme.palette.primary.main
        // },
        // '& .ql-picker-item:hover': {
        //     color: theme.palette.primary.main
        // },
        // '& .ql-picker-item.ql-selected': {
        //     color: theme.palette.primary.main
        // },
        // '& button:hover': {
        //     color: theme.palette.primary.main,
        //     '& .ql-stroke': {
        //         stroke: theme.palette.primary.main
        //     }
        // },
        // '& button:focus': {
        //     color: theme.palette.primary.main,
        //     '& .ql-stroke': {
        //         stroke: theme.palette.primary.main
        //     }
        // },
        // '& button.ql-active': {
        //     '& .ql-stroke': {
        //         stroke: theme.palette.primary.main
        //     }
        // },
        // '& .ql-stroke': {
        //     stroke: "#ccc"
        // },
        // '& .ql-picker': {
        //     color: theme.palette.text.primary
        // },
        // '& .ql-picker-options': {
        //     backgroundColor: theme.palette.background.paper,
        //     border: 'none',
        //     borderRadius: theme.shape.borderRadius,
        //     boxShadow: theme.shadows[10],
        //     padding: theme.spacing(2)
        // }
    },
    '& .ql-toolbar button.ql-header svg': {
        display: 'none',
    },

    '& .ql-toolbar button.ql-header.ql-active': {
        color: '#3891d0',
    },
    '& .ql-toolbar button.ql-header[value="3"]::before': {
        content: '"H3"',
        fontWeight: '600',
        marginTop: '-2px',
        marginLeft: '1px',
        fontSize: '14px',
    },
    '& .ql-toolbar button.ql-header[value="2"]::before': {
        content: '"H2"',
        fontWeight: '600',
        marginTop: '-2px',
        marginLeft: '1px',
        fontSize: '14px',
    },
    '& .ql-toolbar button.ql-header[value="1"]::before': {
        content: '"H1"',
        fontWeight: '600',
        marginTop: '-2px',
        marginLeft: '1px',
        fontSize: '14px',
    },
    '& .ql-snow.ql-container': {
        // borderBottom: 'none',
        borderColor: '#ccc',
        borderRadius: '0px 0px 15px 15px',
        // borderLeft: 'none',
        // borderRight: 'none',
        flexGrow: 1,
        overflow: 'hidden',
        // '& .ql-editor': {
        //     color: theme.palette.text.primary,
        //     fontFamily: theme.typography.body1.fontFamily,
        //     fontSize: theme.typography.body1.fontSize,
        //     padding: theme.spacing(2),
        //     '&.ql-blank::before': {
        //         color: theme.palette.text.secondary,
        //         fontStyle: 'normal',
        //         left: theme.spacing(2)
        //     }
        // }
    }
}));

const modules = {
    toolbar: [
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large'] }],
        [{ 'header': 1 }, { 'header': 2 }, { 'header': 3 }],
        [{ 'align': [] }],
        [{ 'direction': 'rtl' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link'],
        ['clean']
    ],
}
const modules1 = {
    toolbar: [
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large'] }],
        [{ 'header': 1 }, { 'header': 2 }, { 'header': 3 }],
        [{ 'align': [] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'direction': 'rtl' }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ],
}

export const QuillEditor = (props) => (
    <QuillEditorRoot modules={props.quiz ? modules1 : modules} {...props} />
);
