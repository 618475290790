import { isExpired } from "react-jwt";
import { isStatic } from "../common/isStatic";
const isAuthenticated = (token) => {
  if (isStatic) return true;
  if (sessionStorage.getItem('access') === 'ok') {
    return true
  }
  if (token === undefined) {
    return false;
  }
  if (token === "NULL") {
    return false;
  }
  if (token === null) {
    return false;
  }
  return !isExpired(token);
};
export default isAuthenticated;
