import React from 'react'
import { useTranslation } from 'react-i18next';
import './loader_styles/loader.css';
import { Typography } from '@mui/material';


export default function Loading() {

    const { t } = useTranslation();
    return (
        <>
            <div className="full-container">
                <img src="/assets/svg/logo.gif" style={{ marginTop: "2rem" }} width="130px" />
                <div className="contain">
                    <img src="/assets/img/loader.png" className="load" />
                    <div class="bubbles">
                        <div class="firstbubble"></div>
                        <div class="secondbubble"></div>
                        <div class="thirdbubble"></div>
                        <div class="fourthbubble"></div>
                        <div class="fifthbubble"></div>
                    </div>
                </div>
                <br />
                <Typography textAlign="center" variant='h3' color="primary">{t("loading.text")}</Typography>
            </div>
        </>
    )
}
