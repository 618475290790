import React, { useState, useEffect } from 'react'
import TurnPhoneV from '../../other/TurnPhoneV'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { emptyPoints } from '../../../redux-features/user/userSlice'

import { userLog } from '../../_api/index'
import { useMutation } from 'react-query';
import isAuthenticated from '../../auth/auth'
import SkillsBar from '../skills/SkillsBar';
import { useTranslation } from 'react-i18next'
import RewardsModal from '../details/RewardsModal'
import Loading from '../../common/Loading'
import { Box, Button } from '@mui/material'
import { isStatic } from '../../common/isStatic'



export default function QuizResult() {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [totalPoints, setTotalPoints] = useState(0)
    const [isSkillCompleted, setIsSkillCompleted] = useState(false)
    const lessonId = useSelector(state => state.user.lessonId);
    const quiz = useSelector(state => state.user?.quiz)
    const moduleId = useSelector(state => state.user.moduleId);
    const completedSkills = useSelector(state => state.user.completedSkills)
    const skillId = useSelector(state => state.user.skillId);
    const skillSections = useSelector(state => state?.user?.skillSections)
    let loading = useSelector(state => state?.user?.loading)
    const { mutate: log } = useMutation(userLog);
    const [open, setOpen] = useState(false)
    const modules = useSelector((state) => state.user.userData?.data)

    useEffect(() => {
        let totalQuizPoints = 0
        if (!!quiz?.length) {
            quiz?.map(que => {
                totalQuizPoints += que?.points;
            })
        }
    }, [quiz])

    useEffect(() => {
        if (skillSections?.length > 0) {
            let temp = 0;
            skillSections?.map(ss => {
                temp = temp + ss?.allContent?.points
            })
            setTotalPoints(temp)
        }
    }, [skillSections])



    const handleNavigation = () => {
        if (isSkillCompleted) {
            navigate(`/lesson?id=${moduleId}`)
        } else {
            navigate(`/skills?moduleId=${moduleId}&lessonId=${lessonId}&skillId=${skillId}`)
        }
    }

    useEffect(() => {

        if (isStatic) {
            setIsSkillCompleted(completedSkills?.includes(+skillId))
            if (completedSkills?.includes(+skillId)) {
                navigate(`/lesson?id=${moduleId}`)
            } else {
                navigate(`/skills?moduleId=${moduleId}&lessonId=${lessonId}&skillId=${skillId}`)
            }
        } else {
            const foundSubSkills = modules?.find(module => module?.id === moduleId)
                ?.lessons?.find(lesson => lesson?.id === lessonId)
                ?.skills?.find(skill => skill?.id === skillId)
                ?.subSkills || [];

            if (foundSubSkills.filter(ss => { return (ss.isCompleted) })?.length === foundSubSkills?.length) {
                setIsSkillCompleted(true)
            }
        }

    }, [modules, isStatic, completedSkills, skillId])

    if (loading) {
        return <Loading />
    } else {
        return (
            <> {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
                <RewardsModal open={open} setOpen={setOpen} />
                <SkillsBar page="Quiz Result" />
                <div className="skills-details-container quiz-result" style={{ maxHeight: "100vh", overflow: "hidden" }}>
                    <div className="row text-center m-0 p-0" style={{ height: '100%' }}>
                        {/* <h6>Module 1</h6> */}
                        <div className="container-feedback" style={{ height: "90vh" }}>
                            <div className="row m-0 p-0">
                                <h2 className="text-center mt-3">Results</h2>
                            </div>
                            <div className="row feedback-content d-flex justify-content-center m-0 mt-2">
                                <div className="col-4">
                                    <div className="correct-ans">
                                        <h1>{skillSections?.length}/{skillSections?.length}</h1>
                                        <h4>{t("quizResult.completed")}</h4>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <img src="../assets/svg/lesson/quiz-result.svg" alt="" />
                                    {
                                        !!quiz?.length && <h3 className="mt-2">Quiz Score 100%</h3>
                                    }

                                </div>
                                <div className="col-4">
                                    <div className="correct-ans">
                                        <h1>{totalPoints}</h1>
                                        <h4>{t("quizResult.pointsEarned")}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                <h5>{t("quizResult.congratulationsOnCompletion")}</h5>
                            </div>
                            <Box display="flex" justifyContent="center" mt={2}>
                                <Button
                                    variant="contained"
                                    color='primary'
                                    onClick={() => {
                                        log({
                                            pageName: 'Quiz Result',
                                            buttonName: 'Next'
                                        })
                                        dispatch(emptyPoints())
                                        handleNavigation()
                                    }}
                                >
                                    Next
                                </Button>
                            </Box>
                        </div>


                    </div>
                </div>

            </>
        )
    }


}
