
import {
    Box,
    Grid,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material';
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';


export const EventsOfDayModal = ({ openCurrentEvents, setOpenCurrentEvents, events, skillCardStyle, openedCardDetails, day }) => {
    return (
        <Dialog
            open={openCurrentEvents}
            onClose={() => setOpenCurrentEvents(false)}
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'end' }}>
                <IconButton onClick={() => setOpenCurrentEvents(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: 3, maxWidth: '400px', maxHeight: '450px', overflow: 'auto' }}>
                <Grid>
                    {
                        !events?.length &&
                        <Box>
                            <Typography sx={{ fontWeight: 600, textAlign: 'center' }}>{moment(day).format('MMM DD YYYY')}</Typography>
                            <Typography>There are no events scheduled on this day.</Typography>
                        </Box>
                    }
                    {
                        events?.map((elem, index) => {
                            return (
                                <Box key={index} sx={{ ...skillCardStyle, ...openedCardDetails, borderColor: elem.event_color }} >
                                    <Box>
                                        <Typography sx={{ m: 1, fontWeight: 'bold', color: elem.event_color }}>{elem?.event_name} </Typography>

                                        <Typography sx={{ m: 1, }}>From: {moment(elem?.start_date).format("MM-DD-YYYY")} </Typography>
                                        <Typography sx={{ m: 1, }}>  To: {moment(elem?.end_date).format("MM-DD-YYYY")} </Typography>
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    )
}