import React from 'react';
import './loading.css'

export const LoadingDots = () => {
    return (
        <div className="bouncing-balls-container">
            <div className="ball ball1"></div>
            <div className="ball ball2"></div>
            <div className="ball ball3"></div>
        </div>
    );
};
