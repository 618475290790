import { Box, Dialog, DialogTitle, IconButton, Typography, useTheme } from "@mui/material";
import ReactPlayer from "react-player";
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";
import { useState } from "react";
import { CCTransition } from "./contentTypeData";
import CloseIcon from "@mui/icons-material/Close";

const CustomVideoPlayer = (props) => {
  const { url, text, height } = props;
  const [ccModal, setCCModal] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const theme = useTheme()

  return (
    <>
      <Dialog
        open={ccModal}
        TransitionComponent={CCTransition}
        keepMounted
        onClose={() => setCCModal(false)}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Transcript
          <IconButton onClick={() => setCCModal(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Typography sx={audioText}>{text}</Typography>
      </Dialog>
      <Box
        sx={audioPlayerStyle}
        onMouseEnter={() => setShowButton(true)}
        onMouseLeave={() => setShowButton(false)}
      >
        <ReactPlayer
          url={url}
          playing={true}
          loop={false}
          controls={true}
          width="fit-content"
          height="400px"
          style={{
            width: "fit-content",
            objectFit: "contain",
            maxHeight: "60vh"
          }}
        />
        {showButton && (
          <IconButton sx={ccVideoButton} onClick={() => setCCModal(true)}>
            <ClosedCaptionIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
};

const audioPlayerStyle = {
  position: "relative",
  width: "fit-content",
  p: 0
};

const ccVideoButton = {
  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
  position: "absolute",
  top: "5px",
  right: "5px",
  color: (theme) => `${theme.palette.common.white}`,
  backgroundColor: (theme) => `${theme.palette.common.lightGray}`,
};

const audioText = {
  // minWidth: 200,
  // maxWidth: 500,
  textAlign: "left",
  padding: "20px",
  margin: 0,
  overflow: "auto",
  whiteSpace:'pre-wrap'

};

export default CustomVideoPlayer;
