import React from 'react'

export default function BudgetLogo() {
    return (
        <>
            <div className="redirect-item">
                
                <img width='40px' src="../assets/svg/money logo.png" alt="" />
            </div>
        </>
    )
}
