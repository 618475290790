import React from 'react'

import { slideInUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import { userLog } from '../../_api/index'
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';


const styles = {
    slideInUp: {
        animation: 'x 0.8s',
        animationName: Radium.keyframes(slideInUp, 'slideInLeft'),
    },

}

export default function RewardsModal(props) {

    const user = useSelector((state) => state?.user?.userData?.user)
    const { mutate: log } = useMutation(userLog);
    return (
        <>

            <StyleRoot>
                <div className={props.open ? "full-screen-shadow concents-modal modal fade show" : "concents-modal modal fade"} style={{ display: props.open ? "block" : "none" }} id="RewardsModal">
                    <div className="modal-dialog modal-dialog1 modal-dialog-centered" style={styles.slideInUp}>
                        <div className="modal-content" style={{ borderRadius: '30px' }}>
                            <div className="modal-header">
                                <h1 style={{ width: '100%', fontSize: '17px', fontWeight: '600', marginLeft: '30px' }} className="text-center text-dark">Progress</h1>
                                <button type="button" className="close text-dark opacity-full" data-dismiss="modal" onClick={() => {
                                    log({
                                        pageName: 'Words Cape',
                                        buttonName: 'Close'
                                    })
                                    props.setOpen(false)
                                }}>
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body p-0" style={{padding: "1rem 0"}}>
                                <div className="quiz-result m-0">
                                    <div className="row d-flex justify-content-center align-items-center text-center m-0 p-0" style={{ height: '100%' }}>
                                        <div className="container-feedback"
                                        >
                                            <div className="row feedback-content d-flex justify-content-center m-0 p-0">
                                                <div className="col-4">
                                                    <div className="correct-ans">
                                                        <h1>{user?.earnPoints}</h1>
                                                        <h4>Points Earned</h4>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <img src="../assets/svg/lesson/quiz-result.svg" alt="" />
                                                </div>
                                                <div className="col-4">
                                                    <div className="correct-ans" style={{height: "100%"}}>
                                                        <h1>{user?.totalPoints}</h1>
                                                        <h4>Total Points</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer tips-modal-footer justify-content-center">
                                <button
                                    type="button"
                                    className="submit-btn"
                                    data-dismiss="modal"
                                    style={{ padding: '11px', margin: '0px', borderRadius: '10.4px' }}
                                    onClick={() => {
                                        log({
                                            pageName: 'Rewards',
                                            buttonName: 'Ok Button'
                                        })
                                        props.setOpen(false)
                                    }}
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </StyleRoot>
        </>
    )
}