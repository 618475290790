import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import { useSelector, useDispatch } from 'react-redux'
import { setModuleName } from '../../../redux-features/user/userSlice'
import BudgetLogo from '../../common/BudgetCal'

import { userLog } from '../../_api/index'
import { useMutation } from 'react-query';
import { isMoneyVisible } from './isMoneyVisible'
import Swal from 'sweetalert2'
import { useTheme } from '@mui/material/styles'
import { isStatic } from '../../common/isStatic';

export default function SideBar(props) {
    const dispatch = useDispatch()
    const { mutate: log } = useMutation(userLog);
    const { nextLink, setNextLink } = props
    const modules = useSelector((state) => state.user.userData?.data)
    const user = useSelector((state) => state?.user?.userData?.user)
    const { search, pathname } = new URL(window.location.href);
    const theme = useTheme()

    useEffect(() => {
        modules?.map(module => {
            if (module?.id == nextLink) {
                dispatch(setModuleName(module?.name))
            }
        })
    }, [nextLink, modules])
    let userEmail = localStorage.getItem("userEmail") || ""
    let userDetials = JSON.parse(localStorage.getItem("userDetails")) || ""
    let userName = userDetials?.name || ""

    return (
        <>
            <div className="sidebar-container" style={{ position: 'fixed', height: '100%', backgroundColor: theme.palette.primary.main }}>
                <div className="sidebar" style={{ paddingBottom: '60px', paddingTop: '60px', justifyContent: 'center' }}>
                    {
                        !isStatic &&
                        <Link to="/profile" className="bar-item  mb-2"
                            onClick={(e) => {
                                if (sessionStorage.getItem("access") === "ok") {
                                    e.preventDefault();
                                    Swal.fire({
                                        title: '',
                                        text: "It's a teacher view, so there is no profile to show!",
                                        icon: 'info',
                                        confirmButtonText: 'OK'
                                    })
                                }
                            }}>
                            <img className='profileimage' src={user?.photo ? user.photo : "../assets/img/dp_default.png"} alt="" />
                        </Link>
                    }
                    {
                        modules?.map((module) => {
                            return (<>
                                <Link to={"/lesson?id=" + module?.id} key={module?.id} className={nextLink == module.id ? "bar-item  active" : "bar-item"}
                                    onClick={(e) => {
                                        log({
                                            pageName: props?.page,
                                            buttonName: module?.name
                                        })
                                        !module.isEnrolled ?
                                            e.preventDefault()
                                            :
                                            setNextLink(module.id)
                                    }}
                                >
                                    <img src={module?.icon?.svg} alt="" />
                                    {
                                        module.isEnrolled || module.isCompleted ?
                                            <></> : <LockOpenRoundedIcon />

                                    }

                                    <p>{module.name}</p>
                                </Link>
                            </>)
                        })
                    }
                    {
                        isMoneyVisible(user) &&
                        <a
                            href={`${process.env.REACT_APP_BUDG_LINK}${search}&path=${pathname.replace('/', '')}&userName=${userName}&isIframe=false&userEmail=${userEmail}`}
                            onClick={(e) => {
                                if (sessionStorage.getItem("access") === "ok") {
                                    e.preventDefault();
                                    Swal.fire({
                                        title: '',
                                        text: "It's a teacher view, so you can't access to money app!",
                                        icon: 'info',
                                        confirmButtonText: 'OK'
                                    })
                                }
                                log({
                                    pageName: "Lesson",
                                    buttonName: "Budjet Calculator",
                                })
                            }
                            }
                        >
                            <BudgetLogo />
                        </a>
                    }
                </div>
            </div>
        </>
    )
}

// user?.OrganizationId == "65fdbd97bdd44a00129afad7" ? null :