import React from 'react'

export default function CurrentRow(props) {
    const { word, value } = props
    let arr = [...word]
    let val = [...value]
    return (
        <>
            <div className="row">
                {
                    val?.map((v, i) => {
                        return (
                            <div key={i} className={"letter bg-white1 white-shadow box-size" + word.length}>
                                {v}
                            </div>
                        )
                    })
                }
                {
                    arr.slice(val.length).map((_, i) => {
                        return (
                            <div key={i} className={"letter bg-white1 white-shadow empty-size" + word.length}>

                            </div>
                        )
                    })
                }

            </div>
        </>
    )
}
