import React, { useState, useEffect } from 'react'

import { IconButton, Switch, FormControlLabel } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import PasswordIcon from '@mui/icons-material/Password';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getCard, freezCard, getCardHistory, syncOnlinePurchase, getDebitCard, freezDebitCard, getDebitCardHistory } from '../_api/card'
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Alert from '@mui/material/Alert';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import CardSVG from './CardSVG'

const today = new Date()
export default function CreditCard() {
    const queryClient = useQueryClient();
    const user = useSelector(state => state.user.userData.user)
    const { isLoading, data } = useQuery(["get-card", user?.id], () => getDebitCard(user?.id))
    const { isHistoryLoading, data: historyData } = useQuery(["get-card-history", user?.id], () => getDebitCardHistory(user?.id))
    const [reveal, setReveal] = useState(false)
    const [sort, setSort] = useState("0")
    const [value, setValue] = useState(dayjs('2016-08-20'));
    const [cardData, setCardData] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)
    const [transactions, setTransactions] = useState([])
    const [filter, setFilter] = useState("1")
    const [isSync, setIsSync] = useState(user?.createTransactionDetails);
    const { mutate: cardFreez } = useMutation(["freez-card", user.id, data?.data?.[0]?.id], () => freezDebitCard(user.id, data?.data?.[0]?.id), {
        onSuccess: () => {
            queryClient.invalidateQueries(["get-card", user?.id])
            toast.dismiss()
            toast.success("Done")
            setReveal(false)
        }
    })

    useEffect(() => {
        if (!isLoading) {
            setCardData(data?.data)
        }
    }, [isLoading, data])

    useEffect(() => {
        if (!isHistoryLoading) {
            let tempDates = []
            let tempTrans = []
            historyData?.transactionHistory?.map(x => {
                if (tempDates?.filter(y => moment(x?.createdAt).isSame(moment(y), "month"))?.length === 0) {
                    tempDates.push(x.createdAt)
                }
            })
            tempDates?.map(x => {
                tempTrans.push({
                    date: x,
                    data: historyData?.transactionHistory?.filter(y => moment(x).isSame(moment(y?.createdAt), "month"))
                })
            })
            setTransactions(tempTrans || [])
        }
    }, [isHistoryLoading, historyData])

    const handleSyncPurchase = async () => {
        try {
            const res = await syncOnlinePurchase(user?.id);
            setIsSync(res.create_transaction_details);
            toast.success("Transaction Details Setting Updated", 3000)
        } catch (err) {
            toast.error(err)
        }
    }

    const handleChange = (newValue) => {
        setValue(newValue);
    };
    const [value1, setValue1] = useState(dayjs('2020-08-20'));

    const handleChange1 = (newValue) => {
        setValue1(newValue);
    };
    const dateFormate = (date) => {
        let dat = moment(date).format("M/YY")
        return (dat)
    }

    function currencyNumber(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const totalSpending = (data) => {
        let total = 0
        data?.map(item => {
            if (item?.transactionType === "debit") {
                total = total + parseFloat(item?.amount)
            }
        })
        return currencyNumber(total)
    }
    const applySort = (array) => {
        if (sort === "0") {
            array?.sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
            });
            return (array)
        } else {
            array?.sort(function (a, b) {
                return new Date(a.createdAt) - new Date(b.createdAt);
            });
            return (array)
        }

    }
    const sortByMonth = (array) => {
        if (sort === "0") {
            array?.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            });
            return (array)
        } else {
            array?.sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
            });
            return (array)
        }
    }
    const applyFilters = (data) => {
        if (filter === "1") {
            return (data?.filter(x => moment(x?.createdAt).isSame(moment(today), "month")))
        } else if (filter === "-1") {
            return (data?.filter(x => moment(x?.createdAt).isSame(moment(today).subtract(1, 'month'), "month")))
        } else if (filter === "12") {
            return (data?.filter(x => moment(x?.createdAt).isSame(moment(today), "year")))
        } else if (filter === "-12") {
            return (data?.filter(x => moment(x?.createdAt).isSame(moment(today).subtract(1, 'year'), "year")))
        } else {
            return (data)
        }
    }
    const handlePagiantion = (event, value) => {
        setPage(value);

    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const returnName = () => {
        const { userID } = cardData;
        if (!userID) return "Anonymous"
        const { firstName } = userID;

        if (!firstName) return "Anonymous"
        const names = [firstName].toString()

        if (names.toString().length > 18) return names.toString().replace(",", " ").slice(0, 16) + "..."
        return firstName
    }

    const paginatedData = applySort(transactions)?.slice(((page - 1) * 6), (page * 6))
    if (isLoading || isHistoryLoading) {
        return (
            <div className="row d-flex justify-content-center align-content-center" style={{ height: 300 }}>
                <CircularProgress />
            </div>
        )
    } else
        return (
            <>
                <div className="row pl-5 pb-3">
                    {
                        cardData?.isBlocked &&
                        <Alert style={{ width: '100%' }} severity="error">Dear user, Your card has been blocked !</Alert>
                    }
                    <div className="col-md-4">
                        <div className="balance">
                            <div className="balance-label">Balance</div>
                            <div className="amount">${currencyNumber(parseFloat(cardData?.currentAmount))}</div>
                        </div>
                        <div className="visa_card">
                            <CardSVG
                                name={cardData?.userID ? returnName() : "__User __Name"}
                                cardNumber={cardData?.cardNumber}
                                cvv={cardData?.cvv}
                                expiry={dateFormate(cardData?.expiryDate)}
                                reveal={reveal}

                            />
                        </div>

                        <div className="card-details balance">
                            <div className="balance-label">More Actions</div>
                            <div className="buttons">
                                <div className="visa_btn">
                                    <IconButton
                                        disabled={cardData?.tempFreeze || cardData?.isBlocked}
                                        onClick={() => setReveal(!reveal)}
                                    >
                                        {
                                            reveal ?
                                                <img width="30px" src="assets/new-svgs/eye_closed.svg" alt="" />
                                                : (cardData?.tempFreeze || cardData?.isBlocked) ?
                                                    <img width="30px" src="assets/new-svgs/eye-disabled.svg" alt="" />
                                                    :
                                                    <img width="30px" src="assets/new-svgs/eye.svg" alt="" />
                                        }
                                    </IconButton>
                                    <h6>{reveal ? "Hide Details" : "Reveal Details"}</h6>
                                </div>
                                <div style={{ marginTop: 15 }} >
                                    <FormControlLabel labelPlacement="bottom" control={<Switch sx={{ marginBottom: 1.2 }} checked={isSync} onClick={handleSyncPurchase} />} label="Sync Data" />
                                </div>
                                <div className="visa_btn">
                                    <IconButton onClick={() => {
                                        toast.loading("Loading...")
                                        cardFreez(cardData.id)
                                    }}
                                        style={{ padding: '10px', textAlign: 'center' }}
                                        disabled={cardData?.isBlocked}
                                    >
                                        {
                                            cardData?.isBlocked ?
                                                <img width="40px" src="assets/new-svgs/freez-disabled.svg" alt="" />
                                                : cardData?.tempFreeze ?
                                                    <img width="40px" src="assets/new-svgs/freezed-icon.svg" alt="" />
                                                    :
                                                    <img width="40px" src="assets/new-svgs/freez-icon.svg" alt="" />
                                        }
                                    </IconButton>
                                    <h6>{cardData?.tempFreeze ? "Unfreeze Card" : "Freeze Card"}</h6>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="row filters">
                            <select onChange={(e) => setFilter(e.target.value)} value={filter}>
                                <option value="1">This Month</option>
                                <option value="-1">Last Month</option>
                                <option value="12">This Year</option>
                                <option value="-12">Last Year</option>
                                <option value="all">All History</option>
                            </select>
                            <select onChange={(e) => setSort(e.target.value)} value={sort}>
                                <option value="0">Recent First</option>
                                <option value="1">Old First</option>
                            </select>
                        </div>
                        <div className="row">
                            <Paper sx={{ width: '100%', height: '100%', overflow: 'hidden', boxShadow: 'none' }}>
                                <TableContainer sx={{ maxHeight: 440 }} id="style-3">
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow className="table-head">
                                                <TableCell
                                                    align='left'
                                                    style={{ width: '20%' }}
                                                >
                                                    Date
                                                </TableCell>
                                                <TableCell
                                                    align='left'
                                                    style={{ width: '60%' }}
                                                >
                                                    Title & Description
                                                </TableCell>
                                                <TableCell
                                                    align='right'
                                                    style={{ width: '20%' }}
                                                >
                                                    Amount
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                !applyFilters(transactions)?.length ?
                                                    <TableRow className="table-row" >
                                                        <TableCell colspan="3" align="center" className="td-day mt-3" sx={{ borderBottom: 'none' }}>No transaction history found</TableCell>
                                                    </TableRow>
                                                    :
                                                    sortByMonth(applyFilters(transactions))
                                                        ?.map((transaction) => {
                                                            return (
                                                                <>
                                                                    <TableRow className="table-row" >
                                                                        <TableCell className="td-day" sx={{ borderBottom: 'none' }}>
                                                                            {moment(transaction?.date).format("MMM[, ]  YYYY")}
                                                                            {/* {
                            />
                        </div>

                        <div className="card-details balance">
                            <div className="balance-label">More Actions</div>
                            <div className="buttons">
                                <div className="visa_btn">
                                    <IconButton
                                        disabled={cardData?.tempFreeze || cardData?.isBlocked}
                                        onClick={() => setReveal(!reveal)}
                                    >
                                        {
                                            reveal ?
                                                <img width="30px" src="assets/new-svgs/eye_closed.svg" alt="" />
                                                : (cardData?.tempFreeze || cardData?.isBlocked) ?
                                                    <img width="30px" src="assets/new-svgs/eye-disabled.svg" alt="" />
                                                    :
                                                    <img width="30px" src="assets/new-svgs/eye.svg" alt="" />
                                        }
                                    </IconButton>
                                    <h6>{reveal ? "Hide Details" : "Reveal Details"}</h6>
                                </div>
                                <div className="visa_btn">
                                    <IconButton onClick={() => {
                                        toast.loading("Loading...")
                                        cardFreez()
                                    }}
                                        style={{ padding: '10px', textAlign: 'center' }}
                                        disabled={cardData?.isBlocked}
                                    >
                                        {
                                            cardData?.isBlocked ?
                                                <img width="40px" src="assets/new-svgs/freez-disabled.svg" alt="" />
                                                : cardData?.tempFreeze ?
                                                    <img width="40px" src="assets/new-svgs/freezed-icon.svg" alt="" />
                                                    :
                                                    <img width="40px" src="assets/new-svgs/freez-icon.svg" alt="" />
                                        }
                                    </IconButton>
                                    <h6>{cardData?.tempFreeze ? "Unfreeze Card" : "Freeze Card"}</h6>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="row filters">
                            <select onChange={(e) => setFilter(e.target.value)} value={filter} style={{
                                    cursor: 'pointer'
                            }}>
                                <option value="1">This Month</option>
                                <option value="-1">Last Month</option>
                                <option value="12">This Year</option>
                                <option value="-12">Last Year</option>
                                <option value="all">All History</option>
                            </select>
                            <select onChange={(e) => setSort(e.target.value)} value={sort}  style={{
                                    cursor: 'pointer'
                            }}F>
                                <option value="0">Recent First</option>
                                <option value="1">Old First</option>
                            </select>
                        </div>
                      
                        <div className="row">
                            <Paper sx={{ width: '100%', height: '100%', overflow: 'hidden', boxShadow: 'none' }}>
                                <TableContainer sx={{ maxHeight: 440 }} id="style-3">
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow className="table-head">
                                                <TableCell
                                                    align='left'
                                                    style={{ width: '20%' }}
                                                >
                                                    Date
                                                </TableCell>
                                                <TableCell
                                                    align='left'
                                                    style={{ width: '60%' }}
                                                >
                                                    Title & Description
                                                </TableCell>
                                                <TableCell
                                                    align='right'
                                                    style={{ width: '20%' }}
                                                >
                                                    Amount
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                !applyFilters(transactions)?.length ?
                                                    <TableRow className="table-row" >
                                                        <TableCell colspan="3" align="center" className="td-day mt-3" sx={{ borderBottom: 'none' }}>No transaction history found</TableCell>
                                                    </TableRow>
                                                    :
                                                    sortByMonth(applyFilters(transactions))
                                                        ?.map((transaction) => {
                                                            return (
                                                                <>
                                                                    <TableRow className="table-row" >
                                                                        <TableCell className="td-day" sx={{ borderBottom: 'none' }}>
                                                                            {moment(transaction?.date).format("MMM[, ]  YYYY")}
                                                                            {/* {
                                                                    moment(transaction?.date).isSame(moment(), "day") ?
                                                                        "Today"
                                                                        :
                                                                        moment(transaction?.date).add(1, 'days').isSame(moment(), "day")
                                                                            ?
                                                                            "Yesterday"
                                                                            :
                                                                            moment(transaction?.date).format("MMM[, ]  YYYY")
                                                                } */}
                                                                        </TableCell>
                                                                        <TableCell colspan="2" align="right" className="td-day" sx={{ borderBottom: 'none' }}>Amount Spent: $ {totalSpending(transaction?.data)}</TableCell>
                                                                    </TableRow>
                                                                    {
                                                                        applySort(transaction?.data)?.map(item => {
                                                                            return (
                                                                                <TableRow className="table-row" sx={{ borderRadius: "1rem", "&:hover": { background: item?.transactionType === 'debit' ? '#ffcaca' : '#c6ffd2' } }} >
                                                                                    <TableCell className="td-1" sx={{ borderBottom: 'none' }}>
                                                                                        <p className="date"> {moment(item?.createdAt).format("MMM DD[, ] YYYY")}</p>
                                                                                        <p className="time">{moment(item?.createdAt).format("LT")}</p>
                                                                                    </TableCell>
                                                                                    <TableCell className="td-2" sx={{ borderBottom: 'none' }}>
                                                                                        {
                                                                                            item?.transactionType === "debit" ?
                                                                                                <>
                                                                                                    <p className="title">Stickball Store</p>
                                                                                                    <p className="details">{item?.description}</p>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <p className="title">Stickball Bank</p>
                                                                                                    <p className="details">Cash Deposit</p>
                                                                                                </>
                                                                                        }

                                                                                    </TableCell>
                                                                                    <TableCell className="td-3" align="right" sx={{ borderBottom: 'none', color: item?.transactionType === 'debit' ? '#ED3C3C' : '#33C052' }}>
                                                                                        {
                                                                                            item?.transactionType === 'debit' ?
                                                                                                <>-${currencyNumber(item?.amount)}</>
                                                                                                :
                                                                                                <>+${currencyNumber(item?.amount)}</>
                                                                                        }
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        })
                                                                    }

                                                                </>
                                                            );
                                                        })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* <TablePagination
                                rowsPerPageOptions={[15, 30, 100]}
                                component="div"
                                count={transactions?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            /> */}
                            </Paper>
                        </div>


                        {/* <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        marginTop: 20,
                    }}>
                        <Pagination count={transactions?.length % 6 === 0 ? transactions?.length / 6 : (parseInt(transactions?.length / 6) + 1)} page={page} onChange={handlePagiantion} color="primary" />
                    </div> */}

                    </div>

                </div>
            </>
        )
}
