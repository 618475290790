import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

import { slideInUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import { userLog } from '../_api/index'
import { useMutation } from 'react-query';


const styles = {
    slideInUp: {
        animation: 'x 2s',
        animationName: Radium.keyframes(slideInUp, 'slideInLeft')
    },
}

export default function TermsAndConditions(props) {

    const { mutate: log } = useMutation(userLog);
    const navigate = useNavigate()
    return (
        <StyleRoot>
            <div className={props.termCond ? "full-screen-shadow concents-modal modal fade show" : "concents-modal modal fade"} style={{ display: props.termCond ? "block" : "none" }} id="myModal">
                <div className="modal-dialog" style={styles.slideInUp}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1>Terms of Service and Privacy Policy</h1>
                            <button
                                onClick={() => {
                                    props.setTermCond(false)
                                    log({
                                        pageName: 'Terms and Conditions',
                                        buttonName: 'Close'
                                    })
                                }} type="button" className="close">
                                &times;
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="d-flex flex-column justify-content-start">
                                <p>
                                    <h6>Stickball Terms of Service</h6>
                                    <br />
                                    <span>
                                        These terms of service will become a legally binding agreement (this
                                        “Agreement”) between you and Stickball, Inc. ("Stickball") when you
                                        indicate acceptance as provided below. You should read this Agreement
                                        carefully before clicking to indicate acceptance.
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b> 1. Not financial advice.</b> The Services (as defined below) are not intended
                                        to provide financial advice. The information, services and other content
                                        provided on and through the Services, including information that may be
                                        provided on the services directly or via linking to third-party sites,
                                        are provided for informational purposes only. Please consult with a
                                        financial professional regarding any financial decisions, investment
                                        decisions, or taxes. The information provided on the service, including
                                        information relating to financial matters, is often provided in summary
                                        or aggregate form. Again, it is not intended as a substitute for advice
                                        from a financial professional.
                                    </span>


                                    <br />
                                    <br />
                                    <span>
                                        <b> 2. Permission to use.</b> Subject to the terms and conditions of this
                                        Agreement, Stickball hereby provides you with the right and license to
                                        access and to use Stickball's online software (the “Services”) for your
                                        lawful, non-commercial personal use. You will be able to use the Services
                                        for as long as you remain a subscriber of the services in good standing,
                                        except as otherwise provided in this Agreement. As between you and
                                        Stickball, Stickball owns all right, title and interest in and to all
                                        intellectual property rights in the Services including, without
                                        limitation, the contents of any of our websites or services, such as the
                                        compilation and arrangement of content including text, graphics, images
                                        and other materials, and the hypertext markup language (HTML), scripts,
                                        active server pages (ASP), content provided by RSS feed, atom feed or
                                        other web feed or other content or software used in or provided through
                                        any such website or services. Stickball™, the Stickball™ logo,
                                        Stickball.biz™, and the and other marks are tradenames and trademarks of
                                        Stickball. Stickball's tradename and trademarks may not be used by you in
                                        connection with any product or service that is not offered by Stickball
                                        or in any manner that is likely to cause confusion in the marketplace or
                                        that disparages or discredits Stickball.
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b> 3. Your data.</b>  Subject to the terms and conditions of this Agreement, by
                                        indicating your acceptance of this Agreement, you grant to Stickball the
                                        right to copy, store, record, transmit, maintain, display, view, print,
                                        or otherwise use any data or information of any nature whatsoever,
                                        provided to Stickball by you in the course of your use of the Services
                                        (“Your Data”) to the extent necessary to provide the Services to you.
                                        Further, you irrevocably grant to Stickball the right to use aggregated,
                                        de-identified portions of Your Data (“Aggregated Data”), including
                                        without limitation to perform comparative studies and to provide services
                                        to third-parties, and to disclose Aggregated Data to third-parties in
                                        accordance with Stickball's Privacy Policy (as it may be amended from
                                        time to time), which is incorporated into this agreement by reference
                                        (“Privacy Policy”), for their use in accordance with applicable law.
                                        Without limiting the foregoing, Stickball may disclose Your Data to third
                                        parties (i) that are contracted to provide any portion of the Services to
                                        you, for use solely to the extent necessary in providing such Services to
                                        you; and (ii) that are subject to a contractual obligation to protect the
                                        confidentiality of Your Data in their possession, in each case subject to
                                        the terms and conditions of this Agreement.
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b> 4.  Changes to this Agreement and the Services.</b> Stickball reserves the
                                        right, in its sole and absolute discretion, to change, modify, add to,
                                        supplement or delete any of the terms and conditions of this Agreement
                                        (including Stickball's Privacy Policy) at any time, effective with or
                                        without prior notice; provided, however, that material changes (as
                                        determined in Stickball's sole and absolute discretion) will be disclosed
                                        as follows: Stickball will provide you with notification of any such
                                        changes by email, postal mail, website or application posting, pop-up
                                        screen, or in-service notice. If any future changes to this Agreement are
                                        unacceptable to you or cause you to no longer be in compliance with this
                                        Agreement, you must immediately stop using, the Services. Your continued
                                        use of the Services following any revision to this Agreement constitutes
                                        your complete and irrevocable acceptance of any and all such changes.
                                        Stickball may change, modify, suspend, or discontinue any aspect of the
                                        Services at any time. Stickball may also impose limits on certain
                                        features or restrict your access to parts or all of the Services without
                                        notice or liability.
                                    </span>


                                    <br />
                                    <br />
                                    <span>
                                        <b> 5. Email and notices.</b> You agree to accept emails from Stickball in
                                        connection with your use of the Services, including any and all notices,
                                        statements, and other communications to you.
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b> 6. Username and password.</b> You are prohibited from sharing your password
                                        and/or username with any other party. You will be responsible for all
                                        activities that take place under your password and/or username. You agree
                                        to immediately notify Stickball if you become aware of any loss or theft
                                        or unauthorized use of your password and/or user name.
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b>7.Communications.</b> You must provide at your own expense the equipment and
                                        internet connections that you will need to access and use the Services.
                                        Also, if you access the Services through wireless devices (e.g., cell
                                        phones), your carrier, such as a wireless carrier, may charge fees for
                                        alerts, web browsing, messaging and other services that require the use
                                        of airtime and wireless data services. Check with your carrier to verify
                                        whether there are any such fees that may apply to you. You are solely
                                        responsible for any costs you incur to access the Services through any
                                        wireless or other communication service.
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b> 8. Transmission of data.</b> You acknowledge that the technical processing
                                        and transmission of Your Data is fundamentally necessary to your use of
                                        the Services. You expressly consent to Stickball's receipt and storage of
                                        Your Data, and you acknowledge and understand that the use of the
                                        Services will involve transmission of your data over the internet, and
                                        over various networks, only part of which may be owned and/or operated by
                                        Stickball. You acknowledge and understand that Your Data may be accessed
                                        by unauthorized parties when communicated across the internet, network
                                        communications facilities, telephone, or other electronic means. You
                                        agree that Stickball is not responsible for any of Your Data which are
                                        delayed, lost, altered, intercepted, or stored without authorization
                                        during the transmission of any such data across networks not owned and/or
                                        operated by Stickball, including, but not limited to, the internet.
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b>9. Services depend on Your Data.</b> You understand that certain features of
                                        and reports provided in connection with the Services may not be available
                                        to you if you do not provide sufficient information to Stickball and may
                                        not be accurate, current, or complete if Your Data contains any
                                        inaccuracies.
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b>10. Links/third-party data.</b> The Services may provide, or third parties
                                        may provide, links to other internet sites or resources. Because
                                        Stickball has no control over such sites and resources, you agree that
                                        Stickball is not responsible for the availability of such external sites
                                        or resources and does not endorse and is not responsible or liable for
                                        any content, advertising, products, services, or other materials on or
                                        available from such sites or resources. In addition, you agree that
                                        Stickball is not responsible for any third-party product or information
                                        that may be provided to you through the Services (e.g., through the
                                        integration of the Services with a third-party online application).
                                        Stickball may provide to you certain reports that contain information
                                        licensed by Stickball from third-parties who restrict the use of such
                                        reports to use within the United States. You agree that if any reports
                                        provided to you by Stickball specify that they may be used only within
                                        the United States, you will only use such reports within the United
                                        States.  The use, posting, distribution, or publication on or through the
                                        services of any listing, content, product, or information does not
                                        constitute or imply an endorsement, recommendation, advice, opinion or
                                        comment by Stickball nor an assurance of legality, quality or safety by
                                        Stickball. Stickball cannot guarantee, and assumes no responsibility for
                                        verifying, the truthfulness or accuracy of the information provided by
                                        others, including other users of the Services. Stickball shall not be
                                        responsible for any offer, sale, purchase, or other transaction made,
                                        content posted, or other communications made by any third-party posting
                                        information or offering services or products through the Services. Any
                                        contacts you make with another person or entity, or decisions you make
                                        facilitated through the Services are strictly on your own. You must do
                                        your own due diligence and use your best judgment.
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b>11. Suspension for ongoing harm.</b> You agree that Stickball may suspend
                                        your access to the Services if Stickball reasonably concludes that your
                                        use of the Services is causing, or may possibly cause, harm to Stickball
                                        or others. In the extraordinary event that Stickball suspends access to
                                        the Services under this section, Stickball will use commercially
                                        reasonable efforts to resolve the issues causing the suspension of
                                        services. You agree that Stickball will not be liable to you or any
                                        third-party for any suspension of the Services under such circumstances
                                        as described in this section 11.
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b>12. Warranties. </b>
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b>12.1. Security, data, and integrity.</b> Stickball warrants that Stickball
                                        will use commercially reasonable efforts to safeguard and accurately
                                        maintain the integrity of Your Data, utilizing at a minimum industry
                                        standard security and backup procedures. In the event of a breach of this
                                        provision, Stickball will use commercially reasonable efforts to correct
                                        Your Data or restore Your Data as quickly as commercially practicable.
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b>12.2. Disclaimer of warranties. </b> Except as stated in this section 12, (i)
                                        Stickball does not make any representations or warranties, express or
                                        implied, including without limitation, those of merchantability, non-
                                        infringement of third-party rights or fitness for a particular purpose,
                                        and (ii) the Services are provided to you on an “as is” and “as
                                        available” basis. Stickball does not represent that your use of the
                                        Services will be secure, timely, uninterrupted, or error-free or that the
                                        Services will be accurate, reliable, complete, or meet your requirements
                                        or that all errors in the Services will be corrected or that the system
                                        that makes the Services available will be free of viruses or other
                                        harmful components. The warranties stated in this section 12 are the sole
                                        and exclusive warranties offered by Stickball, and the remedies provided
                                        in this section 12 are the sole and exclusive obligations of Stickball
                                        with respect to any breach of such warranties. You assume all
                                        responsibility for determining whether the Services or the information
                                        generated thereby is accurate or sufficient for your purposes.
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b>13. Limitations of liability.</b> To the maximum extent permitted by law, in
                                        no event will Stickball, its affiliates, agents, suppliers, partners,
                                        information providers, and their respective officers, directors,
                                        employees, contractors and agents be liable to you for any damages
                                        whatsoever arising from or in connection with this Agreement or the use
                                        or inability to use any Services, including without limitation direct,
                                        incidental, consequential, punitive, special or exemplary damages, and
                                        indirect damages of any type or kind, and whether or not Stickball has
                                        been advised of the possibility of such damage. If notwithstanding the
                                        foregoing it is found by a court of competent jurisdiction that Stickball
                                        is liable to you for damages, Stickball's maximum aggregate liability to
                                        you will in no case exceed the actual direct damages arising as a result
                                        of the act or omission complained of or $500, whichever is less. The
                                        essential purpose of this provision is to limit the potential liability
                                        of Stickball from this Agreement. Certain states and/or jurisdictions do
                                        not allow the exclusion of implied warranties or limitations of liability
                                        for incidental or consequential damages, so the exclusions set forth
                                        above may not apply.
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b>14. Indemnification and release.</b>
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b>14.1. </b>As a material inducement to provide you with use of the Services,
                                        you hereby agree to indemnify, defend and hold Stickball, and its
                                        officers, directors, owners, agents, partners, information providers, and
                                        affiliates (collectively, the “Indemnified Parties”) harmless from and
                                        against any and all damages, liability, claims, actions, demands and
                                        costs incurred by the Indemnified Parties in connection with any claim
                                        arising out of any breach by you of this Agreement, including, reasonable
                                        attorneys' fees and costs.
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b>14.2. </b> If you have a dispute involving the Services with a third party,
                                        you release each of the Indemnified Parties from all damages, liability,
                                        claims, actions, demands and costs of every kind and nature, known and
                                        unknown, suspected, and unsuspected, disclosed and undisclosed, arising
                                        out of or in any way connected with such disputes. If you are a
                                        California resident, you waive California civil code §1542, which says:
                                        “A general release does not extend to claims that the creditor or
                                        releasing party does not know or suspect to exist in his or her favor at
                                        the time of executing the release and that, if known by him or her, would
                                        have materially affected his or her settlement with the debtor or
                                        released party.”
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b>15. Infringement notice.</b> It is Stickball's policy to respond to claims of
                                        intellectual property infringement. We will promptly process and
                                        investigate notices of alleged infringement and take appropriate actions
                                        under the Digital Millennium Copyright Act, Title 17, United States Code,
                                        Section 512(c)(2) (“DMCA”) and other applicable intellectual property
                                        laws. In accordance with the DMCA, notifications of claimed copyright
                                        infringement should be submitted to the following designated agent for
                                        our website: info@Stickball.biz or mailed to the following address:
                                        Stickball, Inc., Attn. Legal Department/DMCA Compliance, 158 Endicott
                                        Street, Boston MA 02113.
                                        To be effective under the DMCA, the notification must be a written
                                        communication that includes the following: 1. A physical or electronic
                                        signature of a person authorized to act on behalf of the owner of an
                                        exclusive right that is allegedly infringed; 2. Identification of the
                                        copyrighted work claimed to have been infringed, or, if multiple
                                        copyrighted works on our website are covered by a single notification, a
                                        representative list of such works on our website; 3. Identification of
                                        the material that is claimed to be infringing or to be the subject of
                                        infringing activity and that is to be removed or access to which is to be
                                        disabled, and information reasonably sufficient to permit us to locate
                                        the material; 4. Information reasonably sufficient to permit us to
                                        contact the complaining party, such as an address, telephone number and,
                                        if available, an electronic mail address at which the complaining party
                                        may be contacted; 5. A statement that the complaining party has a good-
                                        faith belief that use of the material in the manner complained of is not
                                        authorized by the copyright owner, its agent or the law; and 6. A
                                        statement that the information in the notification is accurate and, under
                                        penalty of perjury, that the complaining party is authorized to act on
                                        behalf of the owner of an exclusive right that is allegedly infringed.
                                    </span>

                                    <br />
                                    <br />
                                    <span>
                                        <b>16. Miscellaneous.</b> This Agreement will inure to benefit and bind the
                                        parties hereto, and their successors and assigns, but neither party may
                                        assign this Agreement without written consent of the other, except to a
                                        related entity or the successor of all or substantially all of the
                                        assignor's business or assets to which this Agreement relates. This
                                        Agreement, including all documents incorporated herein, represents the
                                        entire agreement of the parties and supersedes all prior discussions
                                        and/or agreements between the parties and is intended to be the final
                                        expression of their agreement concerning the subject matter of this
                                        agreement. Except as expressly set forth herein, it will not be modified
                                        or amended except in writing signed by both parties. Any new terms or
                                        changes introduced in a purchase order or other document are void and of
                                        no force or effect. Stickball's acknowledgement of receipt of such
                                        document or acceptance of payment shall not constitute agreement to any
                                        terms other than those set forth in this Agreement. Stickball makes no
                                        claims that any of the Services are appropriate or may be used or
                                        downloaded outside of the United States. Access to the Services may not
                                        be legal by certain persons, or in certain countries. This Agreement will
                                        not be governed by the United Nations convention on contracts for the
                                        international sale of goods. Each party shall comply with all applicable
                                        export laws, restrictions, and regulations of any United States or
                                        foreign agency or authority and will not export or re-export, or allow
                                        the export or re-export of any product, technology or information it
                                        obtains or acquires in connection with the services (or any direct
                                        product thereof) in violation of any such laws, restrictions or
                                        regulations. If you access the services from outside of the United
                                        States, you do so at your own risk and are responsible for compliance
                                        with the laws of your jurisdiction. This agreement will be governed in
                                        accordance with the laws of the Commonwealth of Massachusetts and any
                                        controlling U.S. Federal law. Any disputes, actions, claims or causes of
                                        action arising out of or in connection with this Agreement (or the
                                        services) will be subject to the exclusive jurisdiction of the state and
                                        federal courts located in Massachusetts. If any provision is held by a
                                        court of competent jurisdiction to be contrary to law, such provision
                                        will be limited or eliminated to the minimum extent necessary so that
                                        this Agreement will otherwise remain in full force and effect. Stickball
                                        will not be liable for any loss or delay resulting from any force majeure
                                        event, including, but not limited to, acts of God, fire, natural
                                        disaster, terrorism, labor stoppage, war or military hostilities,
                                        criminal acts of third-parties, regional technology interruptions, or
                                        denial of service attacks.  By clicking “Accept” you acknowledge and
                                        agree that you accept this Agreement as creating a binding legal contract
                                        between you and Stickball.
                                    </span>
                                </p>

                            </div>
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="submit-btn"
                                onClick={
                                    () => {
                                        props.setTermCond(false)
                                        log({
                                            pageName: 'Terms and Conditions',
                                            buttonName: 'Agree & Continue'
                                        })
                                        navigate(props.navigateLinke)
                                    }}
                            >
                                Agree & Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </StyleRoot>
    )
}
