import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { cleverLogin, cleverRegister, getOrganizations } from '../_api';

import { useMutation, useQueryClient, useQuery } from 'react-query';
import Loading from '../common/Loading';
import { termText } from '../other/termsOfService';
import { CardContent, Grid, InputLabel, MenuItem, Select, TextField, FormControl } from '@mui/material';
import * as Yup from "yup";
import { useFormik } from 'formik';
import Header from '../common/NewHeader';
import { getAdmindzByOrg } from '../_api/journal';


export default function CleverAuth() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [notRegistered, setNotRegistered] = useState(false);
    const [data, setData] = useState({});
    const [myClass, setMyClass] = useState("")
    const [teacher, setTeacher] = useState("")
    const [organization, setOrganization] = useState("")
    const [organizations, setOrganizations] = useState([])
    const [allTeacher, setAllTeacher] = useState([])
    const { isLoading, data: orgs } = useQuery('get-organizations', getOrganizations)
    useEffect(() => {
        if (!isLoading) {
            const formatedData = orgs?.data?.filter(el => el.id !== "65fdbd97bdd44a00129afad7")
            const finlid = orgs?.data?.find(el => el.id == "65fdbd97bdd44a00129afad7")
            formik.setFieldValue("organization", finlid?.id || "")
            if(formatedData?.length && finlid) {
                formatedData.unshift(finlid)
            }
            setOrganizations(formatedData || [])
        }
    }, [isLoading, orgs])
    const { mutate: registerData } = useMutation(cleverRegister, {
        onSuccess: (response) => {
            if (response.token) {
                localStorage.setItem("token", response.token);
                queryClient.invalidateQueries('fetching-all-data');
                // setTimeout(() => {
                    navigate("/module")
                // }, 3000);
            }
            else {
                navigate("/login")
            }
        },
        onError: (error) => {
            navigate("/login");
        }
    });
    const { mutate: loginData } = useMutation(cleverLogin, {
        onSuccess: (response) => {
            if (response.token) {
                localStorage.setItem("token", response.token);
                queryClient.invalidateQueries('fetching-all-data');
                // setTimeout(() => {
                    navigate("/module")
                // }, 3000);
            }
            else if (response.success === true && response.message === "User Not Found !") {
                setNotRegistered(true);
            }
            else {
                navigate("/login")
            }
        },
        onError: (error) => {
            navigate("/login");
        }
    });
    function extractParamsFromURIFragment() {
        var fragmentParams = {};
        var e,
            a = /\+/g,  // Regex for replacing addition symbol with a space
            r = /([^&;=]+)=?([^&;]*)/g,
            d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
            q = window.location.hash.substring(1);

        while (e = r.exec(q)) {
            fragmentParams[d(e[1])] = d(e[2]);
        }
        return fragmentParams;
    }
    useEffect(() => {

        const getData = async () => {
            const options = {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                }
            };
            try {
                const infoResp = await axios.get("https://clever.com/oauth/tokeninfo", options);
                const userResp = await axios.get("https://api.clever.com/v3.0/me", options);
                const _userid = userResp.data.data.id;
                const userDataResp = await axios.get(`https://api.clever.com/v3.0/users/${_userid}`, options);
                var _cleverID = userResp.data.data.id;
                var _districtID = userResp.data.data.district;
                var _email = userDataResp.data.data.email;
                var _firstName = userDataResp.data.data.name.first;
                var _lastName = userDataResp.data.data.name.last;
                const data = {
                    cleverID: _cleverID,
                    districtID: _districtID,
                    email: _email,
                    firstName: _firstName,
                    lastName: _lastName,
                }
                setData(data);
                loginData(data);
            } catch (error) {
                navigate("/login");
            }

        }
        var params = extractParamsFromURIFragment();
        var bearer_token = params['access_token'];
        var state = params['state'];
        var scope = params['scope'];
        var NOUNCE = localStorage.getItem('NOUNCE');
        getData();

    }, [])

    const formik = useFormik({
        initialValues: {
            class: "",
            teacher: "finlit@stickball.biz",
            organization: ""
        },
        validationSchema: Yup.object({
            class: Yup.string().required("Please enter your class name"),
            teacher: Yup.string().required("Please enter your teacher name"),
            organization: Yup.string().required("Please select organization"),

        }),
        onSubmit: async (values) => {
            setOrganization(values.organization)
            setMyClass(values.class)
            setTeacher(values.teacher)
            setNotRegistered(false);
            registerData({
                cleverID: data?.cleverID,
                districtID: data?.districtID,
                email: data?.email,
                firstName: data?.firstName,
                lastName: data?.lastName,
                teacher: values.teacher,
                class: values.class,
                OrganizationId: values.organization
            });
        }

    });

    useEffect(() => {
        if(formik?.values?.organization) {
            handleGetTeachers()
        }
    }, [formik?.values?.organization])

    const handleGetTeachers = async () => {
        try {
            const teacherRes = await getAdmindzByOrg()
            setAllTeacher(teacherRes)
            if(formik?.values?.organization === "65fdbd97bdd44a00129afad7" ) {
                const slectedTeacher = teacherRes.find(teach => teach.email == "finlit@stickball.biz")
                formik.setFieldValue("teacher", slectedTeacher?.email || "")
            }

        } catch (error) {
        }
    }
    
    if(!notRegistered) return <Loading />

    return <div className="container">
               <Header back={false}/>
                {termText}

                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={4} xs={4}>
                            <TextField
                                error={Boolean(formik.touched.class && formik.errors.class)}
                                fullWidth
                                helperText={formik.touched.class && formik.errors.class}
                                label="Your Class"
                                type={"class"}
                                name="class"
                                onChange={formik.handleChange}
                                required
                                value={formik.values.class}
                            />
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <FormControl
                                fullWidth
                                error={Boolean(formik.touched.teacher && formik.errors.teacher)}
                                helperText={formik.touched.teacher && formik.errors.teacher}
                            >
                                <InputLabel id="demo-simple-select-label">
                                    Select Teacher
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    onChange={formik.handleChange}
                                    value={formik.values.teacher}
                                    name='teacher'
                                >
                                        {!!isLoading? <MenuItem>Loading...</MenuItem>
                                        : allTeacher?.map(teach => <MenuItem key={teach?.id} value={teach?.email}>{teach?.email}</MenuItem>)
}
                                </Select>

                            </FormControl>

                        </Grid>
                        <Grid item md={4} xs={4}>
                            <FormControl
                                fullWidth
                                error={Boolean(formik.touched.organization && formik.errors.organization)}
                                helperText={formik.touched.organization && formik.errors.organization}
                            >
                                <InputLabel id="demo-simple-select-label">
                                    Select Organization
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    onChange={formik.handleChange}
                                    value={formik.values.organization}
                                    name='organization'
                                >
                                        {!!isLoading? <MenuItem>Loading...</MenuItem>
                                        : organizations?.map(org => <MenuItem key={org?.id} value={org?.id}>{org?.name}</MenuItem>)
}
                                </Select>

                            </FormControl>

                        </Grid>
                        <Grid item md={12} xs={12} sx={{width:"100%"}}>
                            <button className="btn btn-submit bg-primary1" style={{width:"100%"}} onClick={formik.handleSubmit} >
                                Accept
                            </button>
                        </Grid>
                    </Grid>
                  
                </CardContent>
                <br/>
                <br/>
                <br/>
            </div> 
}
