import * as Axios from 'axios';
import { isStatic } from '../common/isStatic';

const API_URL = process.env.REACT_APP_API_LINK;

const axios = Axios.create({
    'headers': {
        'x-api-key': process.env.REACT_APP_X_API_KEY
    }
})

export const getAllSticky = async (data) => {
    let status;
    let approval;
    if (data === "assignment") {
        status = "";
        approval = "";
    }
    else if (data === "submitted") {
        status = "submitted";
        approval = "";
    }
    else if (data === "pending") {
        status = "";
        approval = "pending";
    }
    if (isStatic) {
        return true
    }
    const res = await axios.get(`${API_URL}/sticky-assignment?status=${status || ""}&approval=${approval || ""}&pagination=false`, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}

export const submitAssigment = async (data) => {
    if (isStatic) {
        return true
    }
    const res = await axios.put(`${API_URL}/sticky-assignment/${data.assignmentID}`,
        {
            answer: data?.answer,
            attachment: data?.attachment,
        },
        {
            'headers': {
                'Authorization': `Stickball ${localStorage.getItem('token')}`
            }
        })
    return (res.data)
}