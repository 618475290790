import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import { useLocation, useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'
import Header from '../common/NewHeader'
import { fullRegister, userLog, getOrganizations, googleSignup, login } from '../_api/index'
import { getAdmindzByOrg } from '../_api/journal'
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';

import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import { useTranslation } from 'react-i18next'
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { styled } from '@mui/material/styles';

import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import toast from 'react-hot-toast'
import { TextField, Grid, Typography, FormControl, Button, InputLabel, Container, Box, Modal } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { FormControlLabel } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { setLoading as setLoading4 } from '../../redux-features/user/userSlice';
import ApiSauce from '../../services/networkRequest'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { handleUser } from '../../redux-features/userDetails';
import { USER } from '../../config/WebServices';
import { useGoogleLogin } from '@react-oauth/google';

const GoogleLoginButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { mutate: log } = useMutation(userLog);

    const dispatch = useDispatch()

    const register = useSelector(state => state.user.register)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const link = queryParams.get('returnURL');
    const anchor = window.location.hash.substring(1);

    const queryClient = useQueryClient();
    const [serverError, setServerError] = useState(false)
    const [profileImage, setProfileImage] = useState("")
    const [email, setEmail] = useState("")
    const [dob, setDob] = useState()
    const [zipCode, setZipCode] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState("")
    const [error, setError] = useState(false)
    const [showPass1, setShowPass1] = useState(false)
    const [showPass2, setShowPass2] = useState(false)
    const [loading, setLoading] = useState(false)
    const [organization, setOrganization] = useState("")
    const [organizations, setOrganizations] = useState([])
    const [dobLabel, setDobLabel] = useState()
    const [dateValue, setDateValue] = useState(null)
    const navigateLink = "/email-verification/" + email;
    const [value, setValue] = React.useState(null);
    const [file, setFileName] = useState()
    const [error2, setError2] = useState()
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [confirmPass, setConfirmPass] = useState()
    const [teacher, setTeacher] = useState("")
    const [other, setOther] = useState("")
    const [modalOpen, setModalOpen] = useState(false)
    const [payload, setPayload] = useState()
    const [isMobile, setIsMobile] = useState(false);


    const { isLoading: reqLoading2, isSuccess: isSuccess2, mutate: GoogleSignup } = useMutation(googleSignup, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('request-code');
            loginResponseHandling(response, null)
            setMessage("Registered Successfully !")
        },
        onError: (error) => {
            if (error.message === "Request failed with status code 409") {
                setMessage("User already registered, try with different Email")
            } else {
                setMessage("Internal error, sorry for inconvenience, please try again!")
            }
        }
    });

    const _g_login = useGoogleLogin({
        onSuccess: async tokenResponse => {
            loginData({ tokenResponse: tokenResponse.access_token, loginType: "google" })
        },
        flow: 'implicit', // implicit is the default
    });
    const { isLoading: isLoading2, isSuccess: isSuccess, mutate: loginData } = useMutation(login, {
        onSuccess: (response) => {
            loginResponseHandling(response)
        },
        onError: (error) => {
            if (error?.response?.data?.payload) {
                let _payload = error?.response?.data?.payload
                setPayload({
                    firstName: _payload.given_name,
                    lastName: _payload.family_name,
                    email: _payload.email,
                    picture: _payload.picture
                })
            }
            loginResponseHandling(null, error)
        }
    });
    const handleGoogleSignup = () => {
        const data = {
            ...payload,
            OrganizationId: organization,
            teacher: teacher,
        }
        GoogleSignup(data)

    }
    const loginResponseHandling = (response, error) => {
        if (error) {
            const { message } = error
            if (message === "Request failed with status code 404") {
                setModalOpen(true)
                return setMessage("Sorry, User does not exist")
            }
            return setMessage(`Internal Error, Sorry for inconvenience, Please try again!`)
        }
        const { token, message } = response
        if (token) {
            setMessage(`User logged in successfully`)
            localStorage.setItem("token", token);
            getUserDetails(token)
            dispatch(setLoading4(true))
            queryClient.invalidateQueries('fetching-all-data');

            if (register) return navigate("/video")
            if (link) return navigate(link + "#" + anchor)
            navigate('/module')
            return

        }
        if (message === "No such User !") {
            return setMessage(`Sorry, this email is not registered for login, Please Register and try again`)
        }
        if (response.message === "Invalid Credentials") return setMessage(`Given password is not valid, Please try again or reset your password`)
        if (response.message === "Password not found") return setMessage(`Password not found. Please login using Google.`)

    }

    const getUserDetails = async (token) => {
        try {
            const { user } = await ApiSauce.getWithToken({ url: USER, token })
            user.token = token
            dispatch(handleUser(user))
        } catch (error) {
            console.log("🚀 ~ file: Login.js:98 ~ getUserDetails ~ error:", error)
        }
    }


    let isNorthwell = process.env.REACT_APP_API_LINK.includes("northwell")

    const handleClickShowPassword = () => setShowPass1((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleClickShowPassword2 = () => setShowPass2((show) => !show);

    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };
    const { isLoading, data: orgs } = useQuery('get-organizations', getOrganizations)
    useEffect(() => {
        if (!isLoading) {
            setOrganizations(orgs?.data || [])
            setOrganization(orgs?.data[0]?.id || "")
        }
    }, [isLoading, orgs])

    const { isLoading: adminsLoading, data: adminsList } = useQuery('admins-by-org', () => getAdmindzByOrg(), {
        enabled: Boolean(organization)
    })

    const { isLoading: reqLoading, isSuccess: isSuccess3, mutate: fullReg } = useMutation(fullRegister, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('request-code');
            setMessage("Registered Successfully !")
            setServerError(false)
            setTimeout(() => {
                navigate(navigateLink)
            }, 1000);
        },
        onError: (error) => {
            if (error.message === "Request failed with status code 409") {
                setMessage("User already registered, try with different Email")
                setServerError(true)
            } else {
                setMessage("Internal error, sorry for inconvenience, please try again!")
                setServerError(true)
            }
        }
    });


    return <>

        <Grid item xs={12} md={10} mt={3} width={{xs:'100%',md:"70%"}} style={{ marginBottom: 15, marginLeft: 2 }}>
            <Button onClick={() => _g_login()} variant="outlined" className="google_button google_button"
                style={styles.googleBtn}>
                <img src='../assets/img/google-icon.png' style={{ width: 35 }} />Continue With Google</Button>
        </Grid>

        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(prev => !prev)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} >
                {/* <p onClick={() => setModalOpen(false)} style={styles.CloseIcon}>❌</p> */}
                <Typography id="modal-modal-title" variant="h6" component="h3">
                    Please provide following information to proceed
                </Typography>

                <Grid container>
                    <Grid item xs={12} my={1}>
                        {/* <FormControl sx={{ width: "70%" }}> */}
                        <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>
                            Organization <span style={{ color: 'red' }}>*</span>
                        </Typography>

                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={organization}
                            label={"Text"}
                            className="reqcode__role"
                            sx={{ '& legend': { display: 'none' }, width: '100%', '& fieldset': { top: 0 } }}
                            size="small"
                            onChange={(e) => setOrganization(e.target.value)}
                            defaultValue="Student"

                        >
                            {
                                isLoading ?
                                    <MenuItem >Loading...</MenuItem>
                                    :
                                    organizations?.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((org) => <MenuItem key={org?.id} value={org?.id}>{org?.name}</MenuItem>)
                            }
                        </Select>
                        {/* </FormControl> */}
                    </Grid>
                    <Grid item xs={12} my={1}>
                        <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>
                            Teacher <span style={{ color: 'red' }}>*</span>
                        </Typography>

                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={!!other ? other : teacher}
                            label={"Teacher"}
                            className="reqcode__role"
                            sx={{ '& legend': { display: 'none' }, width: '100%', '& fieldset': { top: 0 } }}
                            size="small"
                            onChange={(e) => {
                                if (e.target.value !== 'Other') {
                                    setTeacher(e.target.value)
                                    setOther("")
                                } else {
                                    setOther(e.target.value)
                                    setTeacher("")
                                }
                            }}
                            defaultValue="Select Teacher"
                            disabled={Boolean(!organization)}
                        >
                            <MenuItem disabled value="xyz" >Select Teacher</MenuItem>
                            {
                                adminsLoading ?
                                    <MenuItem disabled value="xyz" >Loading...</MenuItem>
                                    :
                                    adminsList?.map((admin) => <MenuItem key={admin?.username} value={admin?.username}>{admin?.username}</MenuItem>)

                            }

                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </Grid>
                    {
                        other === "Other" &&
                        <Grid item xs={12} my={1}>
                            <TextField size="small" placeholder={`Enter ${isNorthwell ? "Teacher / Mentor Name" : "Teacher Name"}`} type="text" style={{ width: "100%" }} id="outlined-basic"
                                onChange={(e) => setTeacher(e.target.value)} value={teacher} variant="outlined"
                                sx={{ fontFamily: "Poppins", '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} my={1}>
                        <Button variant="contained" disabled={!organization || !teacher} sx={{ width: "100%", background: "#616DED" }}
                            onClick={handleGoogleSignup}>{
                                reqLoading ?
                                    <CircularProgress style={{ color: 'white' }} />
                                    : "Register"
                            }</Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    </>
}


export default GoogleLoginButton;



const inputStyle = {
    lineHeight: '40px',
    border: 'none',
    background: 'transparent'
}

// const styles = {
//     root: {
//         "flex-direction": "row-reverse"
//     }
// };
const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    // boxShadow: 24,
    p: 4,
    borderRadius: 3,
};
const styles = {
    googleBtn: {
        textTransform: "none",
        borderRadius: "14px",
        color: "rgb(113 114 115)",
        fontWeight: "normal",
        border: "1px solid lightgrey",
        letterSpacing: "normal",
        gap: 10,
        display: "flex",
        width: "100%",
        alingSelf: "center"
    },
    CloseIcon: {
        textAlign: "flex-end",
        position: "absolute",
        right: 5,
        top: 5,
        padding: 3,
        background: "white",
        borderRadius: "50%",
        border: "1px solid black",
        cursor: "pointer"
    }
}