import { Button, Grid, TextField, Typography, useTheme } from "@mui/material";
import {
  ADMIN_LINK,
  checkQuilValidation,
  DATA_TYPE,
  isAdminAdd,
  isAdminEdit,
  renderPlaceholder,
} from "../../../common/contentType/contentTypeData";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Adminwrapper } from "../../../common";
import CollapsibleTable from "../collapse";
import { useStyles } from "../../../../utils/styles";
import { AdminQuillEditor } from "../../details/quil-editor";
import CustomCardWrapper from "../../../common/contentType/CustomCardWrapper";
import TypographyWithMore from "../../../common/contentType/TypographyWithMore";

const RenderDropdown = ({ content }) => {
  const classes = useStyles();

  const { name, description, type } = content?.allContent;
  const [isAdd, setIsAdd] = useState(false);
  const [dropdownName, setDropdownName] = useState("");
  const [dropdownContent, setDropdownContent] = useState("");
  const theme = useTheme();
  const [data, setStateData] = useState({
    name,
    description,
    type,
    dropdowns: JSON.parse(content?.allContent?.content),
  });
  const { QUILL } = DATA_TYPE;

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      dropdowns: data?.dropdowns,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      dropdowns: Yup.array().min(1, "Dropdown must be at least 1"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <CustomCardWrapper overflowX="hidden">
      {isAdminEdit() && (
        <button
          className={classes.btnUpdateStyle}
          onClick={contentFormik.handleSubmit}
        >
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button
          className={classes.btnAddStyle}
          onClick={contentFormik.handleSubmit}
        >
          ADD
        </button>
      )}
      <Grid container spacing={5} px={{ xs: "0rem", md: "3rem" }}>
        <Grid item xs={12} md={7}>
          <Adminwrapper
            errorText={contentFormik.errors.name}
            dataType={QUILL}
            data={data}
            type="name"
            setData={setData}
          >
            {renderPlaceholder(data?.name, "Enter Name")}
            <TypographyWithMore
              variant="h2"
              color="primary"
              data={data?.name}
              textLength={50}
              lines={2}
            />
          </Adminwrapper>
          <Adminwrapper
            errorText={contentFormik.errors.description}
            dataType={QUILL}
            data={data}
            type="description"
            setData={setData}
          >
            {renderPlaceholder(data?.description, "Enter Description")}
            <TypographyWithMore
              variant="body1"
              color="text"
              data={data?.description}
              textLength={130}
              lines={10}
            />
          </Adminwrapper>
        </Grid>
        <Grid item xs={12} md={5} display="flex" alignItems="center">
          <CollapsibleTable
            Adminwrapper={Adminwrapper}
            DATA_TYPE={DATA_TYPE}
            data={data}
            setData={setData}
          />
        </Grid>
      </Grid>

      {(isAdminAdd() || isAdminEdit()) && (
        <>
          {!isAdd && (
            <Button
              sx={{ m: 1 }}
              variant="contained"
              onClick={() => setIsAdd(true)}
            >
              Add DropDown
            </Button>
          )}
          {isAdd && (
            <div className={classes.addDropdown}>
              <TextField
                placeholder="Name"
                variant="outlined"
                label="Name"
                style={{ width: "400px" }}
                onChange={(e) => setDropdownName(e.target.value)}
              />
              <AdminQuillEditor
                placeholder="Dropdown description"
                className={classes.adminQuillStyle}
                onChange={(e) => setDropdownContent(e)}
              />
              <Button
                sx={{ m: 1 }}
                variant="contained"
                onClick={() => {
                  let tempDropdowns = data?.dropdowns || [];
                  tempDropdowns?.push({
                    title: dropdownName,
                    detail: dropdownContent,
                  });
                  setIsAdd(false);
                  let tempData = data;
                  tempData.dropdowns = tempDropdowns;
                  setData(tempData);
                }}
              >
                Save
              </Button>
            </div>
          )}
        </>
      )}

      {(isAdminAdd() || isAdminEdit()) && !!contentFormik.errors.dropdowns && (
        <Typography className={classes.errorStyle}>
          {contentFormik.errors.dropdowns}
        </Typography>
      )}
    </CustomCardWrapper>
  );
};

export default RenderDropdown;
