import React from 'react'
import { useTranslation } from 'react-i18next'


export default function Loading() {

    const { t } = useTranslation();
    return (
        <>
            <div className="full-container w-100 h-100" style={{position: 'relative'}}>
                
                <div className="contain mt-0">
                    <img src="/assets/img/loader.png"  className="load" />
                    <div class="bubbles">
                    <div class="first"></div>
                    <div class="second"></div>
                    <div class="third"></div>
                    <div class="fourth"></div>
                    <div class="fifth"></div>
                </div>
                </div>  
                <br />
                <h4 className="text-center text loadingtext">Submitting sticky....</h4>
            </div>
        </>
    )
}
