import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from 'react-query';
import SkillSection from '../details/SkillSection'
import { userLog } from '../../_api/index'
import { setSession, userEnroll } from '../../../redux-features/user/userSlice'
import Box from '@mui/material/Box';
import SkillSectionLayout from '../../../layouts/SkillSectionLayout'
import { useStyles } from '../../../utils/styles'
import { isStatic } from '../../common/isStatic';


export default function SkillSims() {
    const dispatch = useDispatch()
    const { mutate: log } = useMutation(userLog);
    const navigate = useNavigate();
    const classes = useStyles()

    const skillSections = useSelector(state => state.user.skillSections)
    const completedSubSkills = useSelector((state) => state.user.completedSubSkills)
    const subSkillId = useSelector((state) => state.user.subSkillId)
    const waitTime = useSelector(state => state.user.userData?.timer) || 0

    const [ind, setInd] = useState(0)
    const [simsContent, setSimsContent] = useState([])
    const [introContent, setIntroContent] = useState([]);
    const [assessContent, setAssessContent] = useState([]);
    const [nextTimer, setNextTimer] = useState(waitTime || 0)

    const allEnrolled = isStatic ? completedSubSkills.includes(subSkillId) : simsContent.filter(content => { return (content.isCompleted) }).length == simsContent.length

    useEffect(() => {
        const timer = setInterval(() => {
            setNextTimer((prevProgress) => (prevProgress > 0 ? prevProgress - 1 : 0));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        let temp = skillSections?.filter(ss => { return (ss?.name === "SIMS") })
        let temp2 = skillSections?.filter(ss => { return (ss?.name === "Intro") })
        let temp3 = skillSections?.filter(ss => { return (ss?.name === "Assess") })
        setSimsContent(temp)
        setIntroContent(temp2)
        setAssessContent(temp3)
        if (temp.length === 0) {
            navigate("/skill-detail-quiz")
        }
    }, [skillSections])

    const handleEnrollment = (data, index) => {
        if (simsContent.length - 1 == index) {
            dispatch(setSession(data))
            navigate("/skill-detail-quiz")
        } else {
            dispatch(userEnroll(data))
            setInd(ind + 1)
            setNextTimer(waitTime)
        }
    }


    const handleNextClick = () => {
        log({
            pageName: "Skill Details Sims",
            buttonName: "Next",
        });
        if (allEnrolled) {
            if (simsContent[ind + 1])
                setInd(ind + 1)
            else {
                dispatch(setSession(simsContent[ind].id))
                navigate("/skill-detail-quiz")
            }
        } else {
            handleEnrollment(simsContent[ind]?.id, ind);
        }
    }
    const handlePrevClick = () => {
        log({
            pageName: 'Skill Details Sims',
            buttonName: 'Prev'
        })
        if (ind <= 0) {
            if (!assessContent.length) {
                if (!introContent.length) {
                    window.history.go(-3)
                } else {
                    window.history.go(-2)
                }
            } else {
                window.history.go(-1)
            }
        } else {
            setInd(ind - 1)
        }
    }

    return (
        <SkillSectionLayout
            nextTimer={nextTimer}
            page="Skill Details Sims"
            handleNextClick={handleNextClick}
            handlePrevClick={handlePrevClick}
            isCompleted={allEnrolled}
        >
            <Box className={classes.skillSectionContainer}>
                <SkillSection content={simsContent[ind]} />
            </Box>
        </SkillSectionLayout >
    )
}
