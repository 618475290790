import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { componentsDefaults } from './utils/components-defaults';

const commonConfig = {
    typography: {
        fontFamily: ["Poppins"].join(","),
        h1: {
            fontSize: "34px",
            fontWeight: 700,
            "@media (max-width:992px)": {
                fontSize: "28px", // Smaller font size for small screens (<=600px)
            },
            "@media (max-width:600px)": {
                fontSize: "24px", // Smaller font size for small screens (<=600px)
            },
        },
        h2: {
            fontSize: "32px",
            fontWeight: 700,
            "@media (max-width:992px)": {
                fontSize: "26px", // Smaller font size for small screens (<=600px)
            },
            "@media (max-width:600px)": {
                fontSize: "22px", // Smaller font size for small screens (<=600px)
            },
        },
        h3: {
            fontSize: "24px",
            fontWeight: 600,
        },
        h4: {
            fontSize: "20px",
            fontWeight: 600,
        },
        h5: {
            fontSize: "18px",
            fontWeight: 500,
        },
        h6: {
            fontSize: "16px",
            fontWeight: 500,
        },
        body1: {
            fontSize: "14px",
            fontWeight: 400,
        },
        body2: {
            fontSize: "12px",
            fontWeight: 400,
        },
    },
    components: componentsDefaults,
};

const ThemeProviderCustom = ({ children }) => {
    const config = useSelector(state => state.user.theme)
    let theme = createTheme({
        palette: config?.palette || {
            "primary": {
                "main": "#013ca5",
                "light": "#cdd8ed",
                "extraLight": "#DBE9F3",
                "contrastText": "#fff"
            },
            "secondary": {
                "main": "#FFBA00",
                "light": "#fae9be",
                "contrastText": "#fff"
            },
            "light": {
                "main": "#fff",
                "light": "#fafbff"
            },
            "success": {
                "main": "#2e7d32",
                "light": "#96f299",
                "contrastText": "#fff"
            },
            "common": {
                "black": "#000000",
                "white": "#ffffff",
                "disabled": "#CCCCCC",
                "text": "#4E4E4E",
                "lightGray": "#d3d3d3",
                "dialougeBg": "#FAFAFA"
            }
        }, ...commonConfig
    })

    return (
        <ThemeProvider theme={theme} >
            {children}
        </ThemeProvider>
    )
}

export default ThemeProviderCustom;