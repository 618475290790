import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Feedback from '../details/Feedback'
import { useSelector, useDispatch } from 'react-redux'
import { userLog } from '../../_api/index'
import { setQuizPoints } from '../../../redux-features/user/userSlice'
import { useMutation } from 'react-query';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ProgressBar } from 'react-bootstrap';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import SkillSectionLayout from '../../../layouts/SkillSectionLayout'
import { useStyles } from '../../../utils/styles'
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles'
import { isStatic } from '../../common/isStatic'

export default function SkillQuiz() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const classes = useStyles()
    const theme = useTheme()
    const [isComplete, setIsComplete] = useState(false)
    const [content, setContent] = useState()
    const [points, setPoints] = useState(0)
    const [answers, setAnswers] = useState([])
    const [submit, setSubmit] = useState([])
    const [ind, setInd] = useState(0)
    const [skillComplete, setSkillComplete] = useState();
    const quizes = useSelector(state => state?.user?.quiz)
    const skillSections = useSelector(state => state?.user?.skillSections)
    const completedModules = useSelector(state => state?.user.completedModules)
    const completedSubSkills = useSelector(state => state?.user.completedSubSkills)
    const moduleId = useSelector(state => state.user?.moduleId)
    const cModule = useSelector(state => state.user?.module)
    const subSkillId = useSelector(state => state.user?.subSkillId)

    const isModuleCompleted = isStatic ? completedModules?.includes(cModule?.id) : cModule?.isCompleted


    const { mutate: log } = useMutation(userLog);
    useEffect(() => {
        setContent(quizes)
        if (skillSections) {
            let allSkillSectionsCompleted = isStatic ? completedSubSkills?.includes(subSkillId) : !skillSections.filter(ss => (!ss.isCompleted))?.length
            if (allSkillSectionsCompleted) {
                setSkillComplete(true)
                quizes?.map(que => {
                    setSubmit(old => [...old, true])
                    setAnswers(old => [...old, que.trueOption])
                })
                if (!quizes?.length) {
                    if (isModuleCompleted) {
                        navigate('/module')
                    } else {
                        navigate("/lesson?id=" + moduleId)
                    }
                }
            } else {
                setSkillComplete(false)
                if (!quizes?.length || !quizes) {
                    setIsComplete(true)
                }
            }
        }


    }, [skillSections, quizes])


    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(setQuizPoints(points))
        let temp = submit
        temp[ind] = true
        setSubmit(temp)
        setPoints(0)
    };
    const retryQuiz = () => {
        dispatch(setQuizPoints(0))
        setSubmit([])
        setAnswers([])
        setIsComplete(false)
        setInd(0)
        setPoints(0)
    }


    const handleNextClick = (e) => {
        log({
            pageName: 'Quiz',
            buttonName: 'Next'
        })

        if (submit[ind]) {
            if (content[ind].trueOption != answers[ind]) {
                return
            }
            else {
                content[ind + 1] ? setInd(ind + 1) : <>{skillComplete ? navigate("/lesson?id=" + moduleId) : setIsComplete(true)}</>
            }
        } else {

            handleSubmit(e)
        }
    }
    const handlePrevClick = () => {
        log({
            pageName: 'Quiz',
            buttonName: 'Prev'
        })
        let temp = skillSections?.filter(ss => {
            return (
                ss?.name === "SIMS")
        })
        if (ind === 0 && !temp.length) {
            window.history.go(-2)
            return
        }
        ind <= 0 ? window.history.go(-1) : setInd(ind - 1)

    }
    if (isComplete)
        return <Feedback retry={retryQuiz} nextLink="/quiz-result" />
    return (
        <SkillSectionLayout
            page="Quiz"
            handleNextClick={handleNextClick}
            handlePrevClick={handlePrevClick}
        >
            <Box className={classes.skillSectionContainer}>
                {
                    content ?
                        <div className="row" style={{ height: '82vh', justifyContent: 'center', alignItems: 'center' }}>
                            <form
                                style={{
                                    width: 'fit-content',
                                    minWidth: '50%',
                                    maxWidth: '65%',
                                    padding: '10px 20px',
                                    borderRadius: '16px',
                                    border: '1px solid grey',
                                    fontSize: '16px',
                                    textAlign: 'left',
                                }}
                            >
                                <ProgressBar style={{
                                    height: '20px',
                                    marginTop: '12px',
                                    padding: '0px'
                                }}
                                    className="my-progrss"
                                    now={(ind + 1) / content?.length * 100}
                                    label={`${((ind + 1) / content?.length * 100).toFixed(0)}%`}
                                />
                                <FormControl sx={{ m: 3 }} variant="standard">
                                    <FormLabel id="demo-error-radios">
                                        <p className="text-primary1" style={{ fontSize: '16px', fontWeight: '600' }}>
                                            Q{ind + 1}.  {content[ind]?.question}
                                        </p>
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-error-radios"
                                        name={content[ind]?.id}
                                        value={answers[ind] || null}
                                        onChange={(event) => {
                                            let temp = answers;
                                            temp[ind] = event.target.value;
                                            setAnswers(temp)
                                            content[ind]?.trueOption.toString() === event.target.value.toString()
                                                ? setPoints(content[ind]?.points)
                                                : setPoints(0)

                                        }}
                                    >
                                        <FormControlLabel value={1} control={<Radio
                                            sx={{
                                                color: theme.palette.primary.main,
                                                '&.Mui-checked': {
                                                    color: content[ind]?.trueOption === 1 && submit[ind]
                                                        ? theme.palette.success.main
                                                        : theme.palette.primary.main,
                                                },
                                            }}

                                            disabled={submit[ind] && content[ind]?.trueOption !== 1 && answers[ind] == "1"}
                                        />} label={<>
                                            {content[ind]?.answer01}
                                            {
                                                content[ind]?.trueOption === 1 && submit[ind] && answers[ind] == "1" &&
                                                <DoneIcon style={{ fontSize: '25px', color: theme.palette.success.main }} />
                                            }
                                            {
                                                submit[ind] && answers[ind] === "1" && content[ind]?.trueOption !== 1 &&
                                                <CloseIcon style={{ fontSize: '25px', color: "red" }} />
                                            }
                                        </>} />
                                        <FormControlLabel value={2} control={<Radio
                                            sx={{
                                                color: theme.palette.primary.main,
                                                '&.Mui-checked': {
                                                    color: content[ind]?.trueOption === 2 && submit[ind]
                                                        ? theme.palette.success.main : theme.palette.primary.main,
                                                },
                                            }}
                                            disabled={submit[ind] && content[ind]?.trueOption !== 2 && answers[ind] == "2"}
                                        />} label={
                                            <>
                                                {content[ind]?.answer02}
                                                {
                                                    content[ind]?.trueOption === 2 && submit[ind] && answers[ind] == "2" &&
                                                    <DoneIcon style={{ fontSize: '25px', color: theme.palette.success.main }} />
                                                }
                                                {
                                                    submit[ind] && answers[ind] === "2" && content[ind]?.trueOption !== 2 &&
                                                    <CloseIcon style={{ fontSize: '25px', color: "red" }} />
                                                }
                                            </>
                                        } />
                                        {
                                            (content[ind]?.answer03 !== "null" && content[ind]?.answer03.trim() !== "") &&
                                            <FormControlLabel value={3} control={<Radio
                                                sx={{
                                                    color: theme.palette.primary.main,
                                                    '&.Mui-checked': {
                                                        color: content[ind]?.trueOption === 3 && submit[ind]
                                                            ? theme.palette.success.main : theme.palette.primary.main,
                                                    },
                                                }}
                                                disabled={submit[ind] && content[ind]?.trueOption !== 3 && answers[ind] == "3"}
                                            />} label={
                                                <>
                                                    {content[ind]?.answer03}
                                                    {
                                                        content[ind]?.trueOption === 3 && submit[ind] && answers[ind] == "3" &&
                                                        <DoneIcon style={{ fontSize: '25px', color: theme.palette.success.main }} />
                                                    }
                                                    {
                                                        submit[ind] && answers[ind] === "3" && content[ind]?.trueOption !== 3 &&
                                                        <CloseIcon style={{ fontSize: '25px', color: "red" }} />
                                                    }
                                                </>
                                            } />
                                        }
                                        {
                                            (content[ind]?.answer04 !== "null" && content[ind]?.answer04.trim() !== "") &&
                                            <FormControlLabel value={4} control={<Radio
                                                sx={{
                                                    color: theme.palette.primary.main,
                                                    '&.Mui-checked': {
                                                        color: content[ind]?.trueOption === 4
                                                            ? theme.palette.success.main : theme.palette.primary.main,
                                                    },
                                                }}
                                                disabled={submit[ind] && content[ind]?.trueOption !== 4 && answers[ind] == "4"}
                                            />} label={
                                                <>
                                                    {content[ind]?.answer04}
                                                    {
                                                        content[ind]?.trueOption === 4 && submit[ind] && answers[ind] == "4" &&
                                                        <DoneIcon style={{ fontSize: '25px', color: theme.palette.success.main }} />
                                                    }
                                                    {
                                                        submit[ind] && answers[ind] === "4" && content[ind]?.trueOption !== 4 &&
                                                        <CloseIcon style={{ fontSize: '25px', color: "red" }} />
                                                    }
                                                </>
                                            } />
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </form>
                        </div>
                        : <></>
                }
            </Box>
        </SkillSectionLayout >
    )
}