import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DraggableList from "./DraggableList";
import { Grid } from "@mui/material";

export default function MultipleSelect({
  options,
  formik,
  name,
  handleChange,
  label,
}) {
  const selectedStyles = formik?.values?.learningStyles || [];

  const initialSelectedValues = () => {
    return selectedStyles
      ?.map((selectedStyle) =>
        options.find((option) => option.value === selectedStyle)
      )
      .filter((selectedStyle) => selectedStyle !== undefined);
  };

  const [values, setValues] = useState(initialSelectedValues());
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const updatedValues = initialSelectedValues();
    setValues(updatedValues);
  }, [selectedStyles, options]);

  return (
    <Grid container>
      <Grid item xs={12} lg={12}>
        <Autocomplete
          multiple
          id="demo-multiple-name"
          name={name}
          value={values}
          options={options}
          disableCloseOnSelect
          onChange={(_, newValue) => {
            setValues(newValue);
            handleChange(newValue);
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.value === value.value}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props}>
                <Checkbox
                  fontSize="small"
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            );
          }}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder="Select"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid #616ded",
                  },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        {values.length > 1 && (
          <DraggableList data={values} handleChange={handleChange} />
        )}
      </Grid>
    </Grid>
  );
}
