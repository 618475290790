import React, { useState } from 'react';
import {
    Grid,
    Button,
    Slide,
    Card,
    CardHeader,
    CardContent,
    Divider,
    TextField,
    Typography,
    Box,
    IconButton
} from '@mui/material';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { postTaskLog } from '../../../_api/time-management'
import { toast } from 'react-hot-toast';
import moment from 'moment'
import { useQueryClient } from 'react-query';

export default function TaskLog({ setPage, setPageTitle, page }) {
    const queryClient = useQueryClient();
    const unixCalculator = (date, time) => {
        var currentDate = new Date(date);
        var [hours, minutes] = time.split(":");
        return (
            currentDate.getTime() +
            parseInt(hours) * 3600000 +
            parseInt(minutes) * 60000
        ).toString();
    };
    const formik = useFormik({
        initialValues: {
            projectName: 'project 1',
            taskAssignedTo: 'employee 1',
            taskName: '',
            taskDescription: '',
            startDate: '',
            endDate: '',
            priority: null,
        },
        validationSchema: Yup.object({
            projectName: Yup.string().required("Please select Project Name"),
            priority: Yup.number().typeError("Please select Periority").required("Please select Periority").min(1),
            startDate: Yup.string().required("Please select Start Date of Task"),
            endDate: Yup.string().required("Please select End Date of Task, End Date must exist after start date"),
            taskAssignedTo: Yup.string().required("Please select who is responsible for this Task"),
            taskName: Yup.string().trim().required("Please enter Task Name").min(3, "Task Name must be at least 3 characters").max(120, 'Task Name must be at most 120 characters'),
            taskDescription: Yup.string().trim().required("Please enter Task Description").min(5, "Task Description must be at least 5 characters").max(1200, 'Task Description must be at most 1200 characters'),
        }),
        onSubmit: async (values) => {

            if (values.startDate > values.endDate) {
                formik.setFieldValue('endDate', "")
                formik.setFieldError('endDate', "Please select date after start date")
                return
            }

            toast.loading("Saving Task Please Wait...")
            try {
                await postTaskLog({
                    ...values,
                    endDate: moment(values.endDate).toISOString(),
                    startDate: moment(values.startDate).toISOString(),
                    priority: values.priority === 1 ? 'low' : values.priority === 2 ? 'medium' : 'high',
                })
                queryClient.invalidateQueries('get-log-list')
                toast.dismiss()
                toast.success("Task Saved!")
                setPage('main')
            } catch (err) {
                toast.dismiss()
                toast.error(err.message)
            }


        }
    })
    const fieldProps = {
        onChange: formik.handleChange,
        onBlur: formik.handleBlur,
        fullWidth: true,
    }
    const priority = formik.values.priority
    return (
        <Slide direction="left" in={page === "taskLog"} mountOnEnter unmountOnExit>
            <Grid sx={mainPageStyle}>
                <Card sx={cardStyle}>
                    <CardHeader sx={{ textAlign: 'center' }} title="Task Log Form" />
                    <Divider />
                    <CardContent>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={6} sx={{ p: 3 }}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="projectName"
                                        error={Boolean(formik.touched.projectName && formik.errors.projectName)}
                                        helperText={formik.touched.projectName && formik.errors.projectName}
                                        value={formik.values.projectName}
                                        SelectProps={{ native: true }}
                                        select
                                        {...fieldProps}
                                        label="Project Name"
                                    >
                                        <option value="project 1">Project 1</option>
                                        <option value="project 2">Project 2</option>
                                        <option value="project 3">Project 3</option>
                                        <option value="project 4">Project 4</option>
                                        <option value="project 5">Project 5</option>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="taskName"
                                        error={Boolean(formik.touched.taskName && formik.errors.taskName)}
                                        helperText={formik.touched.taskName && formik.errors.taskName}
                                        value={formik.values.taskName}
                                        {...fieldProps} label="Task" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="taskDescription"
                                        error={Boolean(formik.touched.taskDescription && formik.errors.taskDescription)}
                                        helperText={formik.touched.taskDescription && formik.errors.taskDescription}
                                        value={formik.values.taskDescription}
                                        multiline
                                        rows={6}
                                        {...fieldProps}
                                        label="Task Description" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="taskAssignedTo"
                                        error={Boolean(formik.touched.taskAssignedTo && formik.errors.taskAssignedTo)}
                                        helperText={formik.touched.taskAssignedTo && formik.errors.taskAssignedTo}
                                        value={formik.values.taskAssignedTo}
                                        SelectProps={{ native: true }}
                                        select
                                        {...fieldProps}
                                        label="Task Responsible" >
                                        <option value="employee 1">Employee 1</option>
                                        <option value="employee 2">Employee 2</option>
                                        <option value="employee 3">Employee 3</option>
                                        <option value="employee 4">Employee 4</option>
                                        <option value="employee 5">Employee 5</option>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="startDate"
                                        error={Boolean(formik.touched.startDate && formik.errors.startDate)}
                                        helperText={formik.touched.startDate && formik.errors.startDate}
                                        value={formik.values.startDate}
                                        {...fieldProps}
                                        label="Start Date"
                                        type="date"
                                        InputLabelProps={{ shrink: true }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        {...fieldProps}
                                        onChange={(e) => {
                                            if (formik.values.startDate < e.target.value) {
                                                formik.handleChange(e)
                                            } else {
                                                formik.setFieldValue('endDate', "")
                                                formik.setFieldError('endDate', "Please select date after start date")
                                            }
                                        }}
                                        name="endDate"
                                        error={Boolean(formik.touched.endDate && formik.errors.endDate)}
                                        helperText={formik.touched.endDate && formik.errors.endDate}
                                        value={formik.values.endDate}
                                        label="Expected End Date"
                                        type="date"
                                        InputLabelProps={{ shrink: true }} />
                                </Grid>

                                <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="subtitle2">Priority</Typography>
                                </Grid>
                                <Grid item xs={8} sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                                    <Typography variant="subtitle">Low</Typography>
                                    <IconButton onClick={() => formik.setFieldValue("priority", 1)}>
                                        <Box
                                            sx={{
                                                ...periorityStyle,
                                                background: priority === 1 ? '#616ded' : 'transparent',
                                                color: priority === 1 ? '#fff' : '#ccc',
                                                borderColor: priority === 1 && '#616ded'
                                            }}
                                        >1</Box>
                                    </IconButton>
                                    <IconButton onClick={() => formik.setFieldValue("priority", 2)}>
                                        <Box
                                            sx={{
                                                ...periorityStyle,
                                                background: priority === 2 ? '#616ded' : 'transparent',
                                                color: priority === 2 ? '#fff' : '#ccc',
                                                borderColor: priority === 2 && '#616ded'
                                            }}
                                        >2</Box>
                                    </IconButton>
                                    <IconButton onClick={() => formik.setFieldValue("priority", 3)}>
                                        <Box
                                            sx={{
                                                ...periorityStyle,
                                                background: priority === 3 ? '#616ded' : 'transparent',
                                                color: priority === 3 ? '#fff' : '#ccc',
                                                borderColor: priority === 3 && '#616ded'
                                            }}
                                        >3</Box>
                                    </IconButton>
                                    <Typography variant="subtitle">High</Typography>
                                </Grid>
                                {
                                    (formik.touched.priority && formik.errors.priority) &&
                                    <p style={{ color: '#d32f2f', fontSize: '0.75rem', marginLeft: '50px' }}>{formik.errors.priority}</p>
                                }
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button type="submit" className="btn btn-lg" variant="contained" sx={{ background: '#616ded', padding: '12px 55px' }}>Save</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Slide>
    )
}


const mainPageStyle = {
    height: 'fit-content%',
    background: '#ecedf3',
}
const cardStyle = {
    m: '72px 170px'
}

const periorityStyle = {
    width: '50px',
    height: '50px',
    border: '2px solid #ccc',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    '&:hover': {
        border: '2px solid #616ded',
        color: '#616ded'
    }
}