import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'

export default function LazzyLoading() {
    const theme = useTheme()
    return <div>
        <div
            style={{ "--primary-color": theme.palette.primary.main }}
            className="lazzy-container d-flex flex-column">
            <Typography variant='h4' color="primary" sx={{ marginBottom: '20px' }}>Loading...</Typography>
            <div class="lds-roller">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
        </div>
    </div>
}
