import React, { useState } from "react";
import NoteAltRoundedIcon from "@mui/icons-material/NoteAltRounded";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Box, Typography } from "@mui/material";
import { useStyles } from "../../utils/styles";
import ChatBot from "./chatbot";
import GoalsModal from "./GoalsModal";
import TodoListModal from "../lessons/notes/TodoListModal";
import Notes from "../lessons/notes/Notes";
import StaticNotes from "../lessons/notes/StaticNotes";
import { isStatic } from "./isStatic";
import StaticTodoListModal from "../lessons/notes/StaticTodoListModal";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

export default function SkillSubSkillButtons() {
  const classes = useStyles();

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {showModal && (
        <div className="journal-modal">
          {isStatic ? <StaticNotes /> : <Notes />}
        </div>
      )}
      <Box className={classes.fixedBottomButtons}>
        <Box className={classes.flexColCenter}>
          <ChatBot data={{ name: "Ameer" }} pageName={"Skill Section"} />
          <Typography
            variant="caption"
            sx={{ color: (theme) => theme.palette.common.text }}
          >
            Chat
          </Typography>
        </Box>

        <Box className={classes.flexColCenter}>
          <IconButton
            variant="rounded"
            onClick={openModal}
            sx={{ background: showModal && "red" }}
          >
            {showModal ? <CloseIcon /> : <NoteAltRoundedIcon />}
          </IconButton>
          <Typography
            variant="caption"
            sx={{ color: (theme) => theme.palette.common.text }}
          >
            Journal
          </Typography>
        </Box>
        {
          !isStatic &&
          <Box className={classes.flexColCenter}>
            <GoalsModal page={"Skill Section"} />
            <Typography
              variant="caption"
              sx={{ color: (theme) => theme.palette.common.text }}
            >
              Goals
            </Typography>
          </Box>
        }


        <Box className={classes.flexColCenter}>
          {isStatic ? <StaticTodoListModal page="Skill Section" /> : <TodoListModal page="Skill Section" />}
          <Typography
            variant="caption"
            sx={{ color: (theme) => theme.palette.common.text }}
          >
            To Do
          </Typography>
        </Box>
        <Box className={classes.flexColCenter}>
          <IconButton
            variant="rounded"
            onClick={() => {
              window.print({
                pageSetup: {
                  size: "legal",
                  orientation: "landscape",
                  pages: "1",
                },
                backgrounds: true,
                backgroundGraphics: true,
              });
            }}
          >
            <LocalPrintshopOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            sx={{ color: (theme) => theme.palette.common.text }}
          >
            Print
          </Typography>
        </Box>
      </Box>
    </>
  );
}
