import OpenAI from 'openai';

// Initialize OpenAI client
const client = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_KEY_1, // Add this to your .env file
    dangerouslyAllowBrowser: true
});

export const messageToAssistant = async (questionQuery, threadId, grade) => {
    try {
        const additionalMessage = `For answers you must follow the reading level of grade ${grade} of a student. Grades are according to the lexile range. Always follow lexile range.`;
        const query = `${additionalMessage} 
        Question: ${questionQuery} 
        Answer: `;

        // Create a message in the thread
        await client.beta.threads.messages.create(threadId, {
            role: "user",
            content: query
        });

        // Poll for assistant's response with a timeout
        const response = await client.beta.threads.runs.createAndPoll(threadId, {
            assistant_id: process.env.REACT_APP_ASST_ID,
        });

        // Retrieve the latest message
        const messages = await client.beta.threads.messages.list(threadId, { limit: 1 });

        return messages.data[0]?.content[0]?.text?.value || 'No response found.';
    } catch (error) {
        console.error('Error:', error);
        return error.message || 'An error occurred.';
    }
};

