import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { EditorState, ContentState, convertFromRaw, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';

const DraftEditorRoot = styled('div')({
  border: '1px solid #E0E0E0',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  '& .DraftEditor-editorContainer': {
    borderBottom: 'none',
    borderColor: '#E0E0E0',
    borderLeft: 'none',
    borderRight: 'none',
    flexGrow: 1,
    overflow: 'auto',
    '& .public-DraftEditor-content': {
      color: '#000000',
      fontFamily: 'Arial, sans-serif',
      fontSize: '16px',
      padding: '16px',
      overflow: 'auto',
      '&:empty:before': {
        color: '#757575',
        fontStyle: 'normal',
        left: '16px',
        content: 'attr(placeholder)',
        position: 'absolute',
        pointerEvents: 'none',
      },
    },
  },
});

const AdminDraftEditorRoot = styled('div')({
  border: '1px solid #E0E0E0',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  '& .DraftEditor-editorContainer': {
    borderBottom: 'none',
    borderColor: '#E0E0E0',
    borderLeft: 'none',
    borderRight: 'none',
    flexGrow: 1,
    overflow: 'auto',
    '& .public-DraftEditor-content': {
      color: '#000000',
      fontFamily: 'Arial, sans-serif',
      fontSize: '16px',
      padding: '16px',
      overflow: 'auto',
      '&:empty:before': {
        color: '#757575',
        fontStyle: 'normal',
        left: '16px',
        content: 'attr(placeholder)',
        position: 'absolute',
        pointerEvents: 'none',
      },
    },
  },
});

const QuillEditor = ({ placeholder, value, readOnly, onChange, ...props }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (value && !isLoaded) {
      const blocksFromHTML = convertFromHTML(value);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
      setLoaded(true);
    }
  }, [value, isLoaded]);

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    if (onChange) {
      const contentState = newEditorState.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      const htmlContent = draftToHtml(rawContentState);
      onChange(htmlContent);
    }
  };

  const toolbarOptions = {
    options: readOnly ? [] : [
      'inline',
      'blockType',
      'fontSize',
      'fontFamily',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'embedded',
      'emoji',
      'image',
      'remove',
      'history',
    ],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
  };

  return (
    <div dangerouslySetInnerHTML={{ __html: value }}></div>
  );
};

const AdminQuillEditor = ({ placeholder, value, readOnly, onChange, quiz, ...props }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (value && !isLoaded) {
      const blocksFromHTML = convertFromHTML(value);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
      setLoaded(true);
    }
  }, [value, isLoaded]);

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    if (onChange) {
      const contentState = newEditorState.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      const htmlContent = draftToHtml(rawContentState);
      onChange(htmlContent);
    }
  };

  const toolbarOptions = {
    options: readOnly ? [] : [
      'inline',
      'blockType',
      'fontSize',
      'fontFamily',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'embedded',
      'emoji',
      'image',
      'remove',
      'history',
    ],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
  };

  return <div dangerouslySetInnerHTML={{ __html: value }}></div>
  return (
    <AdminDraftEditorRoot sx={readOnly ? { border: "none" } : {}}>
      <Editor
        toolbarHidden={!!readOnly}
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        placeholder={placeholder}
        toolbar={toolbarOptions}
        readOnly={readOnly}
        {...props}
      />
    </AdminDraftEditorRoot>
  );
};

export { QuillEditor, AdminQuillEditor };
