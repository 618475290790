import React from 'react'
// import { useNavigate } from 'react-router-dom'

import { slideInUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
// import { userLog } from '../../_api/index'
// import { useMutation } from 'react-query';


const styles = {
    slideInUp: {
        animation: 'x 2s',
        animationName: Radium.keyframes(slideInUp, 'slideInLeft'),
        bottom: 'unset',
    },
}

export default function GameWin(props) {
    return (
        <>
            <StyleRoot>
                <div className={props.open ? "full-screen-shadow concents-modal modal fade show d-flex justify-content-center align-items-center" : "concents-modal modal fade"} style={{ display: props.open ? "block" : "none" }} id="successModal">
                    <div className="modal-dialog" style={styles.slideInUp}>
                        <div className="modal-content" style={{ borderRadius: '10px', }}>
                            <div className="modal-header">
                                <h1 style={{ width: '100%', fontSize: '17px', fontWeight: '600' }} className="text-center text-success">Congratulations!</h1>
                                <button type="button" className="close text-dark opacity-full" data-dismiss="modal" onClick={() => {
                                    // log({
                                    //     pageName: 'Words Cape',
                                    //     buttonName: 'Close'
                                    // })
                                    props.setOpen(false)
                                }}>
                                    x
                                </button>
                            </div>

                            <div className="modal-body p-0" >
                                <div className="container-game-modal">
                                    <div className="row d-flex justify-content-center text-success py-3">
                                        <DoneAllRoundedIcon style={{ fontSize: '70px' }} />
                                        <h5 className="text-center">You have found the word</h5>
                                    </div>
                                    {/* <div className="row p-3 justify-content-around">
                                        <div className="col-3 text-center p-0">
                                            <h1>{props.totalTries}</h1>
                                            <span>Total tries</span>
                                        </div>
                                        <div className="col-3 text-center p-0">
                                            <h1>{parseInt(100 / props.totalTries)}%</h1>
                                            <span>Success rate</span>
                                        </div>
                                        <div className="col-3 text-center p-0">
                                            <h1>{props.totalTries}</h1>
                                            <span>Current streak</span>
                                        </div>
                                        <div className="col-3 text-center p-0">
                                            <h1>{props.totalTries}</h1>
                                            <span>Best streak</span>
                                        </div>
                                    </div>
                                    <div className="row d-flex px-3 pb-2">
                                        <h3 className="text-center">Guess Distribution</h3>
                                        {
                                            temp.map((x, i) => {
                                                return (
                                                    <div key={i} className="row ">
                                                        <div className="mt-2 d-flex align-items-center"><h5 className="p-0 m-0">{x}</h5>
                                                            {
                                                                props.totalTries === x ?
                                                                    <div className="ml-2 p-1 px-2 text-center" style={{ backgroundColor: '#616ded', color: '#fff', width: '95%' }} >{x}</div>
                                                                    : <div className="p-1 px-2 bg-dark1 ml-2">0</div>
                                                            }

                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div> */}
                                </div>
                            </div>

                            <div className="modal-footer tips-modal-footer d-flex justify-content-around">
                                <div className="text-center mt-2">
                                    <h4 className="p-0 m-0">New word in</h4>
                                    <span>02:28:43</span>
                                </div>
                                <button
                                    type="button"
                                    className="btn bg-primary1 px-4"
                                    data-dismiss="modal"
                                    style={{ padding: '11px', margin: '0px' }}
                                    onClick={() => {
                                        // log({
                                        //     pageName: 'Words Cape',
                                        //     buttonName: 'Submit Word'
                                        // })
                                        props.setOpen(false)
                                    }}
                                >
                                    Share
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </StyleRoot>
        </>
    )
}
