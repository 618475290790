import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import {
    TextField,
    Grid,
    Typography,
    FormControl,
    Button,
    IconButton,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { customerSupport } from "../_api"
import TitleIcon from '@mui/icons-material/Title';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { FormHelperText } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import toast from 'react-hot-toast'
import UploadIcon from '@mui/icons-material/Upload';
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import CloseIcon from '@mui/icons-material/Close';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snack = (props) => {
    let vertical = 'top'
    let horizontal = 'center'

    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={props.open}
            key={'top' + 'center'}
        >
            {
                props.error ? <Alert severity="error">{props.message}</Alert> : <Alert severity="success">{props.message}</Alert>
            }


        </Snackbar>
    )
}

export default function CustomerSupport() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const [open, setOpen] = useState(false)
    const [msg, setMsg] = useState()
    const [error, setError] = useState(false)
    const [files, setFiles] = useState([])
    const [filesLinks, setFilesLinks] = useState([])
    const [imgLoading, setImgLoading] = useState(false)
    const { t } = useTranslation();


    const formik = useFormik({
        initialValues: {
            email: email || '',
            subject: '',
            message: '',
            image: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email().required("Email is required"),
            subject: Yup.string().min(4).max(30).required("Subject is required").matches(/^[aA-zZ\s]+$/, "Please enter valid Subject"),
            message: Yup.string().min(15).max(800).required("Message is required").typeError("Please enter a valid Number"),
        }),
        onSubmit: async (values, helpers) => {
            toast.loading("Submiting...")
            const data = {
                from: values.email,
                subject: values.subject,
                content: values.message,
                images: filesLinks,
            }
            try {
                await customerSupport(data)
                toast.dismiss()
                setOpen(true)
                setTimeout(() => {
                    setOpen(false)
                }, 2000)
                setMsg('Email Sent Successfully')
                setFilesLinks([])
                setError(false)
                formik.setValues(formik.initialValues)
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
            }
            catch (err) {
                toast.dismiss()
                setOpen(true)
                setTimeout(() => {
                    setOpen(false)
                }, 2000)
                setMsg('Email Could Not be Sent')
                setError(true)
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
            }

        },
    });
    const [isMobile, setIsMobile] = useState(false);



    const fileUpload = async (file) => {
        setImgLoading(true)
        const target = {
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: file.name,
            Body: file,
        };
        const creds = {
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        };
        return new Promise(async (resolve, reject) => {
            try {
                const parallelUploads3 = new Upload({
                    client: new S3Client({
                        region: process.env.REACT_APP_REGION,
                        credentials: creds,
                    }),
                    leavePartsOnError: false,
                    params: target,
                });
                parallelUploads3.on("httpUploadProgress", (progress) => { });
                await parallelUploads3.done().then((res) => {
                    setFilesLinks([...filesLinks, `https://${process.env.REACT_APP_BUCKET_NAME}.s3.amazonaws.com/${file.name}`])
                    resolve(filesLinks)
                });
            } catch (e) {
                reject(e)
            }
        });

    };



    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);

    // if (isMobile) {
    //     return (<TurnPhoneH content="Turn your phone to side for privacy policy" />)
    // } else
        return (
            <>
                <Snack message={msg} open={open} error={error} />
                <div className="back back2 get-started-code-back" style={{
                    position: 'fixed',
                    width: '100%',
                    zIndex: 1000, top: '20px',
                    left: 0,
                }}>
                    <div className="back-wrapper">
                        <a
                            // to={props?.backLink}
                            style={{ padding: '7px 8px', marginLeft: '50px' }}
                            className="text-primary1 bg-light btn-back"
                            onClick={(e) => window.history.go(-1)}>
                            <ArrowBackIosRoundedIcon style={{ fontSize: '25px' }} />
                        </a>
                    </div>
                </div>
                <header className="header-bg">
                    <h1>
                        {t("customerSuport.heading")}
                    </h1>
                </header>

                <div className="container cs-container">
                    <div className="options">
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container justifyContent='center'>
                                <Grid item xs={12} my={1}>
                                    <FormControl sx={{ width: "100%" }}>
                                        <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>{t("customerSuport.Email")}</Typography>
                                        <TextField size="small" placeholder={t("customerSuport.EnterEmail")}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            disabled={!!email}
                                            name='email'
                                            onBlur={formik.handleBlur}
                                            fullWidth id="outlined-basic"
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EmailOutlinedIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                        {(formik.touched.email && formik.errors.email) && (
                                            <FormHelperText error>
                                                {formik.errors.email}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} my={1} >
                                    <FormControl sx={{ width: "100%" }} >
                                        <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>{t("customerSuport.IssueTitle")}</Typography>
                                        <TextField size="small" placeholder={t("customerSuport.placeholderTitle")}
                                            onChange={formik.handleChange}
                                            name='subject'
                                            value={formik.values.subject}
                                            onBlur={formik.handleBlur}
                                            fullWidth id="outlined-basic"
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <TitleIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                        {(formik.touched.subject && formik.errors.subject) && (
                                            <FormHelperText error>
                                                {formik.errors.subject}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} my={1}>
                                    <FormControl sx={{ width: "100%" }}>
                                        <Typography pl={1}
                                            sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>{t("customerSuport.IssueDescription")}</Typography>
                                        <TextField
                                            multiline minRows={4}
                                            size="small" placeholder={t("customerSuport.placeholderDescription")}
                                            onChange={formik.handleChange}
                                            value={formik.values.message}
                                            name='message'
                                            onBlur={formik.handleBlur}
                                            fullWidth id="outlined-basic"
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment sx={{ marginTop: '-4.5rem' }} >
                                                        <DescriptionIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                        {(formik.touched.message && formik.errors.message) && (
                                            <FormHelperText error>
                                                {formik.errors.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} my={1} >
                                    {
                                        !filesLinks?.length ?
                                            <Button
                                                startIcon={<UploadIcon fontSize="small" />}
                                                sx={{ m: 1, width: '100%', margin: '1rem 0', padding: '1rem' }}
                                            >
                                                <input
                                                    type="file"
                                                    multiple
                                                    style={{
                                                        position: "absolute",
                                                        left: "1px",
                                                        backgroundColor: "white",
                                                        padding: "10px",
                                                        opacity: "0%",
                                                        width: '100%'
                                                    }}
                                                    onChange={(e) => {
                                                        setFiles([...files, ...e.target.files])
                                                    }}
                                                    accept={"image/*"}
                                                />
                                                {t("customerSuport.uploadBtn")}
                                            </Button>
                                            : <h5 className="text-center text-success">Images Uploaded</h5>
                                    }


                                    {
                                        files?.map(fil => {
                                            return (
                                                <div className=' row d-flex justify-content-between  align-items-center mt-3 p-2' key={fil?.name} style={{ background: '#eee', borderRadius: '10px' }}>
                                                    <div className='d-flex  align-items-center' style={{ width: 'fit-content' }}>
                                                        <img width="70px" src={URL.createObjectURL(fil)} alt="img" />
                                                        <span style={{ marginLeft: 40 }}>
                                                            {fil.name}
                                                        </span>
                                                    </div>
                                                    <IconButton sx={{ width: 'fit-content', height: 'fit-content' }} onClick={() => {
                                                        setFiles(files?.filter(file => file.name !== fil.name))
                                                    }}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        !!files?.length &&
                                        <div className="d-flex justify-content-end mb-3">
                                            <Button sx={{ mt: 3 }} variant="contained" onClick={() => {
                                                toast.loading("Uploading images...")
                                                files?.map(async (fil) => {
                                                    Promise.all(await fileUpload(fil)).then(() => {
                                                        toast.dismiss()
                                                        toast.success("Images Uploaded")
                                                        setImgLoading(false)
                                                        setFiles([])
                                                    })
                                                })
                                            }}>Upload Images</Button>
                                        </div>

                                    }
                                </Grid>
                                <Grid item xs={12} sx={{ mb: 3 }} container justifyContent='center'>
                                    <FormControl >
                                        <Button type="submit" variant="contained" disabled={imgLoading} sx={{ minWidth: '10rem' }}>{t("customerSuport.Submit")}</Button>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div >
            </>
        )
}
