import React from 'react'

export default function EmptyRow(props) {
    const { word, keyVal } = props
    let arr = [...word]
    return (
        <>
            <div key={keyVal} className="row">
                {
                    arr.map((_, i) => {
                        return (<div key={i} className={"letter bg-white1 white-shadow empty-size" + arr.length}> </div>)
                    })
                }
            </div>
        </>
    )
}
