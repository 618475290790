import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import { Typography, Button, TextField, IconButton, Grid } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { useState, useRef } from 'react';
import Modal from '@mui/material/Modal';
import Loading from './Loading';
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import { submitAssigment } from '../_api/sticky';
import { useQueryClient } from 'react-query'
import { QuillEditor } from '../lessons/details/quil-editor';
import CloseIcon from '@mui/icons-material/Close';



const StickyDemo = (props) => {
    const queryClient = useQueryClient()
    const { sticky, assignmentID, setShowDemo, assigneeData } = props;
    const inputFileRef = useRef();
    const [showbox, setShowbox] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openloading, setOpenloading] = useState(false);
    const [openDescription, setOpenDescription] = useState(false)
    const [isReadMore, setIsReadMore] = useState(false)
    const [isShowLess, setIsShowLess] = useState(false)
    const handleCloseloading = () => setOpenloading(false);
    const formik = useFormik({
        initialValues: {
            answer: assigneeData?.answer || "",
            attachment: assigneeData?.attachment || '',
            submit: null,
        },
        validationSchema: Yup.object({

        }),
        onSubmit: async (values, helpers) => {
            setOpenloading(true)
            try {
                const res = await submitAssigment({
                    assignmentID: assignmentID,
                    answer: formik.values.answer,
                    attachment: values.attachment
                });
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
                queryClient.invalidateQueries("get-all-Stickies")
                toast.success("Assignment Submitted");
                setOpenloading(false)
                setShowDemo(false)
            } catch (err) {
                toast.dismiss();
                setOpenloading(false)
                toast.error(err.message);
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
            }
        }
    });

    const canSubmit = () => {
        if ((assigneeData?.approval === "pending" || assigneeData?.approval === "approved") && assigneeData?.status !== "not submitted") return false
        return true
    }

    const fileUpload = async (file) => {
        toast.loading("Uploading file please wait...")
        const target = {
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: file.name,
            Body: file,
        };
        const creds = {
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        };
        try {
            const parallelUploads3 = new Upload({
                client: new S3Client({
                    region: process.env.REACT_APP_REGION,
                    credentials: creds,
                }),
                leavePartsOnError: false,
                params: target,
            });

            parallelUploads3.on("httpUploadProgress", (progress) => { });

            await parallelUploads3.done().then((res) => {
                formik.setFieldValue("attachment", file.name);
                toast.dismiss()
                toast.success("Attachment Uploaded")
            });
        } catch (e) { }
    };
    useEffect(() => {
        setIsReadMore(sticky?.description?.length > 1000)
    }, [sticky])


    const stickyDescription = isReadMore ? sticky.description?.slice(0, 1000) + '...' : sticky?.description
    return (
        <div>
            <div className="px-5">
                {!showbox ?
                    <Box className="question-box">

                        <Grid container style={{ gap: 10 }}>
                            <Grid item sm={5.9}>
                                <Box>
                                    <Typography className='question-text'>
                                        Details
                                    </Typography>
                                </Box>
                                <Box className='box-inner'>
                                    <QuillEditor className='paragraph'
                                        value={stickyDescription}
                                        readOnly={true}
                                    />
                                    {
                                        isReadMore &&
                                        <Box className="d-flex justify-content-end w-100">
                                            <a onclick='alert(0)' onClick={() => {
                                                setIsReadMore(false)
                                                setIsShowLess(true)
                                            }} href='#'>Show More</a>
                                        </Box>
                                    }
                                    {
                                        isShowLess &&
                                        <Box className="d-flex justify-content-end w-100">
                                            <a onclick='alert(0)' onClick={() => {
                                                setIsReadMore(true)
                                                setIsShowLess(false)
                                            }} href='#'>Show Less</a>
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                            <Grid item sm={5.9}>
                                <Box>
                                    <Typography className='question-text'>
                                        Question
                                    </Typography>
                                </Box>
                                <Box className='box-inner'>
                                    {sticky.contentType === "image" ?
                                        <img src={process.env.REACT_APP_S3_BUCKET_LINK + sticky.content} style={{ width: "100%" }} />
                                        :
                                        sticky?.contentType === "video" ?
                                            <video style={{ width: "100%" }} controls>
                                                <source src={process.env.REACT_APP_S3_BUCKET_LINK + sticky?.content} type="video/mp4" />
                                            </video>
                                            : <QuillEditor className='paragraph'
                                                value={sticky?.content}
                                                readOnly={true}
                                            />
                                    }
                                </Box>
                            </Grid>
                        </Grid>

                        <div className="text-end mt-3">
                            <Button variant="contained" endIcon={<ChevronRightIcon />}
                                onClick={() => { setShowbox(true) }}>
                                Next
                            </Button>
                        </div>
                    </Box>
                    :
                    <form onSubmit={formik.handleSubmit}>
                        <Box className="question-box">
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography className='question-text'>
                                    Answer
                                </Typography>
                                <Typography className='attachment' onClick={handleOpen}>
                                    {
                                        canSubmit() ?
                                            <>
                                                <AddIcon className='me-2' style={{ fontSize: '2rem' }} /> Add Attachments
                                            </> : <>Your Attachment</>
                                    }

                                </Typography>
                            </Box>
                            <Box className='box-inner'>
                                <TextField
                                    rows={10}
                                    error={Boolean(
                                        formik.touched.answer && formik.errors.answer
                                    )}
                                    fullWidth
                                    multiline
                                    helperText={formik.touched.answer && formik.errors.answer}
                                    label="Write Answer"
                                    name="answer"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    required
                                    value={formik.values.answer}
                                />

                            </Box>
                            {
                                canSubmit() ?
                                    <div className="text-end mt-3">
                                        <Button variant="contained" type="submit"
                                            startIcon={<CheckIcon />} style={{ textTransform: 'capitalize' }}>
                                            Submit Stickee
                                        </Button>
                                    </div> : <></>
                            }
                        </Box>
                    </form>
                }

                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='attachment-modal'>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>

                        </div>

                        <div className="text-center">

                            {formik.values?.attachment && <img style={{ maxWidth: 300 }} src={process.env.REACT_APP_S3_BUCKET_LINK + formik.values.attachment} alt="" />}
                            {
                                !canSubmit() && !formik.values?.attachment &&
                                <>You have not uploaded any Attachement</>
                            }
                            {
                                canSubmit() &&
                                <Button
                                    sx={{ m: 1 }}
                                >
                                    <input
                                        type="file"
                                        name="file"
                                        ref={inputFileRef}
                                        style={{
                                            position: "absolute",
                                            left: "1px",
                                            backgroundColor: "white",
                                            padding: "10px",
                                            opacity: "0%",
                                        }}
                                        onChange={(e) => fileUpload(e.target.files[0])}
                                        accept={"image/*"}
                                    />
                                    Upload Your Attachment
                                </Button>
                            }
                        </div>
                    </Box>
                </Modal>
                <Modal
                    open={openDescription}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='attachment-modal'>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography variant="h6">Description</Typography>
                            <IconButton onClick={() => setOpenDescription(false)}>
                                <CloseIcon />
                            </IconButton>

                        </div>
                        <QuillEditor className='paragraph'
                            value={sticky?.description}
                            readOnly={true}
                        />
                    </Box>
                </Modal>
                <Modal
                    open={openloading}
                    onClose={handleCloseloading}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='sticky-check'>
                        <Loading />
                    </Box>
                </Modal>
            </div>
        </div>
    );
}

export default StickyDemo
