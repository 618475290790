import React from 'react'

function Sims({ color }) {
    return (
        <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.9649 1.8125H2.77167C2.2 1.8125 1.73657 2.27593 1.73657 2.84759V24.2603C1.73657 24.8319 2.2 25.2953 2.77167 25.2953H37.9649C38.5366 25.2953 39 24.8319 39 24.2603V2.84759C39 2.27593 38.5366 1.8125 37.9649 1.8125Z" stroke={color} stroke-width="2" stroke-miterlimit="10" />
            <path d="M29.6357 1.8125V25.2953" stroke={color} stroke-width="1.5" stroke-miterlimit="10" />
            <path d="M35.8843 4.28613H33.0585C32.6774 4.28613 32.3684 4.59508 32.3684 4.9762V9.98605C32.3684 10.3672 32.6774 10.6761 33.0585 10.6761H35.8843C36.2654 10.6761 36.5743 10.3672 36.5743 9.98605V4.9762C36.5743 4.59508 36.2654 4.28613 35.8843 4.28613Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M32.3684 13.3438H36.5743" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M32.3687 16.3701H36.5746" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M32.368 19.3896H36.574" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.6858 13.9502V20.1918" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.6862 8.04395L21.1446 11.0112L15.6862 13.9405L10.2036 10.9905L15.6862 8.04395Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21.1446 17.2487L15.6862 20.178L10.2036 17.2314" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21.144 11.0078V17.246" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.2217 10.9932V17.2313" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.6869 20.1815L10.2043 17.2314" stroke={color} stroke-width="1.31579" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M23.5179 18.5081L21.144 17.2314" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.8479 18.5081L10.2217 17.2314" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.6858 8.04678V5.40039" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.3681 26.084V28.5855H14.23" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M26.5065 28.5801H20.3684" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Sims