import React, { useState } from "react";
import {
  Paper,
  Grid,
} from "@mui/material";
import { Typography } from "@mui/material";
import { timeAgo, formatTimestamp } from "../notifications/FormattedTime";
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import NotificationTable from "../notifications/notification-table";

const Listings = () => {
  const notifications = useSelector((state) => state?.user?.notifications);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState({});

  return showDetails ? (
    <Paper
      sx={{
        height: "auto",
        padding: "16px",
        backgroundColor: "#f5f5f5",
        borderRadius: "16px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "8px 16px",
            }}
          >
            <a
              href=""
              onClick={(event) => {
                event.preventDefault();
                setShowDetails(false);
              }}
              className="mybtn customizedbtn"
              style={{
                background: "transparent",
                color: "#2196F3",
                textDecoration: "underline",
              }}
            >
              <img
                src="assets/img/arrowback.png"
                style={{ paddingRight: ".5rem" }}
              />
            </a>
            <Tooltip title={formatTimestamp(selectedNotification.createdAt)}>
              <Typography variant="body2" color="textSecondary">
                {timeAgo(selectedNotification.createdAt)}
              </Typography>
            </Tooltip>
          </div>
        </Grid>
        <Grid item md={12}>
          <Typography
            variant="body1"
            style={{
              paddingLeft: "29px",
              marginTop: "16px",
              fontSize: "1.2rem",
              color: "#333",
              lineHeight: "1.5",
            }}
          >
            {selectedNotification.message}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  ) : (
    <>
      <NotificationTable
        notifications={notifications}
        selectedNotification={selectedNotification}
        setSelectedNotification={setSelectedNotification}
        setShowDetails={setShowDetails}
      />
    </>
  );
};

export default Listings;
