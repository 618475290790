import React from 'react'

export default function Cell(props) {
    const { latter, keyVal, status, boxSize } = props
    return (
        <>
            <div key={keyVal} className={status === "correct" ? "letter bg-primary1 primary-shadow box-size" + boxSize : status === "exist" ? "letter bg-secondary1 secondary-shadow box-size" + boxSize : status === "absent" ? "letter bg-dark1 dark-shadow box-size" + boxSize : "letter box-size" + boxSize}>{latter}</div>
        </>
    )
}
