import * as Axios from 'axios';
import { isStatic } from '../common/isStatic';
const API_URL = process.env.REACT_APP_API_LINK;

const axios = Axios.create({
    'headers': {
        'x-api-key': process.env.REACT_APP_X_API_KEY
    }
})
export const getAssignments = async (type) => {
    if (isStatic) {
        return []
    }
    const res = await axios.get(`${API_URL}/skill-assignment/partname/${type}`,
        {
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('token')}`
            }
        });
    return res.data;

}