import React from 'react'

function Intro({ color }) {
    return (
        <svg width="40" height="34" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.9 12.0871C9.83662 12.0871 12.2172 9.7171 12.2172 6.79355C12.2172 3.87 9.83662 1.5 6.9 1.5C3.96337 1.5 1.58276 3.87 1.58276 6.79355C1.58276 9.7171 3.96337 12.0871 6.9 12.0871Z" stroke={color} stroke-width="1.82916" stroke-miterlimit="10" />
            <path d="M2.09258 15.1982H11.507C11.7967 15.1985 12.0744 15.3133 12.2791 15.5173L15.9757 19.1974C16.1805 19.4011 16.4582 19.5155 16.7477 19.5155C17.0373 19.5155 17.315 19.4011 17.5198 19.1974L20.8486 15.8908C21.0534 15.6871 21.3311 15.5726 21.6207 15.5726C21.9102 15.5726 22.1879 15.6871 22.3928 15.8908L24.1008 17.5912C24.3054 17.7952 24.4204 18.0716 24.4204 18.3599C24.4204 18.6481 24.3054 18.9246 24.1008 19.1285L17.5089 25.6838C17.3041 25.8875 17.0264 26.0019 16.7368 26.0019C16.4473 26.0019 16.1696 25.8875 15.9647 25.6838L13.9544 23.6861C13.9493 23.6811 13.9428 23.6777 13.9358 23.6764C13.9289 23.675 13.9216 23.6757 13.915 23.6784C13.9084 23.6811 13.9028 23.6857 13.8988 23.6916C13.8948 23.6974 13.8926 23.7043 13.8925 23.7114V31.4125C13.8925 31.7009 13.7774 31.9776 13.5725 32.1816C13.3676 32.3856 13.0897 32.5002 12.7999 32.5002H2.09258C1.80281 32.5002 1.52491 32.3856 1.32001 32.1816C1.11511 31.9776 1 31.7009 1 31.4125V16.286C1 15.9975 1.11511 15.7208 1.32001 15.5168C1.52491 15.3128 1.80281 15.1982 2.09258 15.1982V15.1982Z" stroke={color} stroke-width="1.82916" stroke-miterlimit="10" />
            <path d="M16.7478 13.5156V3.31286C16.7478 2.83206 16.9397 2.37095 17.2812 2.03097C17.6227 1.691 18.0858 1.5 18.5688 1.5H37.1791C37.662 1.5 38.1252 1.691 38.4667 2.03097C38.8082 2.37095 39.0001 2.83206 39.0001 3.31286V30.687C39.0001 31.1678 38.8082 31.6289 38.4667 31.9689C38.1252 32.3089 37.662 32.4999 37.1791 32.4999H16.7478" stroke={color} stroke-width="1.82916" stroke-miterlimit="10" stroke-linecap="round" />
            <path d="M20.5645 6.53223H35.2633" stroke={color} stroke-width="1.82916" stroke-miterlimit="10" stroke-linecap="round" />
            <path d="M20.5645 10.6436H35.2633" stroke={color} stroke-width="1.82916" stroke-miterlimit="10" stroke-linecap="round" />
            <path d="M26.5044 14.7559H35.2633" stroke={color} stroke-width="1.82916" stroke-miterlimit="10" stroke-linecap="round" />
        </svg>
    )
}

export default Intro