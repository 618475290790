import React, { useState } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ProgressBar } from 'react-bootstrap';

export default function Quiz({ quiz: content }) {
    const [index, setIndex] = useState(0)
    const getAns = () => content[index]
    const { answer01, answer02, answer03, answer04, question } = getAns(index)
    return (
        <>
            <div className="row" style={divStyle}>
                <div style={quizStyle}>
                    <ProgressBar
                        style={progressStyle}
                        className="my-progrss" now={(index + 1) / content?.length * 100}
                        label={`${((index + 1) / content?.length * 100).toFixed(0)}%`}
                    />
                    <FormControl sx={{ m: 3 }} variant="standard">
                        <FormLabel id="demo-error-radios">
                            <p className="text-primary1" style={questionStyle}>Q{index + 1}.  {question}</p>
                        </FormLabel>
                        <RadioGroup aria-labelledby="demo-error-radios">
                            <FormControlLabel value={1} control={<Radio style={radioStyle} />} label={answer01} />
                            <FormControlLabel value={2} control={<Radio style={radioStyle} />} label={answer02} />
                            {
                                (answer03 !== "null" && answer03.trim() !== "") &&
                                <FormControlLabel value={3} control={<Radio style={radioStyle} />} label={answer03} />
                            }
                            {
                                (answer04 !== "null" && answer04.trim() !== "") &&
                                <FormControlLabel value={4} control={<Radio style={radioStyle} />} label={answer04} />
                            }
                        </RadioGroup>
                    </FormControl>

                    <button className="next" onClick={() => content[index + 1] && setIndex(index + 1)}>
                        <img src="../assets/icons/next-btn.svg" alt="next" />
                    </button>
                </div>
            </div>
            <div className="prev" style={preStyle} onClick={() => index !== 0 && setIndex(index - 1)}>
                <img src="../assets/icons/prev-btn.svg" alt="prev" />
            </div>
        </>
    )
}

const quizStyle = {
    width: 'fit-content',
    minWidth: '50%',
    maxWidth: '65%',
    padding: '10px 20px',
    borderRadius: '16px',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.4)',
    fontSize: '16px',
    textAlign: 'left',

}
const progressStyle = {
    height: '20px',
    marginTop: '12px',
    padding: '0px'
}
const radioStyle = {
    '& span': {
        margin: 0
    }
}
const divStyle = {
    height: '90%',
    justifyContent: 'center',
    alignItems: 'center'
}
const questionStyle = {
    fontSize: '16px',
    fontWeight: '600'
}
const preStyle = {
    width: 'fit-content',
    left: 130
}