import React from 'react'
import Cell from './Cell'
import { flipInX } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { useDispatch } from 'react-redux'
import { setCorrectLetters, setExistLetters, setAbsentLetters, removeAllLetters } from '../../redux-features/user/userSlice'
const styles = {
    flipInX: {
        animation: 'x 2s',
        animationName: Radium.keyframes(flipInX, 'flipInX')
    },
}

export default function Guesses(props) {
    const dispatch = useDispatch()
    const { word, keyVal, value } = props
    let arr = [...value]
    let arr2 = [...word]

    function findStatus(v, i) {
        let index = arr2.findIndex(x => x == v)
        if (index === -1) {
            if (word !== value) {
                dispatch(setAbsentLetters(v))
            } else {
                dispatch(removeAllLetters())
            }
            return ("absent")
        } else if (arr2[i] === v) {
            if (word !== value) {
                dispatch(setCorrectLetters(v))
            } else {
                dispatch(removeAllLetters())
            }
            return ("correct")
        } else {
            if (word !== value) {
                dispatch(setExistLetters(v))
            } else {
                dispatch(removeAllLetters())
            }
            return ("exist")
        }
    }
    return (
        <>
            <StyleRoot>
                <div style={styles.flipInX} key={keyVal} className="row d-flex">
                    {
                        arr.map((v, i) => {

                            return (
                                <Cell
                                    latter={v}
                                    keyVal={i}
                                    status={findStatus(v, i)}
                                    boxSize={arr.length}
                                />
                            )
                        })
                    }
                </div>
            </StyleRoot>
        </>
    )
}
