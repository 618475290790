import React, { useState } from "react";
import { QuillEditor } from "../../details/quil-editor";
import "react-h5-audio-player/lib/styles.css";
import { Grid, Box, Card, Button, useTheme } from "@mui/material";
import * as Yup from "yup";
import { Adminwrapper, TypographyWithMore } from "../../../common";
import {
  ADMIN_LINK,
  checkQuilValidation,
  DATA_TYPE,
  isAdminAdd,
  isAdminEdit,
  isJson,
  renderPlaceholder,
} from "../../../common/contentType/contentTypeData";
import { useFormik } from "formik";
import CustomVideoPlayer from "../../../common/contentType/CustomVideoPlayer";
import CustomCardWrapper from "../../../common/contentType/CustomCardWrapper";

const RenderDoodleVideo = ({ content }) => {
  const { QUILL, MEDIA, LINK } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: isJson(content?.allContent.content)
      ? JSON.parse(content?.allContent.content)?.link
      : content?.allContent.content,
    text: isJson(content?.allContent.content)
      ? JSON.parse(content?.allContent.content)?.text
      : "",
  });
  const theme = useTheme();
  const videoVisibility = () => {
    if (!data?.content) return "https://youtu.be/9xwazD5SyVg";
    if (!data?.content.name) return data?.content;
    return URL.createObjectURL(data?.content);
  };
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
      text: data?.text,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Video is Required"),
      text: Yup.mixed().required("Text is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <CustomCardWrapper>
      {isAdminEdit() && (
        <Button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </Button>
      )}
      {isAdminAdd() && (
        <Button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </Button>
      )}

      <Grid
        container
        spacing={{ xs: 4 }}
        height="100%"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Adminwrapper
            errorText={contentFormik.errors.name}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="name"
          >
            {renderPlaceholder(data?.name, "Enter Name")}
            <TypographyWithMore
              variant="h2"
              color={"primary"}
              data={data?.name}
              textLength={10}
              lines={2}
            />
          </Adminwrapper>
          <Adminwrapper
            errorText={contentFormik.errors.description}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="description"
          >
            {renderPlaceholder(data?.description, "Enter Description")}
            <TypographyWithMore
              variant="subTitle1"
              color="text"
              data={data?.description}
              textLength={70}
              lines={9}
            />
          </Adminwrapper>
        </Grid>
        <Grid item xs={12} md={6} display="flex" justifyContent="center">
          <Adminwrapper
            errorText={contentFormik.errors.content}
            dataType={MEDIA}
            data={data}
            setData={setData}
            type="content"
            mediaType={"VIDEO"}
          >
            <CustomVideoPlayer
              url={videoVisibility()}
              text={data?.text || "No Transcript Added"}
              autoPlay={true}
            />
          </Adminwrapper>
        </Grid>
      </Grid>

      {(isAdminAdd() || isAdminEdit()) && (
        <Adminwrapper
          errorText={contentFormik.errors.text}
          dataType={LINK}
          data={data}
          setData={setData}
          type="text"
          label="Video Subtitle"
        >
          {renderPlaceholder(data?.text, "Enter Video Subtitle")}
          <QuillEditor sx={quilStyle} value={data?.text} readOnly={true} />
        </Adminwrapper>
      )}
    </CustomCardWrapper>
  );
};

const btnUpdateStyle = {
  position: "fixed",
  left: 15,
  bottom: 50,
  padding: "7px 20px",
  backgroundColor: (theme) => `${theme.palette.common.white}`,
  border: "none",
  borderRadius: "10px",
  color: "#616ded",
  width: "fit-content",
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.4)",
};
const btnAddStyle = {
  position: "fixed",
  left: 20,
  bottom: 50,
  padding: "7px 30px",
  backgroundColor: "#fff",
  border: "none",
  borderRadius: "10px",
  color: "#616ded",
  width: "fit-content",
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.4)",
};

const quilStyle = {
  fontSize: "16px",
  marginBottom: "20px",
  marginTop: "20px",
};

export default RenderDoodleVideo;
