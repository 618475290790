import React, { useEffect } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const NotFound = () => {
  const theme = useTheme();

  return (
    <>
      <Box
        component="main"
        style={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          flexGrow: 1,
          paddingTop: '70px'
        }}
      >
        <Container maxWidth="lg">
          <Typography
            align="center"
            variant="h4"
          >
            404: The page you are looking for isn’t here
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            style={{ marginTop: '0.5rem' }}
            variant="subtitle2"
          >
            You either tried some shady route or you
            came here by mistake. Whichever it is, try using the
            navigation.
          </Typography>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1.5rem'
            }}
          >
            <img
              alt="Under development"
              src={`/assets/svg/error404_${theme.palette.mode}.svg`}
              style={{
                height: 'auto',
                maxWidth: '100%',
                width: '375px'
              }}
            />
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem'
            }}
          >
            <a href="/module">
              <Button
                variant="outlined"
                sx={{ fontWeight: 'bold' }}
              >
                Back to Module
              </Button>
            </a>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NotFound;
