import React from 'react'

function Sims1({ color }) {
    return (
        <svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M42.9649 11.8125H7.77167C7.2 11.8125 6.73657 12.2759 6.73657 12.8476V34.2603C6.73657 34.8319 7.2 35.2953 7.77167 35.2953H42.9649C43.5366 35.2953 44 34.8319 44 34.2603V12.8476C44 12.2759 43.5366 11.8125 42.9649 11.8125Z" stroke={color} stroke-width="2" stroke-miterlimit="10" />
            <path d="M34.6357 11.8125V35.2953" stroke={color} stroke-width="1.5" stroke-miterlimit="10" />
            <path d="M40.8843 14.2861H38.0585C37.6774 14.2861 37.3684 14.5951 37.3684 14.9762V19.9861C37.3684 20.3672 37.6774 20.6761 38.0585 20.6761H40.8843C41.2654 20.6761 41.5743 20.3672 41.5743 19.9861V14.9762C41.5743 14.5951 41.2654 14.2861 40.8843 14.2861Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M37.3684 23.3438H41.5743" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M37.3687 26.3701H41.5746" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M37.3679 29.3896H41.5739" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.6858 23.9502V30.1918" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.6862 18.0439L26.1446 21.0112L20.6862 23.9405L15.2036 20.9905L20.6862 18.0439Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M26.1446 27.2487L20.6862 30.178L15.2036 27.2314" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M26.144 21.0078V27.246" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.2217 20.9932V27.2313" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.6869 30.1815L15.2043 27.2314" stroke={color} stroke-width="1.31579" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M28.5179 28.5081L26.144 27.2314" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.8479 28.5081L15.2217 27.2314" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.6858 18.0468V15.4004" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M25.3681 36.084V38.5855H19.23" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M31.5065 38.5801H25.3684" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Sims1