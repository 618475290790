import React, { useEffect, useState } from 'react'
import {
    Grid,
    Typography,
} from '@mui/material';
import moment from 'moment';
import { EventsOfDayModal } from './modals'

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const daysInWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export function YearlyCalendar({ year, activeDateStyle, filteredDropedItems, dropedItems, skillCardStyle, openedCardDetails }) {
    const [dayClicked, setDayClicked] = useState(null)
    const [openCurrentEvents, setOpenCurrentEvents] = useState(false)
    const events = filteredDropedItems(dropedItems, moment(dayClicked))
    return (
        <Grid container justifyContent="space-around" sx={{ p: 2 }}>
            {monthNames.map((month, index) => (
                <MonthGrid
                    key={month}
                    month={index}
                    year={year}
                    activeDateStyle={activeDateStyle}
                    setOpenCurrentEvents={setOpenCurrentEvents}
                    setDayClicked={setDayClicked}
                />
            ))}


            {
                <EventsOfDayModal
                    openCurrentEvents={openCurrentEvents}
                    setOpenCurrentEvents={setOpenCurrentEvents}
                    events={events}
                    skillCardStyle={skillCardStyle}
                    openedCardDetails={openedCardDetails}
                    day={dayClicked}
                />
            }
        </Grid>
    )
}


const MonthGrid = ({ month, year, activeDateStyle, setOpenCurrentEvents, setDayClicked }) => {

    const [daysInMonth, setDaysInMonth] = useState(new Date(year, month + 1, 0).getDate())
    const [firstDay, setFirstDay] = useState(new Date(year, month, 1).getDay())
    const [lastDay, setLastDay] = useState(new Date(year, month, daysInMonth).getDay())
    const [emptyCellsStart, setEmptyCellsStart] = useState(Array(firstDay).fill(null))
    const [monthCells, setMonthCells] = useState(Array.from({ length: daysInMonth }, (_, i) => i + 1))
    const [emptyCellsEnd, setEmptyCellsEnd] = useState(Array(6 - lastDay).fill(null))
    const [monthArray, setMonthArray] = useState([...emptyCellsStart, ...monthCells, ...emptyCellsEnd])

    useEffect(() => {
        setDaysInMonth(new Date(year, month + 1, 0).getDate());
        setFirstDay(new Date(year, month, 1).getDay())
        setLastDay(new Date(year, month, daysInMonth).getDay())
        setEmptyCellsStart(Array(firstDay).fill(null))
        setMonthCells(Array.from({ length: daysInMonth }, (_, i) => i + 1));
        setEmptyCellsEnd(Array(6 - lastDay).fill(null));
        setMonthArray([...emptyCellsStart, ...monthCells, ...emptyCellsEnd]);
    }, [year, month])
    return (
        <Grid container item lg={2.8} md={3.5} sm={5.5} xs={12} direction="row" alignItems="center" sx={{ my: 2 }}>
            <Grid item xs={12}>
                <Typography variant="h6">{monthNames[month]}</Typography>
            </Grid>
            {daysInWeek.map((day) => (
                <Grid sx={{ display: 'flex', justifyContent: 'center' }} key={day} xs={1.7} item>
                    <Typography variant="body2" align="center" color="textSecondary">{day}</Typography>
                </Grid>
            ))}
            {monthArray.map((day) => {
                day = parseInt(day)
                return <Grid key={day} item xs={1.7} sx={{ display: 'flex', justifyContent: 'center' }} >
                    {
                        day ? (
                            <Typography variant="body1" align='center'
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setDayClicked(moment(`${year}-${month + 1}-${day}`, 'YYYY-MM-DD'))
                                    setOpenCurrentEvents(true)
                                }}
                            >
                                <span style={{
                                    background: moment(`${year}-${month + 1}-${day}`, 'YYYY-MM-DD').isSame(moment(), 'day') ? '#616ded' : 'transparent',
                                    color: moment(`${year}-${month + 1}-${day}`, 'YYYY-MM-DD').isSame(moment(), 'day') ? '#fff' : moment(`${year}-${month + 1}-${day}`, 'YYYY-MM-DD').isBefore(moment(), 'day') ? "rgba(55, 65, 81, 0.40)" : 'rgba(55, 65, 81, 0.80)',
                                    ...activeDateStyle
                                }}>{day}</span>
                            </Typography>
                        ) : (
                            <Typography variant="body1" color="textSecondary">{''}</Typography>
                        )
                    }
                </Grid >
            })}
        </Grid >
    );
};
