import {
  ADMIN_LINK,
  checkQuilValidation,
  DATA_TYPE,
  isAdminAdd,
  isAdminEdit,
  isValidUrl,
  renderPlaceholder,
} from "../../../common/contentType/contentTypeData";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Adminwrapper } from "../../../common";
import { useStyles } from "../../../../utils/styles";
import TypographyWithMore from "../../../common/contentType/TypographyWithMore";
import CustomCardWrapper from "../../../common/contentType/CustomCardWrapper";
import { Box, Button, Grid, useTheme } from "@mui/material";
import LazzyLoading from "../../../common/LazzyLoading";
import CustomAudioPlayer from "../../../common/contentType/CustomAudioPlayer";

const RenderIframAudio = ({ content }) => {
  const myObj = JSON.parse(content?.allContent?.content);
  const { wireframe_link, wireframe_link1, text } = myObj;
  const { name, description, type } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link,
    wireframe_link1,
    text,
  });
  const classes = useStyles();
  const theme = useTheme();
  const { QUILL, MEDIA, LINK } = DATA_TYPE;
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      wireframe_link: data.wireframe_link,
      wireframe_link1: data.wireframe_link1,
      text: data.text,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      wireframe_link: Yup.string().required("Link is Required"),
      wireframe_link1: Yup.string().required("Audio is Required"),
      text: Yup.string().required("Audio Subtitle is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <>
      <CustomCardWrapper>
        {isAdminEdit() && (
          <Button
            className={classes.btnUpdateStyle}
            onClick={contentFormik.handleSubmit}
          >
            Update
          </Button>
        )}
        {isAdminAdd() && (
          <Button
            className={classes.btnAddStyle}
            onClick={contentFormik.handleSubmit}
          >
            ADD
          </Button>
        )}

        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <TypographyWithMore
            variant="h2"
            color="primary"
            data={"data?.name"}
            textLength={50}
            lines={2}
          />
        </Adminwrapper>

        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          <TypographyWithMore
            variant="body1"
            color="text"
            data={"data?.description"}
            textLength={50}
            lines={4}
          />
        </Adminwrapper>

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: { xs: "column-reverse", lg: "row" },
            alignItems: "center",
            height: { lg: "100%" },
          }}
          spacing={{ xs: 2, lg: 8 }}
        >
          <Grid
            item
            xs={12}
            lg={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Adminwrapper
              errorText={contentFormik.errors.wireframe_link1}
              dataType={MEDIA}
              data={data}
              setData={setData}
              type="link2"
              mediaType={"AUDIO"}
            >
              <CustomAudioPlayer
                autoPlay={false}
                src={
                  data?.wireframe_link1?.includes("https")
                    ? data?.wireframe_link1
                    : process.env.REACT_APP_S3_BUCKET_LINK +
                      data?.wireframe_link1
                }
                text={data?.text || "No subtitle to show"}
              />
            </Adminwrapper>
            {(isAdminAdd() || isAdminEdit()) && (
              <Adminwrapper
                errorText={contentFormik.errors.text}
                dataType={LINK}
                data={data}
                setData={setData}
                type="text"
                label="Audio Subtitle"
              >
                <TypographyWithMore
                  variant="body1"
                  color="text"
                  data={"data?.text"}
                  textLength={50}
                  lines={4}
                  textAlign="center"
                  width="100%"
                />
              </Adminwrapper>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            lg={8}
            width="100%"
            height="100%"
            mt={{ xs: "1.5rem", lg: "" }}
          >
            <Adminwrapper
              errorText={contentFormik.errors.wireframe_link}
              dataType={LINK}
              data={data}
              setData={setData}
              type="link1"
            >
              {!isIframeReady && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <LazzyLoading />
                </Box>
              )}

              <Box
                sx={{
                  width: "100%",
                  height: { xs: "20rem", lg: "100%" },
                  opacity: isIframeReady ? 1 : 0,
                }}
              >
                <iframe
                  onLoad={() => setIsIframeReady(true)}
                  src={
                    isValidUrl(data?.wireframe_link)
                      ? data?.wireframe_link +
                        "?token=" +
                        localStorage.getItem("token") +
                        "&isIframe=true"
                      : "www.google.com"
                  }
                  frameBorder="0"
                  style={{ width: "100%", height: "100%" }} // Full height & width of the Box
                ></iframe>
              </Box>
            </Adminwrapper>
          </Grid>
        </Grid>
      </CustomCardWrapper>
    </>
  );
};

export default RenderIframAudio;
