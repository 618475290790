import React from 'react'
import { useNavigate } from 'react-router-dom'

import { slideInUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';


const styles = {
    slideInUp: {
        animation: 'x 0.8s',
        animationName: Radium.keyframes(slideInUp, 'slideInLeft'),
    },

}

export default function Modal(props) {
    const navigate = useNavigate()
    return (
        <>

            <StyleRoot>
                <div className={props.open ? "full-screen-shadow concents-modal modal fade show" : "concents-modal modal fade"} style={{ display: props.open ? "block" : "none" }} id="RewardsModal">
                    <div className="modal-dialog modal-dialog1 modal-dialog-centered d-flex justify-content-center" style={styles.slideInUp}>
                        <div className="modal-content" style={{ borderRadius: '30px', width: '350px' }}>
                            <div className="modal-header">
                                <h1 style={{ width: '100%', fontSize: '17px', fontWeight: '600', marginLeft: '30px' }} className="text-center text-dark">Stickball Jobs</h1>
                                <button type="button" className="close text-dark opacity-full" data-dismiss="modal" onClick={() => {
                                    props.setOpen(false)
                                }}>
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body p-0 py-5" >
                                <div className="row d-flex justify-content-center text-success py-3">
                                    <DoneAllRoundedIcon style={{ fontSize: '70px' }} />
                                    <h5 className="text-center">We'll soon get back to you</h5>
                                </div>
                            </div>

                            <div className="modal-footer tips-modal-footer justify-content-center">

                                <button
                                    type="button"
                                    className="submit-btn"
                                    data-dismiss="modal"
                                    style={{ padding: '11px', margin: '0px' }}
                                    onClick={() => {
                                        props.setOpen(false)
                                        navigate("/success-skills-intro")
                                    }}
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </StyleRoot>
        </>
    )
}