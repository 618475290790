import React from 'react'

import { slideInUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import { userLog } from '../_api/index'
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';


const styles = {
    slideInUp: {
        animation: 'x 2s',
        animationName: Radium.keyframes(slideInUp, 'slideInLeft')
    },
}

export default function TipsModal(props) {
    let words = useSelector(state => state?.user?.words)
    const { t } = useTranslation();
    const { mutate: log } = useMutation(userLog);
    return (
        <>

            <StyleRoot>
                <div className={props.open ? "full-screen-shadow concents-modal modal fade show" : "concents-modal modal fade"} style={{ display: props.open ? "block" : "none" }} id="tipsModal">
                    <div className="modal-dialog modal-dialog-centered" style={styles.slideInUp}>
                        <div className="modal-content" style={{ borderRadius: '30px' }}>
                            <div className="modal-header">
                                <h1 style={{ width: '100%', fontSize: '17px', fontWeight: '600' }} className="text-center text-dark">{t("wordleGame.modalHead")}</h1>
                                <button type="button" className="close text-dark opacity-full" data-dismiss="modal" onClick={() => {
                                    log({
                                        pageName: 'Words Cape',
                                        buttonName: 'Close'
                                    })
                                    props.setOpen(false)
                                }}>
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body p-0" >
                                <div className="container-tips">
                                    <div className="row text-center">
                                        <span style={{ height: 'fit-content' }}>{t("wordleGame.findByClues")}</span>
                                    </div>
                                    <div className="row">
                                        <div className="char bg-dark1 dark-shadow">
                                            E
                                        </div>
                                        <div className="char bg-secondary1 secondary-shadow">
                                            A
                                        </div>
                                        <div className="char bg-primary1 primary-shadow">
                                            T
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <span>
                                            <span className="text-primary1">{t("wordleGame.blue")}: </span>{t("wordleGame.correct")} <br />
                                            <span className="text-secondary1">{t("wordleGame.orange")}: </span>{t("wordleGame.wrongPlace")}<br />
                                            <span className="text-dark1">{t("wordleGame.darkGrey")}: </span>{t("wordleGame.incorrect")} <br />
                                        </span>
                                    </div>
                                    <div className="row">
                                        <div className="char bg-primary1 primary-shadow">
                                            A
                                        </div>
                                        <div className="char bg-dark1 dark-shadow">
                                            N
                                        </div>
                                        <div className="char bg-primary1 primary-shadow">
                                            T
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <span style={{ height: 'fit-content' }}>{t("wordleGame.oneLetter")}</span>
                                    </div>
                                    <div className="row">
                                        <div className="char bg-primary1 primary-shadow">
                                            A
                                        </div>
                                        <div className="char bg-primary1 primary-shadow">
                                            R
                                        </div>
                                        <div className="char bg-primary1 primary-shadow">
                                            T
                                        </div>
                                    </div>
                                    <div className="row text-center mb-5">
                                        <span style={{ height: 'fit-content' }}>{t("wordleGame.goodLuck")}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer tips-modal-footer justify-content-center">
                                <ul className="list-group">
                                    {
                                        words?.map((word, index) => {
                                            return (
                                                word?.isCompleted && <li key={index} className="list-group-item">{word?.name}</li>
                                            )
                                        })
                                    }
                                    {
                                        words.filter(word => { return (word.isCompleted) })?.length === 0 &&
                                        <h6 className="text-center">No words completed!</h6>
                                    }
                                </ul>
                                {/* <button
                                    type="button"
                                    className="submit-btn"
                                    data-dismiss="modal"
                                    style={{ padding: '11px', margin: '0px' }}
                                    onClick={() => {
                                        log({
                                            pageName: 'Words Cape',
                                            buttonName: 'Submit Word'
                                        })
                                        props.setOpen(false)
                                    }}
                                >
                                    {t("wordleGame.btnSubmit")}
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </StyleRoot>
        </>
    )
}