import moment from "moment";

export function timeAgo(timestamp) {
    return moment(timestamp).fromNow();
  }
  
  export function formatTimestamp(timestamp) {
    const currentDate = new Date();
    const inputDate = new Date(timestamp);
  
    // Check if the date is today
    if (
      inputDate.getDate() === currentDate.getDate() &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear()
    ) {
      return `Today at ${formatTime(inputDate)}`;
    }
  
    // Check if the date is yesterday
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    if (
      inputDate.getDate() === yesterday.getDate() &&
      inputDate.getMonth() === yesterday.getMonth() &&
      inputDate.getFullYear() === yesterday.getFullYear()
    ) {
      return `Yesterday at ${formatTime(inputDate)}`;
    }
  
    // For other days, return the custom format
    const monthAbbreviation = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(inputDate);
    const dayWithSuffix = getDayWithSuffix(inputDate.getDate());
    const formattedTime = formatTime(inputDate);
  
    return `${monthAbbreviation} ${dayWithSuffix} at ${formattedTime}`;
  }
  
  function formatTime(date) {
    const options = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
    return date.toLocaleTimeString(undefined, options);
  }
  
  function getDayWithSuffix(day) {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const relevantDigits = (day < 30) ? day % 20 : day % 30;
    const suffix = (relevantDigits <= 3) ? suffixes[relevantDigits] : suffixes[0];
    return `${day}${suffix}`;
  }
  