
import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState,
    reducers: {
        handleUser: (state, action) => action.payload
    },
})

export const { handleUser } = userDetailsSlice.actions

export default userDetailsSlice.reducer
