import React, { useState } from "react";
import { Typography } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import Tooltip from '@mui/material/Tooltip';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { AttachementDisplayModal } from './AttachementDisplayModal'
import { timeAgo, formatTimestamp } from "./FormattedTime";

export default function NotificationTable({ notifications, selectedNotification, setSelectedNotification, setShowDetails }) {
    const [openAttachementModal, setOpenAttachementModal] = useState(false)

    return (
        <>
            <TableContainer>
                <Table>
                    <TableBody>
                        {notifications
                            ?.map((notification) => (
                                <TableRow
                                    key={notification.id}
                                    onClick={() => {
                                        setSelectedNotification(notification);
                                        !!notification.fileUrl
                                            ? setOpenAttachementModal(true)
                                            : setShowDetails(true);
                                    }}
                                    sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                            backgroundColor: "#f0f0f0",
                                        },
                                    }}
                                >
                                    <TableCell>
                                        <div>
                                            <div style={{ display: "flex" }}>
                                                <Typography
                                                    variant="body1"
                                                    sx={{ fontWeight: notification.status === 'unread' ? 700 : 400 }}
                                                >
                                                    {notification.message.slice(0, 25)}
                                                </Typography>
                                                {notification.fileUrl &&
                                                    (notification.fileType === "image" ? (
                                                        <ImageIcon style={{ marginLeft: "5px" }} />
                                                    ) : (
                                                        <OndemandVideoIcon style={{ marginLeft: "5px" }} />
                                                    ))}
                                            </div>
                                        </div>
                                        <div>
                                            <Tooltip title={formatTimestamp(notification.createdAt)}>
                                                <Typography variant="body2" color="textSecondary">
                                                    {timeAgo(notification.createdAt)}
                                                </Typography>
                                            </Tooltip>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {notification.status === "unread" && (
                                            <FiberManualRecordIcon
                                                style={{ color: "#00cc33" }}
                                                fontSize="small"
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer >
            <AttachementDisplayModal
                open={openAttachementModal}
                selectedNotification={selectedNotification}
                onClose={() => setOpenAttachementModal(false)}
            />
        </>
    )
}
