import * as Axios from 'axios';
const API_URL = process.env.REACT_APP_API_LINK + "/form/";

const axios = Axios.create({
    'headers': {
        'Authorization': `Stickball ${localStorage.getItem('token')}`,
        'x-api-key': process.env.REACT_APP_X_API_KEY
    }
})

const getForm = async (id) => {
    const res = await axios.get(API_URL + id, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}

const submitResponse = async (data) => {
    const res = await axios.post(API_URL + "add-response", data, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}

const analyticSubmission = async (data) => {
    const res = await axios.post(process.env.REACT_APP_API_LINK + "/analytics/new-submission", data)
    return (res.data)
}

const analyticFormSubmission = async (data) => {
    const res = await axios.post(process.env.REACT_APP_API_LINK + "/analytics/form-submission", data)
    return (res.data)
}



export { getForm, submitResponse, analyticSubmission, analyticFormSubmission }