import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography'
import { Box, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { getStudentData } from '../_api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import LogoImage from './LogoImage';

export default function WelcomeDialog({ open, setOpen, setOpenTutorials }) {
    const welcomeMessage = useSelector(state => state.user.userData?.welcomeMessage) || ""
    const user = useSelector(state => state.user.userData?.user)
    const { data } = useQuery("student-data", getStudentData)
    const isTutorial = useSelector(state => state.user.userData?.isTutorial) || false

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Please enter a valid email address.')
            .required('Please enter your email address.'),
        name: Yup.string().required("Please Enter Your Name")
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            name: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);
            let userDetials = {}

            const studentData = data?.find(student => student.student_email == values.email);
            if (studentData && user?.organizationId == 13) {
                userDetials = {
                    coach: studentData?.success_coach_or_class,
                    mentor: studentData?.mentor_name_or_teacher,
                    name: values.name
                }
            } else {
                userDetials = {
                    coach: "Dummy",
                    mentor: "Dummy",
                    name: values.name
                }
            }
            localStorage.setItem("userEmail", values.email)
            localStorage.setItem("userDetails", JSON.stringify(userDetials))
            setOpen(false);
            if (isTutorial) {
                setOpenTutorials(true)
            }
            localStorage.setItem(`hasVisitedMyPage`, 'true');
            setSubmitting(false);
        },
    });

    const handleClose = () => {
        formik.handleSubmit();
    };

    return (
        <Dialog
            open={open}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    borderRadius: "32px"
                }
            }}
        >
            <DialogContent>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "30px"
                }}>
                    <LogoImage  />
                    <img width={"50%"} src="/assets/img/welcomeillustration.png" alt="" />
                    <Typography textAlign={"center"} variant="body" color="initial">
                        {welcomeMessage}
                    </Typography>
                    <TextField
                        fullWidth
                        label="Your Name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name ? formik.errors.name : ""}

                    />
                    <TextField
                        fullWidth
                        name="email"
                        label="Your Email Address"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onKeyPress={(e) => e.key === 'Enter' && formik.handleSubmit()}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email ? formik.errors.email : ""}

                    />
                    <Button sx={{ px: 4 }} variant='contained' onClick={handleClose}>
                        Submit
                    </Button>
                </Box>

            </DialogContent>
        </Dialog>
    );
}