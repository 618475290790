import {
  ADMIN_LINK,
  checkQuilValidation,
  DATA_TYPE,
  isAdminAdd,
  isAdminEdit,
  renderPlaceholder,
} from "../../../common/contentType/contentTypeData";
import { useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Adminwrapper } from "../../../common";
import { useStyles } from "../../../../utils/styles";
import TypographyWithMore from "../../../common/contentType/TypographyWithMore";
import ReactPlayer from "react-player";
import CustomCardWrapper from "../../../common/contentType/CustomCardWrapper";
import { Box, Button, Grid, useTheme } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

const RenderTiktokHybrid = ({ content }) => {
  const { QUILL, MEDIA } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent.content,
  });

  const classes = useStyles();
  const theme = useTheme();
  const videoRef = useRef(null);

  const videoVisibility = () => {
    if (!data?.wireframe_link?.name)
      return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link;
    return URL.createObjectURL(data?.wireframe_link);
  };

  const handleFullscreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) {
        videoRef.current.mozRequestFullScreen(); // Firefox
      } else if (videoRef.current.webkitRequestFullscreen) {
        videoRef.current.webkitRequestFullscreen(); // Chrome, Safari, and Opera
      } else if (videoRef.current.msRequestFullscreen) {
        videoRef.current.msRequestFullscreen(); // IE/Edge
      }
    }
  };

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };

  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Video is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };

  return (
    <CustomCardWrapper bgColor={theme.palette.primary.extraLight}>
      {isAdminEdit() && (
        <Button
          className={classes.btnUpdateStyle}
          onClick={contentFormik.handleSubmit}
        >
          Update
        </Button>
      )}
      {isAdminAdd() && (
        <Button
          className={classes.btnAddStyle}
          onClick={contentFormik.handleSubmit}
        >
          ADD
        </Button>
      )}

      <Grid
        container
        flexDirection={{ xs: "column-reverse", md: "row" }}
        height={{ md: "100%" }}
        spacing={4}
      >
        <Grid item xs={12} md={3} my={{ xs: "1rem", md: "0rem" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            flexDirection="column"
            gap={1.5}
            height="100%"
          >
            <Adminwrapper
              errorText={contentFormik.errors.content}
              dataType={MEDIA}
              data={data}
              setData={setData}
              type="content"
              mediaType={"VIDEO"}
            >
              <div ref={videoRef}>
                <ReactPlayer
                  url={videoVisibility()}
                  playing={true}
                  loop={true}
                  controls={true}
                  muted={true}
                  width="100%"
                  height="100%"
                  style={{ aspectRatio: "9/16", borderRadius: "8px" }}
                />
              </div>
            </Adminwrapper>
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.success.main,
                p: "10px 20px",
                "&:hover": {
                  backgroundColor: theme.palette.success.main,
                  opacity: 0.8,
                },
              }}
              startIcon={<FullscreenIcon />}
              onClick={handleFullscreen}
            >
              Full Screen
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Adminwrapper
            errorText={contentFormik.errors.name}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="name"
          >
            {renderPlaceholder(data?.name, "Enter Name")}
            <TypographyWithMore
              variant="h2"
              color="primary"
              data={data?.name}
              textLength={18}
              lines={2}
            />
          </Adminwrapper>

          <Adminwrapper
            errorText={contentFormik.errors.description}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="description"
          >
            {renderPlaceholder(data?.description, "Enter Description")}
            <TypographyWithMore
              variant="body1"
              color="text"
              data={data?.description}
              textLength={180}
              lines={12}
            />
          </Adminwrapper>
        </Grid>
      </Grid>
    </CustomCardWrapper>
  );
};

export default RenderTiktokHybrid;
