import { IconButton, Typography, Box, Tooltip } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import { extractTextFromHTML } from './contentTypeData'
import { useStyles } from '../../../utils/styles'
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';

const TypographyWithMore = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { data, textLength } = props
    let text = extractTextFromHTML(data || "")

    return (
        <Box sx={{ display: "flex", position: "relative" }}>
            <Typography
                {...props}
                sx={{ whiteSpace: "pre-wrap" }}
            >
                {text?.split(" ")?.length > textLength
                    ? isExpanded
                        ? text
                        : `${text?.split(" ")?.slice(0, textLength)?.join(" ")}...`
                    : text}
                {text?.split(" ")?.length > textLength && (
                    <IconButton
                        sx={{ height: "fit-content", p: "3px", ml: 1 }}
                        color='primary'
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        {isExpanded ? <UnfoldLessRoundedIcon /> : <UnfoldMoreRoundedIcon />}
                    </IconButton>
                )}
            </Typography>
        </Box>

    )
}

export default TypographyWithMore