import * as Axios from 'axios';
const API_URL = process.env.REACT_APP_API_LINK;

const axios = Axios.create({
    headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY
    }
});

export const getNotifications = async (orgId) => {
    try {
        const res = await axios.get(`${API_URL}/notification/client`, {
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('token')}`
            }
        });
        return res.data.data;
    } catch (error) {
        console.error('Error fetching notifications:', error);
        throw error;
    }
};

