import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { Grid } from "@mui/material";
import "./dragableList.css";
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxHeight: "50px",
  },
});

export default function DraggableList({ data, handleChange }) {
  const [tasks, setTasks] = useState(data);

  useEffect(() => {
    data && setTasks(data);
  }, [data]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = [...tasks];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setTasks(items);
    handleChange(items);
  };

  const startDrag = (index) => {
    const draggableId = tasks[index].value;
    document
      .querySelector(`[data-rbd-drag-handle-draggable-id="${draggableId}"]`)
      .dispatchEvent(new MouseEvent("mousedown"));
  };

  return (
    <Grid container>
      <Grid item xs={12} lg={12}>
        <div className="draggable-list">
          {tasks.length > 1 ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="tasks">
                {(provided) => (
                  <div
                    className="task-container"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {tasks?.map((task, index) => (
                      <Draggable
                        key={task.value}
                        draggableId={task.value}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <StyledTooltip
                            title={
                            <ul style={{ marginRight: "20px", marginTop: "5px"}}>
                              {task.description.map((desc, index) => (
                                <li key={index}>{desc}</li>
                              ))}
                            </ul>}
                            arrow
                          >
                          <div
                            className={`task ${
                              snapshot.isDragging ? "dragging" : ""
                            }`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={() => startDrag(index)}
                          >
                            <div className="task-content">
                              <DragIndicatorOutlinedIcon />
                              {task.label}
                            </div>
                          </div>
                          </StyledTooltip>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : null}
        </div>
      </Grid>
    </Grid>
  );
}
