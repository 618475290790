import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import { Link, useNavigate } from 'react-router-dom'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next'
import isAuthenticated from '../auth/auth'
import Header from '../common/NewHeader';

import { useMutation } from 'react-query';
import { userLog } from '../_api/index'

export default function LearnMore() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { mutate: log } = useMutation(userLog);
    const [index, setIndex] = useState(0)

    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    // if (isMobile) {
    //     return (<TurnPhoneH content="Turn your phone to side for privacy policy" />)
    // } else
        return (
            <>{isAuthenticated(localStorage.getItem("token")) && navigate("/module")}
                 <Header logo page="Success Skill Intro" />
                <div className="check-code" style={{ height: '100vh' }}>
                    <div className="container splash-screen-container">
                        <div className="container-inner">
                            <div className="content row">
                                <Carousel showArrows={true} showThumbs={false} showStatus={false} onChange={(item) => { setIndex(item) }}>
                                    <div className="main">
                                        <img src="../assets/svg/new-screen2/screen2.svg" alt="" style={{ width: '300px' }} />
                                        <h1 style={{ marginTop: '6px' }} className="learnmoreheading">{t('learnMore.beforeBr1')}
                                            <br />{t('learnMore.afterBr1')}</h1>
                                    </div>
                                    <div className="main ">
                                        <img src="../assets/svg/new-screen2/screen3.svg" alt="" style={{ width: '300px' }} />
                                        <h1 className="learnmoreheading" style={{ marginTop: '6px' }}>{t('learnMore.2')}</h1>
                                    </div>
                                    <div className="main ">
                                        <img src="../assets/svg/new-screen2/screen4.svg" alt="" style={{ width: '300px' }} />
                                        <h1 className="learnmoreheading" style={{ marginTop: '-4px' }}>{t('learnMore.3')}</h1>
                                    </div>
                                </Carousel>
                                {
                                    index === 2 &&
                                    <div className="buttons" >
                                        <Link to="/get-started">
                                            <button className="get-started-button"
                                                onClick={() => log(
                                                    {
                                                        pageName: 'Learn More',
                                                        buttonName: 'Go'
                                                    })}
                                            >{t('learnMore.btnGo')}!</button>
                                        </Link>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
}
