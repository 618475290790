import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import isAuthenticated from "../auth/auth";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { LoadingDots } from "./dots-loading";
import { getPlannerList } from "../_api/planner";
import moment from "moment";

import { EventsOfDayModal } from "./modals";
import { YearlyCalendar } from "./yearly-calendar";
import { WeeklyCalendar } from "./weekly-calendar";
import { DailyCalendar } from "./daily-calendar";

// import { getPlannerList } from '../../../_api/planner'
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const calanderTypes = ["Daily", "Weekly", "Monthly", "Yearly"];
const Planner = () => {
  const navigate = useNavigate();
  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const [currentDate, setCurrentDate] = useState(moment());
  var startDayOfWeek = moment(currentDate).startOf("month").day();
  var daysInMonth = moment(currentDate).daysInMonth();
  const [dropedItems, setDropedItems] = useState([]);
  // [
  //     {
  //         "id": "6454aa456341af00129c6134",
  //         "start_date": "2023-05-16T07:02:33.542Z",
  //         "end_date": "2023-05-19T07:02:33.000Z",
  //         "type": "Assignment",
  //         "event_name": "iframe test",
  //         "event_description": "No Description to show",
  //         "event_color": "#FFB746",
  //         "organization_id": "642fcd49a54c123d070c827b",
  //         "type_object": "642fe8e973c1850011634519",
  //         "__v": 0
  //     },
  //     {
  //         "id": "6454aa9b6341af00129c6164",
  //         "start_date": "2023-05-26T07:02:33.000Z",
  //         "end_date": "2023-05-29T07:02:33.000Z",
  //         "type": "Other",
  //         "event_name": "testing event",
  //         "event_description": "fsdfad",
  //         "event_color": "#170101",
  //         "organization_id": "642fcd49a54c123d070c827b",
  //         "__v": 0
  //     },
  //     {
  //         "id": "6457eb9398e9be0011f754ac",
  //         "start_date": "2023-08-09T18:05:38.658Z",
  //         "end_date": "2023-08-18T18:05:38.000Z",
  //         "type": "Skills",
  //         "event_name": "Check Your Understanding",
  //         "event_description": "No Description to show",
  //         "event_color": "#5048E5",
  //         "organization_id": "642fcd49a54c123d070c827b",
  //         "type_object": "642fde30a06822742ad016ae",
  //         "__v": 0
  //     },
  //     {
  //         "id": "6457ebb298e9be0011f754ba",
  //         "start_date": "2023-06-07T18:05:38.658Z",
  //         "end_date": "2023-06-10T18:05:38.000Z",
  //         "type": "Boast",
  //         "event_name": "testing sami",
  //         "event_description": "No Description to show",
  //         "event_color": "#10B981",
  //         "organization_id": "642fcd49a54c123d070c827b",
  //         "type_object": "6438f52c5425e10012379b65",
  //         "__v": 0
  //     },
  //     {
  //         "id": "6458fb03c682ff00120dcde4",
  //         "start_date": "2023-05-10T13:35:30.682Z",
  //         "end_date": "2023-05-12T13:35:30.000Z",
  //         "type": "Boast",
  //         "event_name": "skill name",
  //         "event_description": "No Description to show",
  //         "event_color": "#10B981",
  //         "organization_id": "642fcd49a54c123d070c827b",
  //         "type_object": "64366ffb5425e10012376062",
  //         "__v": 0
  //     },
  //     {
  //         "id": "6458fb38c682ff00120dcdf2",
  //         "start_date": "2023-05-26T13:35:30.682Z",
  //         "end_date": "2023-05-28T13:35:30.000Z",
  //         "type": "Assignment",
  //         "event_name": "skill assignment test",
  //         "event_description": "No Description to show",
  //         "event_color": "#FFB746",
  //         "organization_id": "642fcd49a54c123d070c827b",
  //         "type_object": "643581b05425e100123750af",
  //         "__v": 0
  //     },
  //     {
  //         "id": "64590095c682ff00120dcf2c",
  //         "start_date": "2023-05-08T13:55:59.907Z",
  //         "end_date": "2023-05-11T13:55:59.000Z",
  //         "type": "Assignment",
  //         "event_name": "test",
  //         "event_description": "No Description to show",
  //         "event_color": "#FFB746",
  //         "organization_id": "642fcd49a54c123d070c827b",
  //         "type_object": "6433c3e573c18500116361de",
  //         "__v": 0
  //     }
  // ])
  const [openCurrentEvents, setOpenCurrentEvents] = useState(false);
  const [dayClicked, setDayClicked] = useState(null);
  const [currentMonthEvents, setCurrentMonthEvents] = useState([]);
  const [openClearModal, setOpenClearModal] = useState(false);
  const [openEmptyModal, setOpenEmptyModal] = useState(false);
  const [message, setMessage] = useState("");
  const [calanderType, setCalanderType] = useState("Monthly");
  const [startOfWeek, setStartOfWeek] = useState(
    moment(currentDate).startOf("week")
  );
  const [endOfWeek, setEndOfWeek] = useState(moment(currentDate).endOf("week"));
  const [today, setToday] = useState(moment());
  const [year, setYear] = useState(moment());

  const handlePrevWeek = () => {
    const prevWeekStart = moment(startOfWeek)
      .subtract(1, "week")
      .startOf("week");
    const prevWeekEnd = moment(endOfWeek).subtract(1, "week").endOf("week");
    setStartOfWeek(prevWeekStart);
    setEndOfWeek(prevWeekEnd);
  };

  const handleNextWeek = () => {
    const nextWeekStart = moment(startOfWeek).add(1, "week").startOf("week");
    const nextWeekEnd = moment(endOfWeek).add(1, "week").endOf("week");
    setStartOfWeek(nextWeekStart);
    setEndOfWeek(nextWeekEnd);
  };

  const { isLoading, data: planData } = useQuery(
    "get-planner-list",
    getPlannerList
  );

  useEffect(() => {
    if (!isLoading) {
      setDropedItems(planData?.events);
    }
  }, [planData, isLoading]);

  useEffect(() => {
    startDayOfWeek = moment(currentDate).startOf("month").day();
    daysInMonth = moment(currentDate).daysInMonth();
  }, [currentDate, planData]);

  const filteredDropedItems = (array, dayIndex) => {
    let filteredArray = array?.filter(
      (item) =>
        moment(item.start_date).isSame(dayIndex, "day") ||
        (moment(item.start_date).isBefore(dayIndex, "day") &&
          moment(item?.end_date).isAfter(dayIndex, "day")) ||
        moment(item?.end_date).isSame(dayIndex, "day")
    );

    let sortedArray = filteredArray?.sort(
      (a, b) => moment(a.start_data) - moment(b.start_date)
    );
    return sortedArray;
  };

  const Event = ({
    event_color,
    start_date,
    end_date,
    id,
    event_name: name,
    index,
    position,
  }) => {
    if (moment(start_date).isSame(moment(currentDate).date(index + 1), "day")) {
      return (
        <Box
          sx={{
            ...eventStyle,
            background: event_color,
            top: `${position * 35 + 37}px`,
          }}
          // onClick={() => setSelectedEvent({ color, start_date, end_date, id, name, index, position })}
        >
          <div style={{ width: "16vw" }}>{name.slice(0, 20)}</div>
        </Box>
      );
    }
    if (
      end_date &&
      moment(start_date).isBefore(currentDate.date(index + 1)) &&
      (moment(end_date)?.isSame(moment(currentDate).date(index + 1), "day") ||
        moment(end_date)?.isAfter(currentDate.date(index + 1)))
    ) {
      return (
        <Box
          sx={{
            ...eventStyle,
            background: event_color,
            top: `${position * 35 + 37}px`,
          }}
        >
          {name.slice(0, 20)}
        </Box>
      );
    }

    return null;
  };
  if (isLoading) return <LoadingDots />;
  return (
    <>
      {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
      <Grid container>
        <Grid item xs={12} sx={{ pt: 1 }}>
          <Grid sx={{ mx: 2, my: 4 }}>
            <Button
              to="/module"
              className="mybtn customizedbtn"
              onClick={() => window.history.go(-1)}
              style={{
                background: "transparent",
                marginBottom: "30px",
                padding: "10px 25px",
                borderRadius: "15px",
                textTransform: "capitalize",
                color: "#616ded",
              }}
            >
              <img
                src="assets/img/arrowback.png"
                style={{ paddingRight: ".5rem" }}
              />
              Back
            </Button>
            <Grid
              container
              sx={{
                px: 3,
                my: 4,
                display: "flex",
                flexDirection:{xs:"column",md:'row'},
                justifyContent: {xs:"flex-start",md:"space-between"},
                alignItems:{xs:'center',md:"flex-start"}
              }}
            >
              <Grid item sm={8} xs={12} mb={'1rem'}>
                {calanderType === "Monthly" ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: "1rem",
                    }}
                  >
                    <IconButton
                      onClick={() =>
                        setCurrentDate(moment(currentDate).subtract(1, "month"))
                      }
                    >
                      <ArrowBackIosNewIcon
                        sx={{ height: "25px", width: "25px" }}
                      />
                    </IconButton>
                    <Typography
                      variant="subtitle"
                      sx={{
                        mx: { xs: "10px", md: "50px" },
                        fontSize: { xs: "16px", md: "24px" },
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {moment(currentDate).format("MMMM")}
                      </span>{" "}
                      {moment(currentDate).format("YYYY")}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        setCurrentDate(moment(currentDate).add(1, "month"))
                      }
                    >
                      <ArrowForwardIosIcon
                        sx={{ height: "25px", width: "25px" }}
                      />
                    </IconButton>
                  </Box>
                ) : calanderType === "Daily" ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      onClick={() => setToday(moment(today).subtract(1, "day"))}
                    >
                      <ArrowBackIosNewIcon
                        sx={{ height: "25px", width: "25px" }}
                      />
                    </IconButton>
                    <Grid align="center">
                      <Typography
                        variant="subtitle"
                        sx={{
                          mx: { xs: "10px", md: "50px" },
                          fontSize: { xs: "16px", md: "24px" },
                          fontWeight: "bold",
                        }}
                      >
                        {today.format("MMM  DD")}
                      </Typography>
                    </Grid>
                    <IconButton
                      onClick={() => setToday(moment(today).add(1, "day"))}
                    >
                      <ArrowForwardIosIcon
                        sx={{ height: "25px", width: "25px" }}
                      />
                    </IconButton>
                  </Box>
                ) : calanderType === "Weekly" ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton onClick={() => handlePrevWeek()}>
                      <ArrowBackIosNewIcon
                        sx={{ height: "25px", width: "25px" }}
                      />
                    </IconButton>
                    <Typography
                      variant="subtitle"
                      sx={{
                        mx: { xs: "10px", md: "50px" },
                        fontSize: { xs: "16px", md: "24px" },
                        fontWeight: "bold",
                      }}
                    >{`${startOfWeek.format("MMM D")} - ${endOfWeek.format(
                      "MMM D"
                    )}`}</Typography>
                    <IconButton onClick={() => handleNextWeek()}>
                      <ArrowForwardIosIcon
                        sx={{ height: "25px", width: "25px" }}
                      />
                    </IconButton>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      onClick={() => setYear(moment(year).subtract(1, "year"))}
                    >
                      <ArrowBackIosNewIcon
                        sx={{ height: "25px", width: "25px" }}
                      />
                    </IconButton>
                    <Typography
                      variant="subtitle"
                      sx={{
                        mx: { xs: "10px", md: "50px" },
                        fontSize: { xs: "16px", md: "24px" },
                        fontWeight: "bold",
                      }}
                    >
                      {moment(year).format("YYYY")}
                    </Typography>
                    <IconButton
                      onClick={() => setYear(moment(year).add(1, "year"))}
                    >
                      <ArrowForwardIosIcon
                        sx={{ height: "25px", width: "25px" }}
                      />
                    </IconButton>
                  </Box>
                )}
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  xs: { px: 3 },
                }}
              >
                <TextField
                  sx={{ minWidth: 200 }}
                  select
                  label="Calendar"
                  variant="outlined"
                  value={calanderType}
                  onChange={(e) => setCalanderType(e.target.value)}
                >
                  {calanderTypes.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
          </Grid>
          {calanderType === "Yearly" ? (
            <YearlyCalendar
              year={moment(year).format("YYYY")}
              activeDateStyle={activeDateStyle}
              filteredDropedItems={filteredDropedItems}
              dropedItems={dropedItems}
              skillCardStyle={skillCardStyle}
              openedCardDetails={openedCardDetails}
            />
          ) : calanderType === "Weekly" ? (
            <WeeklyCalendar
              startOfWeek={startOfWeek}
              endOfWeek={endOfWeek}
              filteredDropedItems={filteredDropedItems}
              dropedItems={dropedItems}
              eventStyle={eventStyle}
              skillCardStyle={skillCardStyle}
              openedCardDetails={openedCardDetails}
            />
          ) : calanderType === "Daily" ? (
            <DailyCalendar
              currentDate={today}
              filteredDropedItems={filteredDropedItems}
              dropedItems={dropedItems}
              eventStyle={eventStyle}
              skillCardStyle={skillCardStyle}
              openedCardDetails={openedCardDetails}
            />
          ) : (
            <Grid container>
              {daysOfWeek.map((day) => (
                <Grid item xs={1.71} key={day} sx={dayBoxStyle}>
                  <Typography align="center" sx={dayStyle}>
                    {day}
                  </Typography>
                </Grid>
              ))}
              {Array.from(Array(startDayOfWeek)).map((_, index) => (
                <Grid
                  item
                  xs={1.71}
                  key={index}
                  sx={{ ...boxStyle, opacity: 0.5 }}
                >
                  {" "}
                </Grid>
              ))}
              {Array.from(Array(daysInMonth)).map((_, index) => (
                <Grid
                  item
                  xs={1.71}
                  key={index}
                  sx={{ ...boxStyle, backgroundColor: "#fff" }}
                  onClick={() => {
                    setDayClicked(index);
                    setOpenCurrentEvents(true);
                  }}
                >
                  <Typography sx={{ display: "flex", justifyContent: "end" }}>
                    <span
                      style={{
                        background: moment(currentDate)
                          .date(index + 1)
                          .isSame(moment(), "day")
                          ? "#616ded"
                          : "transparent",
                        color: moment(currentDate)
                          .date(index + 1)
                          .isSame(moment(), "day")
                          ? "#fff"
                          : "rgba(55, 65, 81, 0.60)",
                        ...activeDateStyle,
                      }}
                    >
                      {index + 1}
                    </span>
                  </Typography>
                  {filteredDropedItems(dropedItems, currentDate.date(index + 1))
                    ?.slice(0, 3)
                    ?.map((elem, pstn) => {
                      return (
                        <div
                        // onClick={(e) => { setSelectedEvent(elem); setOpenEvent(true) }}
                        >
                          <Event {...elem} index={index} position={pstn} />
                        </div>
                      );
                    })}
                  {filteredDropedItems(dropedItems, currentDate.date(index + 1))
                    ?.length > 3 && (
                    <MoreHorizIcon
                      style={{ position: "absolute", bottom: 0, right: 0 }}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        {!!filteredDropedItems(dropedItems, currentDate.date(dayClicked + 1))
          ?.length && (
          <EventsOfDayModal
            openCurrentEvents={openCurrentEvents}
            setOpenCurrentEvents={setOpenCurrentEvents}
            events={filteredDropedItems(
              dropedItems,
              currentDate.date(dayClicked + 1)
            )}
            skillCardStyle={skillCardStyle}
            openedCardDetails={openedCardDetails}
          />
        )}
      </Grid>
    </>
  );
};

export default Planner;

const boxStyle = {
  border: "1px solid rgba(0, 0, 0, 0.12)",
  minHeight: "200px",
  maxHeight: "200px",
  backgroundColor: "#FFF",
  display: "flex",
  flexDirection: "column",
  padding: "12px",
  position: "relative",
  overflow: "hidden",
  cursor: "pointer",
};
const dayBoxStyle = {
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
  backgroundColor: "#FFF",
  height: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const dayStyle = {
  color: "#111827",
  fontSize: "12px",
  fontWeight: 600,
};

const skillCardStyle = {
  my: "5px",
  p: "12px",
  borderRadius: "6px",
  borderLeft: "6px solid",
  background: "#FFF",
  boxShadow: "0px 0px 2px 1px rgba(216, 215, 215, 0.6)",
};
const eventStyle = {
  cursor: "pointer",
  p: 1,
  height: "30px",
  color: "#FFF",
  display: "flex",
  alignItems: "center",
  zIndex: 1,
  position: "absolute",
  left: 0,
  width: "100%",
  opacity: 0.8,
};
const openedCardDetails = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
const activeDateStyle = {
  width: "25px",
  display: "flex",
  justifyContent: "center",
  borderRadius: "50%",
};
