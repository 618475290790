import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import { Link } from 'react-router-dom'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import emailjs from '@emailjs/browser'
import toast, { Toaster } from 'react-hot-toast'

export default function CustomerSupport() {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [category, setCategory] = useState("Technical")
    const [message, setMessage] = useState("")
    const serviceID = "service_t8vp2fl"
    const templateID = "template_y1vk708"
    const publicKey = "-pmIAWMrxNp9zg1Qj"


    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);


    const handleSubmit = (e) => {
        e.preventDefault()
        toast.loading("Sending...")
        let header = {
            name: name,
            email: email,
            category: category,
            message: message,
            toEmail: category === "Technical" ? "support@stickball.biz" : "info@stickball.biz"
        }
        // if (category === "Technical") {
        emailjs.send(serviceID, templateID, header, publicKey).then(() => {
            toast.dismiss()
            toast.success("Message Sent")
            setTimeout(() => {
                window.history.go(-1)
            }, 1000)
        })
        // } else {

        // }
    }


    // if (isMobile) {
    //     return (<TurnPhoneH content="Turn your phone to side for privacy policy" />)
    // } else
        return (
            <>

                <Toaster />
                <div className="back back2 get-started-code-back" style={{
                    position: 'fixed',
                    width: '100%',
                    zIndex: 1000, top: '20px',
                    left: 0,
                }}>
                    <div className="back-wrapper">
                        <a
                            // to={props?.backLink}
                            style={{ padding: '7px 8px', marginLeft: '50px' }}
                            className="text-primary1 bg-light btn-back"
                            onClick={(e) => window.history.go(-1)}>
                            <ArrowBackIosRoundedIcon style={{ fontSize: '25px' }} />
                        </a>
                    </div>
                </div>
                <header className="header-bg">
                    <h1>
                        Help Desk
                    </h1>
                </header>

                <div className="container cs-container p-4">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="form-group">
                            <label for="exampleFormControlInput11">Enter Your Name</label>
                            <input onChange={(e) => setName(e.target.value)} type="text" className="form-control" id="exampleFormControlInput11" placeholder="Name" required />
                        </div>
                        <div className="form-group mt-4">
                            <label for="exampleFormControlInput1">Email address</label>
                            <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" required />
                        </div>
                        <div className="form-group mt-4">
                            <label for="exampleFormControlSelect1">Category</label>
                            <select className="form-control" id="exampleFormControlSelect1" onChange={(e) => setCategory(e.target.value)}>
                                <option value="Technical">Technical</option>
                                <option value="General">General</option>
                            </select>
                        </div>
                        <div className="form-group mt-4">
                            <label for="exampleFormControlTextarea1">Message</label>
                            <textarea onChange={(e) => setMessage(e.target.value)} className="form-control" id="exampleFormControlTextarea1" placeholder="Enter your Message" rows="4" required></textarea>
                        </div>

                        <button
                            type="submit"
                            className="submit-btn mt-4"
                            data-dismiss="modal"
                            style={{ padding: '11px', margin: '0px', borderRadius: '10.4px' }}
                        // onClick={() => {
                        //     props.setOpen(false)
                        // }}
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </>
        )
}
