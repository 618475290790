export default function TurnPhoneH(props) {
    return (
        <>
            <div className="body-bg" style={{ position: 'relative', height: '100vh' }}>
                <div className="container turn-screen-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="container-inner mt-0 pt-0">
                        {/* <img className="img-logo" src="/assets/svg/logo-white.gif" alt="" /> */}
                        <img className="turn-img m-0" src="/assets/img/turn.png" alt="" />
                        <h1 style={{ marginTop: '28px', width: '300px' }}>{props?.content}</h1>
                    </div>
                </div>
            </div>
        </>
    )
}