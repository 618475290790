import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    jobData: [],
    dataFetch: false,
}

export const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {
        setJobData: (state, action) => {
            state.jobData = action.payload
            state.dataFetch = false
        },
        setDataFetch: (state, action) => {
            state.dataFetch = action.payload
        },
    },
})

export const { setJobData, setDataFetch } = jobSlice.actions

export default jobSlice.reducer