import Header from '../common/NewHeader';
import {Grid,Button} from "@mui/material"
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Video  = () => {
    const register = useSelector(state => state.user.register)
    const navigate = useNavigate()
    return (
        <>
            <Grid container justifyContent="center" alignItems="center" direction="column" width="856px" sx={{margin: 'auto'}}>
                <Grid item>
                    <Header back logo />
                </Grid>
                <Grid item mt={10}>
                    <video width="100%" height="100%" autoPlay controls onEnded={()=>{
                        navigate('/module')
                    }}>
                        <source src="https://stickball.s3.us-east-1.amazonaws.com/intro-updated.m4v" type="video/mp4" />
                    </video>
                </Grid>
                <Grid item container justifyContent="flex-end">
                    <Link to="/module">          
                        {
                            !register && 
                            <Button variant="contained">
                                Skip The Video
                            </Button>
                        }    
                    </Link>
                </Grid>
            </Grid>
        </>
    )
}

export default Video