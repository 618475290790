import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import Header from '../common/NewHeader'
import { Link } from 'react-router-dom'
import { userLog } from '../_api/index'
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Grid, Typography, Box } from "@mui/material";

export default function SuccessSkillList(props) {
    const { t } = useTranslation();
    const { mutate: log } = useMutation(userLog);
    const { selectedSkills, desiredJob, jobs, currentJob } = props
    const [filteredJobs, setFilteredJobs] = useState([])
    const [isMobile, setIsMobile] = useState(false);
    const [openedJob, setOpenedJob] = useState({})
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = useState(false)
    const [openedJob2, setOpenedJob2] = useState([])
    const handleClickOpen = (job) => {
        setOpenedJob(job)
        setOpen(true);
    };
    const handleClickOpen2 = (job) => {
        setOpenedJob2(job)
        setOpen(false);
        setOpen2(true)
    };
    const handleClose = (value) => {
        setOpen(false);
    };
    const handleClose2 = (value) => {
        setOpen2(false);
    };
    useEffect(() => {
    }, [openedJob])
    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    useEffect(() => {
        let temparray = []
        jobs?.map(job => {
            let point = 0;
            selectedSkills?.map(selectedSkill => {
                return (
                    job?.skills?.map(skill => {
                        if (skill.id == selectedSkill.id) {
                            point = point + 1
                            return (0)
                        }
                    })
                )
            })
            let temp = {
                id: job.id,
                point: point,
                name: job.name,
                description: job.description,
            }
            temparray.push(temp)
        })

        setFilteredJobs(temparray)
    }, [])



    function SimpleDialog(props) {
        const { onClose, open } = props;

        const handleClose = () => {
            onClose();
        };

        const handleListItemClick = (value) => {
            onClose(value);
        };

        return (
            <Dialog onClose={handleClose} open={open}>

                <Grid container p={3}>
                    <Grid item container direction="column">
                        <Typography variant="h6" sx={{ lineHeight: '30px', fontSize: '20px', fontWeight: '700', background: "#FAFBFF", color: "#616DED", padding: "1rem", width: "100%", borderRadius: "1rem" }}>{openedJob.name}</Typography>
                        <Typography variant="body1" sx={{ padding: "1rem 0" }}>{openedJob.description}</Typography>
                    </Grid>
                    <Grid item container direction="column">
                        <Typography variant="h6" sx={{ color: "#616DED" }}>Skills</Typography>
                        <Grid container justifyContent="space-between" sx={{ flexWrap: 'wrap', gap: "0.5rem" }}>

                            {
                                openedJob?.skills?.length > 0 ?
                                    openedJob?.skills?.map((item) => (
                                        <Box sx={{ background: "#F0F1FF", color: "#616DED", borderRadius: "1rem" }} py={2} px={3}>
                                            {item}
                                        </Box>
                                    ))
                                    :
                                    <h3>No Skill Found</h3>
                           }
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" sx={{ background: "#FAFBFF", width: "100%", borderRadius: "1rem" }} py={2} px={2} mt={2}>
                        <Button sx={{ color: "#616DED" }} onClick={() => handleClickOpen2()} >Request Learning Roadmap</Button>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }



    function SimpleDialog2(props) {
        const { onClose, open } = props;

        const handleClose = () => {
            onClose();
        };

        const handleListItemClick = (value) => {
            onClose(value);
        };

        return (
            <Dialog onClose={handleClose} open={open}>
                <Grid container p={3}>
                    <Grid item container direction="column">
                        <Typography variant="h6" sx={{ background: "#FAFBFF", color: "#616DED", padding: "1rem", width: "100%", borderRadius: "1rem" }}>{openedJob?.name}</Typography>
                    </Grid>
                    <Grid item xs={12} mt={5} container justifyContent="center">
                        <img src="assets/img/jobcomplete.png" />
                    </Grid>
                    <Grid item xs={12} mt={5} container justifyContent="center">
                        <p>we’ll get back to you soon</p>
                    </Grid>
                    <Grid container justifyContent="center" sx={{ background: "#FAFBFF", width: "100%", borderRadius: "1rem" }} py={2} px={2} mt={2}>
                        <Button sx={{ color: "#616DED" }} onClick={handleClose2}>OK</Button>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }



    function highToLow(data) {
        for (let i = 0; i < data.length; i++) {
            let temp = {};
            for (let j = 0; j < data.length - 1; j++) {
                if (data[j].point <= data[j + 1].point) {
                    temp = data[j];
                    data[j] = data[j + 1];
                    data[j + 1] = temp;
                }
            }

        }
        return (data);
    }

    // if (isMobile) {
    //     return (<TurnPhoneH content={t("successSkillsIntro.turn")} />)
    // } else
        return (
            <>
                <Header logo page="Success Skill List" />


                <SimpleDialog
                    open={open}
                    onClose={handleClose}
                />

                <SimpleDialog2
                    open={open2}
                    onClose={handleClose2}
                />

                <div className="container-success-skills container mt-5 pt-5">
                    <h3 className="success_skills_jobs_result">{t("successSkillsList.recomendedJob")}</h3>
                    {/* <div className="row px-1" style={{ gap: 10 }}>
                        {
                            jobs?.map((job, index) => {
                                return (
                                    job?.id == desiredJob &&
                                    <div key={index} className="col-6 col-md-3 p-0">
                                        <div className="lesson-details">
                                            <div className="header">
                                                <button className="btn-lesson-header text-primary">
                                                    Business
                                                </button>
                                                <div className="img-section">
                                                    <img src="../assets/svg/lesson/calculator.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="content-lesson row mx-0">
                                                <h4>{job.name}</h4>
                                                <p>
                                                    {job.description}
                                                </p>
                                                <Link className="btn-learn-more" to={"/success-skill-details/" + job.id}
                                                    onClick={() => log({
                                                        pageName: 'Success Skill List',
                                                        buttonName: 'Learn More'
                                                    })}
                                                >{t("successSkillsList.btnLearn")}</Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        {
                            highToLow(filteredJobs)?.slice(0, 5).map((job, index) => {
                                return (
                                    currentJob !== job.id &&
                                    <div key={index} className="col-6 col-md-3 p-0">
                                        <div className="lesson-details">
                                            <div className="header">
                                                <button className="btn-lesson-header text-primary">
                                                    Finance
                                                </button>
                                                <div className="img-section">
                                                    <img src="../assets/svg/lesson/calculator1.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="content-lesson row mx-0">
                                                <h4>{job.name}</h4>
                                                <p>
                                                    {job.description}
                                                </p>
                                                <Link className="btn-learn-more" to={"/success-skill-details/" + job.id}
                                                    onClick={() => log({
                                                        pageName: 'Success Skill List',
                                                        buttonName: 'Learn More'
                                                    })}
                                                >{t("successSkillsList.btnLearn")}</Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div> */}


                    <div className="box-wraper" style={{ gap: 10 }}>
                        {
                            jobs?.map((job, index) => {
                                return (
                                    job?.id == desiredJob &&
                                    <div key={index} className=" box p-0">
                                        <div className="lesson-details">
                                            <div className="header">
                                                <h3
                                                    className="title">
                                                    {job?.name ? job?.name : 'dummy'}
                                                </h3>

                                            </div>
                                            <div className="content-lesson row mx-0">
                                                <h4 classname="mt-1">{job.name}</h4>
                                                <p>
                                                    {job.description}
                                                </p>
                                                <Button
                                                    style={{ borderRadius: '10.4px' }} className="btn-learn-more"
                                                    onClick={() => handleClickOpen(job)}
                                                // onClick={() => log({
                                                //     pageName: 'Success Skill List',
                                                //     buttonName: 'Learn More'
                                                // })}
                                                >{t("successSkillsList.btnLearn")}</Button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        {
                            highToLow(filteredJobs)?.slice(0, 6).map((job, index) => {
                                return (
                                    currentJob !== job.id &&
                                    <div key={index} className="box p-0">
                                        <div className="lesson-details">
                                            <div className="header">
                                                <h3
                                                    className="title">
                                                    {job?.name ? job?.name : 'dummy'}
                                                </h3>

                                            </div>
                                            <div className="content-lesson row mx-0">
                                                <h4 classname="mt-1">{job.name}</h4>
                                                <p>
                                                    {job.description}
                                                </p>
                                                <Button
                                                    style={{ borderRadius: '10.4px' }} className="btn-learn-more"
                                                    onClick={() => handleClickOpen(job)}
                                                // onClick={() => log({
                                                //     pageName: 'Success Skill List',
                                                //     buttonName: 'Learn More'
                                                // })}
                                                >{t("successSkillsList.btnLearn")}</Button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>


                    <div className="row">
                        <div className="col-12">
                            <div className="zoom-section">
                                {
                                    window.innerWidth < 1000 ?
                                        <p>
                                            {t("successSkillsList.meet1")} <br /> {t("successSkillsList.meet2")}
                                        </p>
                                        :
                                        <p>
                                            {t("successSkillsList.meet1")} {t("successSkillsList.meet2")}
                                        </p>
                                }

                                {
                                    window.innerWidth < 1000 ? <h1>|</h1> : ""
                                }

                                <img src="../assets/svg/lesson/zoom.svg" alt="" />
                            </div>
                        </div>
                    </div>


                    {
                        highToLow(filteredJobs).length > 0 &&
                        (
                            <>
                                <h3 className="success_skills_jobs_result">{t("successSkillsList.otherJob")}</h3>
                                <div className="box-wraper px-2">
                                    {
                                        highToLow(filteredJobs)?.slice(6).map((job, index) => {
                                            return (
                                                currentJob !== job.id &&
                                                <>
                                                    <div key={index} className="box p-0">
                                                        <div className="lesson-details">
                                                            <div className="header">
                                                                <h3
                                                                    className="title">
                                                                    {job?.name ? job?.name : 'dummy'}
                                                                </h3>
                                                            </div>
                                                            <div className="content-lesson row mx-0">
                                                                <h4 classname="mt-1">{job.name}</h4>
                                                                <p>
                                                                    {job.description}
                                                                </p>
                                                                <Button
                                                                    style={{ borderRadius: '10.4px' }} className="btn-learn-more"
                                                                    onClick={() => handleClickOpen2(job)}
                                                                // onClick={() => log({
                                                                //     pageName: 'Success Skill List',
                                                                //     buttonName: 'Learn More'
                                                                // })}
                                                                >{t("successSkillsList.btnLearn")}</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }

                                </div>
                            </>
                        )
                    }
                </div>
            </>
        )
}
