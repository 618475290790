import React, { useState, useEffect } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export default function Popup() {
    const location = useLocation();
    const { t } = useTranslation()
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (location.pathname == "/module") {
            setOpen(true)
        }
    }, [location.pathname])
    if (!open) return <></>
    return (
        <Box
            sx={alertStyle}>
            <Box sx={{ borderRadius: "15px 15px 0px 0px", background: theme => theme.palette.secondary.light, p: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "10px", mb: 1 }}>
                    <WarningAmberIcon color="warning" />
                    <Typography variant='body1' color={"initial"} fontWeight={600}>{t("beforeYouBegin")}</Typography>
                </Box>
                <Typography sx={{ fontSize: "12px" }} color="initial" textAlign="left">
                    {t("popupText1")}
                </Typography>
                <Typography sx={{ fontSize: "12px", mt: 1 }} color="initial" textAlign="left">
                    {t("popupText2")}
                </Typography>
            </Box>
            <Button variant='contained' color='secondary' sx={{ textTransform: "capitalize", width: '100%', borderRadius: "0px 0px 15px 15px",background: (theme)=> theme.palette.secondary.main }} onClick={handleClose}>{t("ok")}</Button>
        </Box>
    );
}

const alertStyle = {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    right: { sm: 10, xs: 3 },
    bottom: { sm: 10, xs: 3 },
    left: { sm: "auto", xs: 3 },
    maxWidth: "400px",
    zIndex: 9999,
    backgroundColor: "#fff",
    boxShadow: "0 0 5px 1px rgba(0, 0, 0, 0.1)",
    padding: 0,
    borderRadius: "15px",
}