import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../common/NewHeader';

import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useMutation } from 'react-query';
import { changePassword, userLog } from '../_api/index'
import { useTranslation } from 'react-i18next'

import { Grid, Button, FormControl } from "@mui/material"
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';



export default function ChangePassword() {
    const { t } = useTranslation();
    const { mutate: log } = useMutation(userLog);
    const navigate = useNavigate()
    const location = useLocation()
    const email = location?.state?.email
    const [serverError, setServerError] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [currentPassword, setCurrentPassword] = useState("")
    const [passSuccess, setPassSuccess] = useState(false)

    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const { isLoading: passLoading, isSuccess: success, mutate: changePass } = useMutation(changePassword, {
        onSuccess: (response) => {
            setServerError(false)
            setMessage(`Password Changed succesfully !`)
            setPassSuccess(true)
            setTimeout(() => {
            navigate(-1)
            },1500);

        },
        onError: (error) => {
            setServerError(true)
            if (error.message === "Request failed with status code 404") {
                setMessage("Invalid Password")
            }else if (error.message === "Email is required"){
                setMessage("Email is not provided")
            } else {
                setMessage("Internal error, sorry for inconvenience, please try again!")
            }
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault()
            if (!error) {
                let data = {
                    email: email,
                    password: currentPassword,
                    newpassword: password,
                }
                changePass(data)
            }
    }

    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    // if (isMobile) {
    //     return (<TurnPhoneH content="Turn your phone to side to reset your password" />)
    // } else
        return (
            <>
                <Header backLink='/' logo page='Reset Password' />
                 {
                    passSuccess &&
                    <div className="full-screen-shadow">
                        <div className="success-dialog text-primary">
                            <DoneAllRoundedIcon style={{ fontSize: '50px' }} />
                            <h1>Password Changed </h1>
                        </div>
                    </div>
                }
                <Grid className="loginscreen" container sx={{ height: "100vh", padding: "8vw 14vw" }}>
                    <Grid item xs={12} md={7} container justifyContent="center" sx={{ flexDirection: "column", marginTop: { sm: "4rem", md: "0" } }}>
                        <h1 className="register--modifier">Change Password</h1>
                        <form onSubmit={(e) => { handleSubmit(e) }}>
                            {
                                <>
                                    <div className="field ">
                                        <div className="loginscreen__password">
                                            <label className="loginscreen__label">Current Password</label>
                                            <div className="loginscreen__inputIcon resetpassword__field">
                                                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                                    <OutlinedInput
                                                        id="outlined-adornment-password "
                                                        type={showPassword2 ? 'text' : 'password'}
                                                        size="small"
                                                        placeholder={"Current Password"}
                                                        minLength="8"
                                                        onChange={(e) => {
                                                            setCurrentPassword(e.target.value)
                                                        }} required
                                                        className="loginscreen__password"
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword2}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    onMouseUp={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>

                                    {/* new password */}

                                    <div className="field ">
                                        <div className="loginscreen__password">
                                            <label className="loginscreen__label">New Password</label>
                                            <div className="loginscreen__inputIcon resetpassword__field">
                                                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                                    <OutlinedInput
                                                        id="outlined-adornment-password "
                                                        type={showPassword ? 'text' : 'password'}
                                                        size="small"
                                                        placeholder={t('resetPassword.newPassword')}
                                                        minLength="8"
                                                        onChange={(e) => {
                                                            setPassword(e.target.value)
                                                            if (e.target.value === password2) {
                                                                setError(false)
                                                            } else {
                                                                setError(true)
                                                            }
                                                        }} required
                                                        className="loginscreen__password"
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    onMouseUp={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field ">
                                        <div className="loginscreen__password">
                                            <label className="loginscreen__label">Confirm Password</label>
                                            <div className="loginscreen__inputIcon resetpassword__field">
                                                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                                    <OutlinedInput
                                                        id="outlined-adornment-password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        size="small"
                                                        placeholder={t('resetPassword.confirmPassword')}
                                                        minLength="8"
                                                        // value={password2}
                                                        onChange={(e) => {
                                                            setPassword2(e.target.value)
                                                            if (e.target.value === password) {
                                                                setError(false)
                                                            } else {
                                                                setError(true)
                                                            }
                                                        }}
                                                        required
                                                        className="loginscreen__password"
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    onMouseUp={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        error && <p className="text-danger error-text">Password doesn't match !</p>
                                    }
                                    {
                                        serverError &&
                                        <p className="text-danger error-text">{message}</p>
                                    }
                                </>
                            }
                            <Button type="submit" style={{ minWidth: "70%", marginTop:7 }} variant="contained" className="loginscreen__button loginscreen__buttonone"
                                onClick={() => {
                                    {
                                       <>
                                            {log({
                                                pageName: 'Reset Password',
                                                buttonName: 'Send Code'
                                            })}</>
                                    }
                                    { <> {log({
                                                pageName: 'Reset Password',
                                                buttonName: 'Done'
                                            })}</>
                                    }
                                }}>
                                {
                                    passLoading ?
                                        <CircularProgress style={{ color: 'white' }} />
                                        : <>
                                            { <>{t('resetPassword.done')}</>}
                                        </>
                                }
                            </Button>
                        </form>
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center" xs={5} sx={{ display: { xs: "none", md: "flex" } }}>
                        <img className="loginscreen__illustration" src="../assets/img/fgpass.png" alt="" />
                    </Grid>
                </Grid>
            </>
        )
}






