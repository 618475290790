import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    todos: []
}

export const todoSlice = createSlice({
    name: 'todo',
    initialState,
    reducers: {
        addTodo: (state, action) => {
            let todos = state.todos || []
            todos.push(action.payload)
            state.todos = todos
        },
        updateTodo: (state, action) => {
            let todos = []
            state.todos.map(todo => {
                if (todo._id === action.payload._id) {
                    todos.push({ ...todo, title: action.payload.title })
                } else {
                    todos.push(todo)
                }
            })
            state.todos = todos
        },
        deleteTodo: (state, action) => {
            let todos = state.todos || []
            let tempTodos = todos.filter(todo => todo._id !== action.payload)
            state.todos = tempTodos
        },

        prioritizeTodos: (state, action) => {
            state.todos = action.payload
        },
    },

})

export const {
    addTodo,
    updateTodo,
    deleteTodo,
    prioritizeTodos
} = todoSlice.actions

export default todoSlice.reducer