import React from 'react'
import Slider from "react-slick";
import { useEffect } from 'react'
import { userLog } from '../_api/index'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setWords, setSkillName, setLessonName, setSkillCount, setLessonIndex, setSkillIndex } from '../../redux-features/user/userSlice'
import { Grid, Tooltip, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from '@mui/material/styles'
import Button from '@mui/material/Button';
import CelebrationIcon from '@mui/icons-material/Celebration';

import { Link } from 'react-router-dom';
import { isStatic } from '../common/isStatic';

const Entrepreneur = (props) => {
    const { mutate: log } = useMutation(userLog);
    const user = useSelector((state) => state?.user?.userData?.user);
    const completedLessons = useSelector((state) => state?.user.completedLessons)
    const completedSkills = useSelector((state) => state?.user.completedSkills)
    const { lesson, keyIndex, moduleId, moduleContent, currentSkill, currentLesson, nextSkill } = props;
    const dispatch = useDispatch();
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    const settings = {
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 0,
        infinite: false,
        arrows: false,
        vertical: isMobile ? true : false
    };



    return (
        <>
            {
                !!props.module?.lessons.length ?
                    <>
                        <div className='slider_all'
                            style={{
                                '--primary-color': theme.palette.primary.main,
                            }}
                        >
                            <Slider {...settings} className="slider_setting">
                                {
                                    props.module?.lessons?.map((lesson, lessonIndex) => {
                                        let matched = user?.badges?.length ? lesson?.badges.map(val => user?.badges?.find(el => el.id === val.id)).filter(Boolean) : [];
                                        const isLessonCompleted = isStatic ? completedLessons?.includes(lesson?.id) : lesson?.isCompleted;
                                        const completedSkillsLength = isStatic
                                            ? lesson?.skills?.filter(skill => completedSkills?.includes(skill?.id)).length
                                            : lesson?.skills?.filter(skill => skill?.isCompleted).length;
                                        return (
                                            <div className="customItem">
                                                <div className="Literacy-card" id='style-3'>
                                                    <div className="box-financial">
                                                        <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                            <h3 style={{ color: theme.palette.primary.main }}>{lesson.name}</h3>
                                                            <div style={{ display: "flex", gap: "10px" }}>
                                                                {
                                                                    (!!isLessonCompleted && !!matched.length) &&
                                                                    matched?.slice(0, 2)?.map((badge) => {
                                                                        return (
                                                                            <Tooltip title={badge?.name} placement='top' arrow >
                                                                                <div
                                                                                    className="icon p-2"
                                                                                    style={{
                                                                                        backgroundColor: theme.palette.primary.main,
                                                                                        width: "35px",
                                                                                        height: "35px",
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        borderRadius: "8px",
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        width="30px"
                                                                                        src={badge?.iconID?.svg}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </Tooltip>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </div>
                                                        {/* <Grid container gap={"1rem"}> */}
                                                        {/* <Grid item sx={{ background: "white", padding: "0.5rem 1rem", borderRadius: "0.5rem" }}>
                                                            <Typography className="innerhead" sx={{ color: "grey" }}>Grade</Typography>
                                                            <Typography className="innerhead" sx={{ color: "black", fontWeight: "700" }}> 9 - 12</Typography>
                                                        </Grid> */}
                                                        <Grid item sx={{ background: isLessonCompleted ? theme.palette.success.main : "white", padding: "0.5rem 1rem", borderRadius: "0.5rem", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography sx={{ color: isLessonCompleted ? "white" : "grey" }}>Completed</Typography>
                                                            <Typography sx={{ color: isLessonCompleted ? "white" : "black", fontWeight: "700" }}>{completedSkillsLength} / {lesson?.skills?.length}</Typography>
                                                        </Grid>
                                                        {/* </Grid> */}
                                                        <ul>
                                                            {/* <li>
                                                <h6>Grade 9 - 12</h6>
                                            </li> */}
                                                            <li>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="card-body" >
                                                        <div className="scroll_inner" id='style-3'>
                                                            {
                                                                lesson?.skills?.map((skill, skillIndex) => {
                                                                    let isSkillCompleted = isStatic ? completedSkills?.includes(skill?.id) : skill?.isCompleted
                                                                    let isSkillEnrolled = isStatic ? true : skill?.isEnrolled
                                                                    return (
                                                                        <>
                                                                            <Link className="skill-next" to={isSkillEnrolled ? "/skills?moduleId=" + moduleId + "&lessonId=" + lesson.id + "&skillId=" + skill.id : ''}
                                                                                onClick={(e) => {
                                                                                    log({
                                                                                        pageName: 'Lesson',
                                                                                        buttonName: skill?.name
                                                                                    })
                                                                                    dispatch(setSkillName(skill?.name))
                                                                                    dispatch(setLessonName(lesson?.name))
                                                                                    dispatch(setLessonIndex(lessonIndex + 1))
                                                                                    dispatch(setSkillIndex(skillIndex + 1))
                                                                                    dispatch(setSkillCount(lesson?.skills?.length))
                                                                                    isLessonCompleted && dispatch(setWords(lesson?.words))
                                                                                        (!skill?.isEnrolled || !isStatic) ?
                                                                                        e.preventDefault()
                                                                                        :
                                                                                        <></>
                                                                                }}
                                                                                style={{ color: isSkillCompleted ? theme.palette.success.main : isSkillEnrolled ? theme.palette.common.white : theme.palette.common.text }}
                                                                            >
                                                                                <div className="box-title" style={{ backgroundColor: isSkillCompleted ? theme.palette.success.light : isSkillEnrolled ? theme.palette.primary.main : theme.palette.common.disabled }}>
                                                                                    {skill?.name}
                                                                                </div>
                                                                            </Link>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}
                            </Slider>

                        </div>
                    </>
                    :
                    <>
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <img width="30%" src="../assets/svg/lesson/no-data2.svg" alt="" />
                        </div>
                    </>
            }

        </>
    )
}

export default Entrepreneur






