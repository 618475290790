import * as React from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { extractTextFromHTML } from "../../common/contentType/contentTypeData";
import { useTheme } from "@mui/material";

const Row = ({ row, index, expanded, handleChange }) => {
  const theme = useTheme();

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange(index)}
      sx={{
        backgroundColor: expanded
          ? theme.palette.primary.main
          : theme.palette.common.lightGray,
        marginBottom: "10px",
        boxShadow: "none",
        border: `1px solid ${theme.palette.common.darkGray}`,
        borderRadius: "1rem",
        padding: ".5rem",
        "&:before": {
          display: "none",
        },
        "&:first-of-type": {
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
        },
        "&:last-of-type": {
          borderBottomLeftRadius: "1rem",
          borderBottomRightRadius: "1rem",
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              color: expanded
                ? theme.palette.common.white
                : theme.palette.common.text,
            }}
          />
        }
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            flexGrow: 1,
            textAlign: "left",
          }}
          color={
            expanded ? theme.palette.common.white : theme.palette.common.text
          }
        >
          {`${index + 1}. ${row.title}`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ py: 0 }}>
        <Box>
          <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body2" color={theme.palette.common.white}>
            {extractTextFromHTML(row.detail)}
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const CollapsibleTable = ({ Adminwrapper, DATA_TYPE, data, setData }) => {
  const [expandedIndex, setExpandedIndex] = React.useState(false);

  const handleChange = (panelIndex) => (event, isExpanded) => {
    setExpandedIndex(isExpanded ? panelIndex : false);
  };

  return (
    <Box>
      {data?.dropdowns?.map((row, index) => (
        <Row
          key={row.name}
          row={row}
          index={index}
          expanded={expandedIndex === index}
          handleChange={handleChange}
          Adminwrapper={Adminwrapper}
          DATA_TYPE={DATA_TYPE}
          data={data}
          setData={setData}
        />
      ))}
      {!data?.dropdowns?.length && "No Dropdowns to show"}
    </Box>
  );
};

export default CollapsibleTable;
