const ChatIconCustom = ({ color, width, height }) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    width={width || "40px"} // adjust size as needed
    height={height || "40px"} // adjust size as needed
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        {/* Apply the color dynamically using inline fill */}
        <path
          fill={color} // Apply the dynamic color here
          d="M35.55,52.69c.01-8.08,6.12-14.18,14.22-14.19,1.19,0,2.44,0,3.76,0,4.24,0,9.08,0,14.18,0,5.68,0,11.68,0,17.53,0,1.4,0,2.75,0,4.05,0-.05-6.3-4.73-10.95-11.06-10.95-15.84-.01-43.81,.06-59.65-.03-6.4-.04-10.69,4.52-11.26,9.24,0,.07-.08,.14-.13,.2v55.66c.53,1.76,2.11,2.52,3.48,2.02,.46-.17,.9-.45,1.28-.76,4.83-4.01,9.65-8.03,14.49-12.03,.35-.29,.89-.48,1.34-.48,2.26,0,4.89,0,7.77-.01-.01-9.69-.02-19.32,0-28.69Z"
        />
        <path
          fill={color} // Apply the dynamic color here
          d="M120.81,106.74c0-18.55,0-37.11,0-55.66-.04-.07-.12-.13-.13-.2-.57-4.72-4.86-9.28-11.26-9.24-15.84,.09-43.81,.02-59.65,.03-6.36,0-11.06,4.69-11.06,11.04-.01,10.57-.01,21.13,0,31.7,0,.89,.08,1.8,.28,2.66,1.18,5.09,5.42,8.4,10.74,8.41,12.78,.01,37.7,0,50.49,.02,.45,0,.99,.19,1.34,.48,4.84,3.99,9.66,8.02,14.49,12.03,.38,.31,.82,.6,1.28,.76,1.37,.5,2.96-.26,3.48-2.02Z"
        />
      </g>
      <rect fill="none" width="128" height="128" />
    </g>
  </svg>
);

export default ChatIconCustom;
