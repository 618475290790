import { useState } from "react";
import {
  ADMIN_LINK,
  checkQuilValidation,
  DATA_TYPE,
  isAdminAdd,
  isAdminEdit,
  renderPlaceholder,
} from "../../../common/contentType/contentTypeData";
import { useFormik } from "formik";
import { Box, Button, Grid, useTheme } from "@mui/material";
import { Adminwrapper, TypographyWithMore } from "../../../common";
import { QuillEditor } from "../../details/quil-editor";
import CustomAudioPlayer from "../../../common/contentType/CustomAudioPlayer";
import CustomCardWrapper from "../../../common/contentType/CustomCardWrapper";
import * as Yup from "yup";

const RenderImageAudio = ({ content }) => {
  const myObj = JSON.parse(content?.allContent?.content);
  const { wireframe_link, wireframe_link1, text } = myObj;
  const { name, description, type } = content?.allContent;
  const theme = useTheme();
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link,
    wireframe_link1,
    text,
  });

  const { MEDIA, LINK, QUILL } = DATA_TYPE;

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };

  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      text: data.text,
      description: data.description,
      wireframe_link: data.wireframe_link,
      wireframe_link1: data.wireframe_link1,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      wireframe_link: Yup.string().required("Image is Required"),
      wireframe_link1: Yup.string().required("Audio is Required"),
      text: Yup.string().required("Audio Subtitle is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handleImageVisibility = () => {
    if (!data?.wireframe_link)
      return (
        process.env.REACT_APP_S3_BUCKET_LINK +
        "d82ced10-9bcc-11ed-8bd2-abba50f2c66f.jpeg"
      );
    if (!data?.wireframe_link?.name)
      return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link;
    return URL.createObjectURL(data?.wireframe_link);
  };

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };

  return (
    <Box>
      {isAdminEdit() && (
        <Button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </Button>
      )}
      {isAdminAdd() && (
        <Button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </Button>
      )}

      <CustomCardWrapper>
        <Grid
          container
          spacing={{ xs: 4, md: 10 }}
          alignItems="center"
          height="100%"
        >
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Adminwrapper
              errorText={contentFormik.errors.name}
              dataType={QUILL}
              data={data}
              setData={setData}
              type="name"
            >
              {renderPlaceholder(data?.name, "Enter Name")}
              <TypographyWithMore
                variant="h2"
                color="primary"
                data={data?.name}
                textLength={50}
                lines={2}
              />
            </Adminwrapper>
            <Adminwrapper
              errorText={contentFormik.errors.description}
              dataType={QUILL}
              data={data}
              setData={setData}
              type="description"
            >
              {renderPlaceholder(data?.description, "Enter Description")}
              <TypographyWithMore
                variant="subTitle1"
                color="text"
                data={data?.description}
                textLength={50}
                lines={4}
              />
            </Adminwrapper>
          </Grid>
          <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
            {(isAdminEdit() || isAdminAdd()) && (
              <Adminwrapper
                errorText={contentFormik.errors.wireframe_link}
                dataType={MEDIA}
                data={data}
                setData={setData}
                type="link1"
                mediaType={"IMAGE"}
              >
                <Box>
                  <img
                    src={handleImageVisibility()}
                    frameBorder="0"
                    width="100%"
                  />
                </Box>
              </Adminwrapper>
            )}
            <CustomAudioPlayer
              autoPlay={false}
              src={
                data?.wireframe_link1.includes("https")
                  ? data?.wireframe_link1
                  : process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link1
              }
              text={data?.text || "No subtitle to show"}
              imageSrc={
                handleImageVisibility() || "/assets/img/dummy-audio.png"
              }
            />
          </Grid>
        </Grid>
      </CustomCardWrapper>
      {(isAdminAdd() || isAdminEdit()) && (
        <Adminwrapper
          errorText={contentFormik.errors.text}
          dataType={LINK}
          data={data}
          setData={setData}
          type="text"
          label="Audio Subtitle"
        >
          {renderPlaceholder(data?.text, "Enter Audio Subtitle")}
          <QuillEditor sx={quilStyle} value={data?.text} readOnly={true} />
        </Adminwrapper>
      )}
    </Box>
  );
};

const btnUpdateStyle = {
  position: "fixed",
  left: 15,
  bottom: 50,
  padding: "7px 20px",
  backgroundColor: "#fff",
  border: "none",
  borderRadius: "10px",
  color: "#616ded",
  width: "fit-content",
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.4)",
};
const btnAddStyle = {
  position: "fixed",
  left: 20,
  bottom: 50,
  padding: "7px 30px",
  backgroundColor: "#fff",
  border: "none",
  borderRadius: "10px",
  color: "#616ded",
  width: "fit-content",
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.4)",
};

const quilStyle = {
  fontSize: "16px",
  marginBottom: "20px",
  marginTop: "20px",
};

export default RenderImageAudio;
