import React, { memo, useState } from "react";
import LazzyLoading from "../../src/components/common/LazzyLoading";

const FileViewer = ({ fileUrl, height }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const iframeDisplayStyle = isLoaded ? "block" : "none";

  const iframeStyle = {
    width: "80%",
    margin: "0 auto",
    height: height ? height : "570px",
    overflow: "hidden",
    ...{ display: iframeDisplayStyle },
  };

  return (
    <div>
      {!isLoaded && <LazzyLoading />}
      <iframe
        src={fileUrl}
        onLoad={() => setIsLoaded(true)}
        frameborder="0"
        style={iframeStyle}
        allowfullscreen="true"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
      ></iframe>
    </div>
  );
};

export default memo(FileViewer);
